<lib-full-width-layout *transloco="let t">
  <lib-wide-layout>
    <h1>
      {{ t('userCredential.title') }}
      <button
        #tooltip="matTooltip"
        matSuffix
        aria-label="tooltip help text"
        class="tooltip-bttn"
        mat-button
        [matTooltip]="t('userCredential.informationProvide')"
        [matTooltipPosition]="'above'"
      >
        <fa-icon matIconPrefix size="xs" [icon]="faCircleInfo"></fa-icon>
      </button>
    </h1>
    <lib-card>
      <div class="mr-1 ml-1">
        <div class="row cleverContain">
          <div class="col-lg-6">
            <div class="d-none d-lg-block">
              <img
                src="assets/images/clever_banner.png"
                alt="{{ t('login.img_alttext') }}"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="col-lg-6">
            @if(isIes){
            <app-ies-new-user-form></app-ies-new-user-form>
            }@else {
            <app-missing-user-form></app-missing-user-form>
            }
          </div>
        </div>
      </div>
    </lib-card>
  </lib-wide-layout>
</lib-full-width-layout>
