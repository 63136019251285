import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { faAddressBook } from '@fortawesome/pro-light-svg-icons';
import { faEarthAmerica } from '@fortawesome/pro-solid-svg-icons';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import {
  TopazDialogMaxSize,
  TopazLoadingModule,
} from '@pearsonvue/topaz-angular-ui';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { SrcLibsDataAccessModule } from '@src/libs/data-access/src';
import { SrcLibsUiModule } from '@src/libs/ui/src';
import { SrcLibsUtilityModule } from '@src/libs/utility/src';
import { MaterialUiModule } from './modules/material-ui.module';
import { TopazUiModule } from './modules/topaz-ui.module';
import { TranslocoRootModule } from './transloco-root.module';

import { AppendDefaultLangPipe } from './pipe/appendDefaultLang';
import { CacheService } from './services/common/cache.service';
import { RegistrationService } from './services/registration.service';
import { TokenService } from './services/common/token.service';
import { ProfileService } from './services/profile.service';
import { appEffects, appStore, metaReducers } from './core/store';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { LoginV2Component } from './components/login-v2/login-v2.component';
import { LanguagePickerService } from './services/common/language-picker.service';
import { CustomerSupportErrorComponent } from './components/customersupporterror/customer-support-error.component';
import { HeaderWrapperComponent } from './components/header-wrapper/header-wrapper.component';
import { UnsavedPopupComponent } from './components/unsaved/unsavedPopup.component';
import { CountryService } from './services/common/country.service';
import { TimeOutComponent } from './components/timeout/timeout.component';
import { MouseDirective } from './directives/mouse-directive';
import { environment } from '@src/environments/environment.development';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginV2Component,
    AppendDefaultLangPipe,
    CustomerSupportErrorComponent,
    HeaderWrapperComponent,
    UnsavedPopupComponent,
    TimeOutComponent,
    MouseDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialUiModule,
    FontAwesomeModule,
    GoogleTagManagerModule.forRoot(),
    TopazUiModule,
    TopazLoadingModule,
    TranslocoRootModule,
    SrcLibsUiModule,
    SrcLibsUtilityModule,
    SrcLibsDataAccessModule  

  ],
  providers: [
    CacheService,
    RegistrationService,
    TokenService,
    LanguagePickerService,
    ProfileService,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        maxWidth: TopazDialogMaxSize.MEDIUM,
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-dark-backdrop',
        restoreFocus: true,
      },
    },
    {
      provide: TRANSLOCO_SCOPE,
      useValue: '',
    },
    //Store
    provideStore(appStore, { metaReducers }),
    environment.imports,   
    provideEffects(appEffects),
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (countryService: CountryService) => {
        return () => {
          countryService.getCountryList();
        };
      },
      deps: [CountryService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faAddressBook, faEarthAmerica);
  }
}
