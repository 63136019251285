import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from '@pearsonvue/topaz-angular-ui';

import { LoginComponent } from './components/login/login.component';
import { LoginV2Component } from './components/login-v2/login-v2.component';
import { SignupModule } from './modules/signup/signup.module';
import { CustomerSupportErrorComponent } from './components/customersupporterror/customer-support-error.component';

import { authenticationGuard } from './guards/auth/auth.guard';
import { HeaderWrapperComponent } from './components/header-wrapper/header-wrapper.component';

const routes: Routes = [
  {
    path: 'support',
    title: 'Portal Support :: Certiport',
    loadChildren: () =>
      import('./modules/support/support.module').then(m => m.SupportModule),
  },
  {
    path: '',
    title: 'Portal Login :: Certiport',
    component: LoginV2Component,
  },
  {
    path: 'login',
    title: 'Portal Login :: Certiport',
    component: LoginComponent,
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./modules/signup/signup.module').then(m => m.SignupModule),
  },
  {
    path: 'under13',
    loadChildren: () =>
      import('./modules/under13/under13.module').then(m => m.Under13Module),
  },
  {
    path: 'account-recovery',
    loadChildren: () =>
      import('./modules/account-recovery/account-recovery.module').then(
        m => m.AccountRecoveryModule,
      ),
  },
  {
    path: 'profile',
    canActivate: [authenticationGuard],
    loadChildren: () =>
      import('./modules/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'customersupport',
    component: CustomerSupportErrorComponent,
  },
  {
    path: '**',
    component: ErrorPageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
    }),
    SignupModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
