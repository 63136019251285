import { Component, forwardRef, Injector, Inject, OnInit, Input } from '@angular/core'
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { Subject, takeUntil, startWith, distinctUntilChanged, tap } from 'rxjs';
import { EmailExistsErrors } from '@src/libs/utility/src/lib/validators/email-exists-validator';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';


@Component({
  selector: 'lib-email',
  templateUrl: './email.component.html',
  styleUrl: './email.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailComponent),
      multi: true,
    },
  ],
})
export class EmailComponent implements ControlValueAccessor, OnInit{
  @Input() label = 'Email';
  @Input() placeHolder = 'useremail@pearson.com';
  @Input() errorRequired = `signup.error.required_email`;
  @Input() errorMatch = 'under13.parentalEmail.error_candidateEmail';
  @Input() errorInvalid = 'signup.error.email';
  control!: FormControl;
  isRequired = false;
  faEnvelope = faEnvelope;

  private _destroy$ = new Subject<void>();
  private _onTouched!: () => any;

  constructor(
    @Inject(Injector) private injector: Injector,
    private translocoService: TranslocoService,
  ) {}


  hasError(field: string): boolean {
    return !!this.control?.errors && !!this.control?.touched;
  }

  ngOnInit() {
    this.setFormControl();
    this.isRequired = this.control?.hasValidator(Validators.required) ?? false;
  }

  setFormControl() {
    try {
      const formControl = this.injector.get(NgControl);

      switch (formControl.constructor) {
        case FormControlName:
          this.control = this.injector
            .get(FormGroupDirective)
            .getControl(formControl as FormControlName);
          break;
        default:
          this.control = (formControl as FormControlDirective)
            .form as FormControl;
          break;
      }
    } catch (err) {
      this.control = new FormControl();
    }
  }

  writeValue(value: any): void {
    if (this.control && this.control.value != value)
      this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (val: any | null) => any): void {
    this.control?.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        startWith(this.control.value),
        distinctUntilChanged(),
        tap(val => fn(val)),
      )
      .subscribe(() => this.control?.markAsUntouched());
  }

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn;
  }


  getEmilValidation() {
    const email = this.control;

    if (email?.hasError('email')) {
      return this.translocoService.translate('signup.error.email');
    }
   
    if (email?.hasError('emailvalid')) {
      return this.translocoService.translate(this.errorInvalid);
    }

    if (email?.hasError('match')) {
      return this.translocoService.translate(this.errorMatch);
    }

    if (
      email?.hasError(EmailExistsErrors.EmailExists)
    ) {
      return this.translocoService.translate('signup.error.verifyEmail');
    }

    if (email?.hasError('matching')) {
      return this.translocoService.translate(
        'signup.error.alt_email_match',
      );
    }

    return email?.hasError('required')
      ? this.translocoService.translate(this.errorRequired)
      : '';
  }

}
