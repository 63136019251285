import {
  Component,
  ViewChild,
  ViewEncapsulation,
  OnInit,
  Inject,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioGroup } from '@angular/material/radio';
import { TranslocoService } from '@jsverse/transloco';
import {
  LoaderService,
  ToastService,
  TopazDefaultToastComponent,
  TopazDialogMaxSize,
} from '@pearsonvue/topaz-angular-ui';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import {
  faCalendar,
  faCaretDown,
  faFlag,
} from '@fortawesome/pro-regular-svg-icons';
import {
  finalize,
  map,
  Observable,
  startWith,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import moment from 'moment';

import { CountryService } from '@src/app/services/common/country.service';
import { ValidateMaxDate } from '@src/libs/utility/src/lib/validators/date-validators';
import { StudentIdValidators } from '@src/libs/utility/src/lib/validators/studentid-validators';
import { LanguagePickerService } from '@src/app/services/common/language-picker.service';
import { getAge } from '@src/libs/utility/src/lib/validators/age-validators';
import { UseragreementmodalComponent } from '../../signup/components/useragreementmodal/useragreementmodal.component';
import { GovStudentidPopupWrapperComponent } from '../gov-studentid-popup-wrapper/gov-studentid-popup-wrapper.component';
import { SsoService } from '../services/sso.service';
import { RegistrationFormService } from '../../signup/services/registration-form.service';
import {
  DefaultSSOModel,
  SSOModel,
  SSOPutModel,
  DefaultPutSSOModel,
} from '../models/sso-model';
import { APP_CONFIG, Config } from '@src/app/config/config';
import { Country } from '@src/app/models/countries';
import {
  CountryErrors,
  CountryValidators,
} from '@src/libs/utility/src/lib/validators/country-validators';
import { CacheService } from '@src/app/services/common/cache.service';
import { generateLoginRedirect } from '@certiport/login-library';

@Component({
  selector: 'app-missing-user-form',
  templateUrl: './missing-user-form.component.html',
  styleUrls: ['./missing-user-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MissingUserFormComponent implements OnDestroy, OnInit {
  @ViewChild('tos') checkbox!: MatCheckbox;
  @ViewChild('myGovStudentId') govId!: MatRadioGroup;

  token: string | null = '';
  updateUser: SSOPutModel = DefaultPutSSOModel;
  userInfo: SSOModel = DefaultSSOModel;

  isToSChecked: boolean = false;
  dialogOpen: boolean = false;
  dialogToSStatus: boolean = false;
  dialogGovUpdated: boolean = false;

  showSpinner: boolean = false;
  formSubmit: boolean = false;
  isItaly: boolean = false;

  countries: Observable<Country[]> = this.countryService.get();
  public filteredCountries!: Observable<Country[]>;

  todayDate = new Date();
  italy_studentId = 'Codice Fiscale';

  registrationForm = this.registrationService.createForm();
  usercredentialForm = this.fb.group({
    birthDate: [null, [Validators.required, ValidateMaxDate]],
    myGovStudentId: ['', Validators.required],
    country: [
      '',
      [
        Validators.required,
        CountryValidators.createExistsValidator(this.countryService),
      ],
    ],
    studentID: [''],
    tos: ['', Validators.required],
  });
  showStudentId: boolean = false;

  faCircleInfo = faCircleInfo;
  faCalendar = faCalendar;
  faFlag = faFlag;
  faCaretDown = faCaretDown;

  private _destroy = new Subject<void>();

  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private _dialog: MatDialog,
    private translocoService: TranslocoService,
    private countryService: CountryService,
    private registrationService: RegistrationFormService,
    private ssoService: SsoService,
    private loaderService: LoaderService,
    private router: Router,
    private toastService: ToastService,
    private langPickerService: LanguagePickerService,
    private cache: CacheService,
  ) {}

  ngOnInit(): void {
    this.usercredentialForm
      .get('country')!
      .valueChanges.pipe(
        takeUntil(this._destroy),
        startWith(''),
        map(value => this.countryService.filterCountries(value ?? '')),
      )
      .subscribe(countries => (this.filteredCountries = countries));

    this.ssoService.resetSSOUserInfo();

    this.route.queryParams.pipe(take(1)).subscribe(params => {
      this.token = params['token'];
    });

    this.route.data.pipe(take(1)).subscribe(({ user }) => {
      if (user.userId) {
        this.loaderService.hideLoader();
        this.userInfo = user;
        this.registrationForm.get('identity')?.patchValue({
          firstName: user.firstName,
          middleName: user.middleName,
          lastName: user.lastName,
        });
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  get formValid(): boolean {
    return (
      this.usercredentialForm.touched &&
      this.usercredentialForm.valid &&
      this.checkbox?.checked
    );
  }

  onChangeToSCheckbox($event: MatCheckboxChange) {
    if (this.dialogOpen) {
      this.checkbox.checked = this.dialogToSStatus;
      this.isToSChecked = !this.checkbox.checked;
    } else {
      this.isToSChecked = !$event.checked;
    }
  }

  openUserAgreementModal($event: MouseEvent): void {
    this._dialog.open(UseragreementmodalComponent, {
      autoFocus: false,
      maxWidth: TopazDialogMaxSize.MEDIUM,
      panelClass: 'UserAgreementModal',
    });

    this.dialogOpen = true;
    this.dialogToSStatus = this.checkbox.checked;

    this._dialog.afterAllClosed.pipe(take(1)).subscribe(() => {
      this.dialogOpen = false;
      this.dialogToSStatus = false;
    });
  }

  hasError(field: string): boolean {
    return (
      !!this.usercredentialForm.get(field)?.errors &&
      !!this.usercredentialForm.get(field)?.touched
    );
  }

  getValidationText(field: string): string {
    if (
      this.usercredentialForm.get(field)?.hasError(CountryErrors.CountryExists)
    ) {
      return this.translocoService.translate(`signup.error.required_${field}`);
    }
    if (this.usercredentialForm.get(field)?.hasError('maxDate')) {
      return this.translocoService.translate('signup.error.maxDate');
    }
    if (this.usercredentialForm.get(field)?.hasError('matDatepickerParse')) {
      return this.translocoService.translate(`signup.error.dateFormat`);
    }
    return this.usercredentialForm.get(field)?.hasError('required')
      ? this.translocoService.translate(`signup.error.required_${field}`)
      : '';
  }

  getStudentIdValidationErrorMsg() {
    const getStudentId = this.usercredentialForm.get('studentID');
    if (getStudentId?.hasError('required')) {
      return !this.isItaly
        ? this.translocoService.translate('signup.error.required_studentID')
        : this.translocoService.translate('signup.error.required_codiceFicale');
    }
    if (getStudentId?.hasError('hasSpecialCharacter')) {
      return this.translocoService.translate(
        'signup.error.specialChars_codiceFicale',
      );
    }
    if (getStudentId?.hasError('minLength')) {
      return this.translocoService.translate(
        'signup.error.minLength_codiceFicale',
      );
    }
    if (getStudentId?.hasError('maxLength')) {
      return this.translocoService.translate(
        'signup.error.maxLength_codiceFicale',
      );
    }
    return '';
  }

  setupCountryValidators() {
    const studentIdControl = this.usercredentialForm.get('studentID');

    const selectedCountry = this.countryService.getCountryByDesc(
      this.usercredentialForm.get('country')?.value || '',
    );
    // Student ID Requirement Check
    studentIdControl?.setErrors(null);
    studentIdControl?.clearValidators();
    if (selectedCountry?.isStudentIdNeeded) {
      if (selectedCountry.isO3166Code === 'IT') {
        studentIdControl?.setValidators([
          Validators.required,
          ...StudentIdValidators.Validators,
        ]);
        this.isItaly = true;
      } else {
        studentIdControl?.setValidators([Validators.required]);
        this.isItaly = false;
      }

      this.showStudentId = true;
    } else {
      studentIdControl?.clearValidators();
      this.showStudentId = false;
    }
  }

  openGovPopup($event: string) {
    if ($event === 'No') {
      const dialogRef = this._dialog.open(GovStudentidPopupWrapperComponent, {
        autoFocus: false,
        maxWidth: TopazDialogMaxSize.LARGE,
        data: this.registrationForm?.value['identity'],
      });

      this.dialogOpen = true;

      dialogRef.afterClosed().subscribe((result: any) => {
        this.usercredentialForm.controls['myGovStudentId'].setValue(null);
        this.dialogOpen = false;
        this.dialogGovUpdated = true;
        if (result?.event) {
          this.userInfo.firstName = result?.data.firstName;
          this.userInfo.lastName = result?.data.lastName;
          this.registrationForm.get('identity')?.patchValue(result?.data);
          this.usercredentialForm.controls['myGovStudentId'].setValue('Yes');
          this.toastService.open(TopazDefaultToastComponent, {
            toastType: 'success',
            toastContent: this.translocoService.translate(
              'userCredential.myGovidSuccessNote',
            ),
          });
        }
      });
    }
  }

  getPutObject() {
    const formValue = this.usercredentialForm.value;

    const selectedLang = this.langPickerService.languageInfos.find(
      lang =>
        lang.languageCode ===
        this.langPickerService.getLanguageSelectedByUser(),
    );

    const selectedCountry = this.countryService.getCountryByDesc(
      formValue.country || '',
    );

    let userPutData: SSOPutModel = {
      ...DefaultPutSSOModel,
      email: this.userInfo.email,
      preferredLanguageId: selectedLang?.languageId || 5,
      birthDate: moment(DefaultPutSSOModel.birthDate).format('YYYY-MM-DD'),
    };

    if (this.token && this.userInfo.userId) {
      userPutData['token'] = this.token;
      userPutData['userId'] = this.userInfo.userId;
      userPutData['birthDate'] =
        moment(formValue.birthDate).format('YYYY-MM-DD') || '';
      userPutData['country'] = {
        countryName: selectedCountry?.name || '',
        countryId: selectedCountry?.countryId || 0,
      };

      userPutData['studentId'] = formValue.studentID || '';
      userPutData['firstName'] = this.userInfo.firstName;
      userPutData['lastName'] = this.userInfo.lastName;
      userPutData['isUserAgreementChecked'] = this.isToSChecked;
      userPutData['middleName'] = this.userInfo.middleName;
      userPutData['parentApproved'] = this.userInfo.parentApproved;
      userPutData['parentEmail'] = this.userInfo.parentEmail ?? '';
      this.cache.setComplexItem('under13form', userPutData);

      if (this.dialogGovUpdated) {
        userPutData = {
          ...userPutData,
          ...this.registrationForm?.value['identity'],
        };
      }
    }
    return userPutData;
  }

  backtoLogin() {
    // TODO: redirect to IES login page
    if (this.config.iesFlag) {
      window.location.href = generateLoginRedirect(
        this.config.baseLoginUri,
        this.config.subLoginUri,
        this.config.ssoUri,
        '',
      );
    } else {
      this.router.navigate(['/']);
    }
  }

  submit(): void {
    this.usercredentialForm.markAllAsTouched();
    this.formSubmit = true;
    if (this.formValid) {
      this.loaderService.showLoader(
        this.translocoService.translate('common.loadingText'),
      );

      const dob = this.usercredentialForm.get<string>('birthDate')?.value;
      const country = this.countryService.getCountryByDesc(
        this.usercredentialForm.get('country')?.value || '',
      );

      const userPutData = this.getPutObject();

      if (
        country?.isParentApprovalNeededForMinor &&
        getAge(new Date(dob)) < 13
      ) {
        this.ssoService.ssoUserInfo.next(userPutData);
        this.router.navigate(['/under13'], {
          queryParams: [{ sso: 1 }],
          queryParamsHandling: 'preserve',
        });
        this.loaderService.hideLoader();
        return;
      }

      this.ssoService
        .updateSSOUser(userPutData, this.userInfo.userId)
        .pipe(
          take(1),
          finalize(() => this.loaderService.hideLoader()),
        )
        .subscribe(result => {
          if (result) {
            const sessionId = result.data!.PortalUserSessionID;
            if (!this.config.profileFlag) {
              window.location.href = `${this.config.portalRootUrl}/Portal/SSL/LoginRedirect.aspx?sessionId=${sessionId}`;
            } else {
              this.router.navigate(['/profile'], {
                queryParamsHandling: 'merge',
              });
            }
          }
        });
    }

    if (!this.checkbox?.checked) {
      this.isToSChecked = true;
    }
  }
}
