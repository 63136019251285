import { Component, OnInit, ViewChild } from '@angular/core'
import {
  ToastComponent,
  ToastService,
  ToastServiceConfig,
} from '@pearsonvue/topaz-angular-ui'
import { ToastData } from './model/toast-data.model'
import { ToastConfigService } from './services/config/toast-config-service.service'
import { TopazToastData } from '@pearsonvue/topaz-angular-ui/lib/components/toast/types/toast-data.interface'

@Component({
  selector: 'lib-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.css'],
})
export class CustomToastComponent {
  @ViewChild(ToastComponent, { static: true }) public toast!: ToastComponent

  toasterdata!: ToastData

  constructor(private toastService: ToastService<ToastData>) {}

  ngOnInit(): void {
    this.toasterdata = this.toastService.getToastData(this.toast)
    if (this.toasterdata.disableCloseButton == undefined) {
      this.toasterdata.disableCloseButton = true
    }
  }
}
