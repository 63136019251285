<header *transloco="let t">
  <topaz-global-bar
    *ngIf="!showTabHeader"
    customLogoImg="../../../../../assets/images/Portal_certiport_logo.svg"
    logoAltText="Cetiport"
    (logoClicked)="handleLogoClick()"
  >
    <topaz-global-bar-start>
      <div *ngIf="mobileDisplay$ | async" class="desktop-content-container">
        @for (item of _headerConfig.tabs[0].subViews; track item; let i =
        $index) {
        <a
          role="link"
          class="topaz-nav-link"
          tabindex="0"
          data-id="header-nav-item-{{ i }}"
          [ngClass]="{ 'topaz-nav-link--active': item.label === activeLink }"
          (click)="handleViewChange(item)"
        >
          {{ item.label }}
        </a>
        }
      </div>
    </topaz-global-bar-start>

    <topaz-global-bar-end>
      <div *ngTemplateOutlet="barEnd"></div>

      <div *ngIf="authStatus">
        <div *ngTemplateOutlet="profileMenu"></div>
        <div *ngIf="(mobileDisplay$ | async) === false"></div>
      </div>
    </topaz-global-bar-end>
  </topaz-global-bar>

  <topaz-global-tabs-bar
    *ngIf="showTabHeader"
    #tabsBar
    customLogoImg="../../../../../assets/images/Portal_certiport_logo.svg"
    logoAltText="Cetiport"
    [tabs]="_headerConfig.tabs"
    (logoClicked)="handleLogoClick()"
    (tabChangeEvent)="handleTabChange($event)"
    (viewChangeEvent)="handleViewChange($event)"
  >
    <div *ngTemplateOutlet="allMyRoles" class="TopazNavLinks"></div>

    <topaz-global-tabs-bar-end>
      <div
        *ngTemplateOutlet="barEnd"
        class="demo-tab-links TopazNavLinksMobile"
      ></div>

      <div *ngIf="authStatus" class="mr-1">
        <div *ngTemplateOutlet="profileMenu"></div>
        <div *ngIf="(mobileDisplay$ | async) === false"></div>
      </div>
    </topaz-global-tabs-bar-end>

    <topaz-global-tabs-bar-body-mobile>
      <div *ngTemplateOutlet="allMyRoles" class="mt-1"></div>

      <mat-divider class="mt-1 mb-1"></mat-divider>

      <div class="mr-1 ml-1">
        @for (item of _headerConfig.tabs; track item; let i = $index) {
        <div
          style="display: inline-block; width: 100%"
          data-id="header-sidenav-nav-item-{{ i }}"
          (click)="toggle[i] = !toggle[i]"
        >
          <h2
            class="active-parent-item"
            style="float: left"
            data-id="header-sidenav-nav-lbl-{{ i }}"
          >
            {{ item.label }}
          </h2>
          <span style="float: right; padding: 0.5rem">
            <fa-icon [icon]="toggle[i] ? faChevronUp : faChevronDown"></fa-icon>
          </span>
        </div>
        <div [hidden]="!toggle[i]">
          <mat-list role="list" class="subview-links">
            @for(subItem of item.subViews; track subItem; let j = $index) {
            <mat-list-item
              role="list"
              class="topaz-nav-link-mobile"
              data-id="header-sidenav-nav-{{ i }}-item-lbl-{{ j }}"
              [ngClass]="{
                'topaz-nav-link--active': subItem.label === activeLink
              }"
              (click)="handleMenuClick(item, subItem)"
            >
              {{ subItem.label }}
            </mat-list-item>
            }
          </mat-list>
        </div>
        }
      </div>

      <mat-divider></mat-divider>

      <ng-content *ngTemplateOutlet="loginDiv"></ng-content>
    </topaz-global-tabs-bar-body-mobile>
  </topaz-global-tabs-bar>

  <ng-template #barEnd>
    <div class="demo-tab-links TopazNavLinksMobile mr-1">
      <div
        class="icon-container support-icon-container mr-1"
        tabindex="0"
        attr.aria-label="{{ t('header.helpAndSupport') }}"
        role="link"
        data-id="header-support-icon"
        (click)="supportRedirect()"
        (keydown)="supportRedirectKeydown($event); tooltip.hide()"
      >
        <fa-icon
          class="support-icon"
          #tooltip="matTooltip"
          matTooltip="{{ t('header.helpAndSupport') }}"
          data-id="header-support-tooltip"
          [icon]="faCircleQuestion"
        ></fa-icon>
      </div>
      <div
        class="mr-1 icon-container language-icon-container"
        tabindex="0"
        attr.aria-label="{{ t('common.language') }}"
        role="link"
        data-id="header-language-icon"
        (click)="openLanguageSelectorDialog()"
        (keydown)="openLanguageSelectorKeydown($event); tooltip.hide()"
      >
        <fa-icon
          class="language-icon"
          #tooltip="matTooltip"
          matTooltip="{{ t('common.language') }}"
          data-id="header-language-tooltip"
          [icon]="faEarthAmericas"
        ></fa-icon>
      </div>
    </div>
  </ng-template>

  <ng-template #allMyRoles>
    <div class="mr-1 ml-1 logout-container">
      <button
        *ngIf="showAllRoles | async"
        disabled
        mat-flat-button
        color="primary"
        attr.aria-label="{{ t('header.roleDir') }}"
        class="mr-1 ml-1 icon-container support-icon-container all-my-roles"
        tabindex="0"
        data-id="header-allRoles-btn"
        (click)="allRoleRedirect()"
        (keydown)="allRoleKeydown($event)"
      >
        <div
          [matTooltip]="t('header.allRoles_tooltip')"
          data-id="header-allRoles-lbl"
        >
          {{ t('common.roles.allRoles') }}
        </div>
      </button>
    </div>
  </ng-template>

  <ng-template #loginDiv>
    <div class="mb-1 mr-1 ml-1 logout-container">
      <button
        mat-flat-button
        color="primary"
        class="topaz-button-small-simple"
        data-id="header-login-btn"
        (click)="handleLogoutClick()"
      >
        {{ t('common.logOut') }}
      </button>
    </div>
  </ng-template>

  <ng-template #profileMenu>
    <div class="">
      <div class="user-name tab-links">
        <div
          aria-label="menu"
          class="icons-container language-icon-container"
          data-id="header-userName-btn"
          matTooltip="{{ t('common.menu') }}"
          [matMenuTriggerFor]="accountMenu"
          #tooltip="matTooltip"
          (keyup)="tooltip.hide()"
        >
          <fa-icon class="user-icon" [icon]="faUser"></fa-icon>
          <div class="user-name-container">
            <span class="user-name-str" data-id="header-userName-lbl">
              {{ userName }}
            </span>
            <fa-icon [icon]="faCaretDown"></fa-icon>
          </div>
        </div>
      </div>
      <mat-menu #accountMenu="matMenu">
        <ng-template matMenuContent>
          <button
            mat-menu-item
            data-id="header-menu-profile-btn"
            (click)="handleProfileClick()"
          >
            {{ t('header.profile') }}
          </button>
          <button
            mat-menu-item
            data-id="header-menu-logOff-btn"
            (click)="handleLogoutClick()"
          >
            {{ t('common.logOut') }}
          </button>
        </ng-template>
      </mat-menu>
    </div>
  </ng-template>

  <ng-template #mobileMenu class="mr-1 user-name-container">
    <div
      class="mr-2 icon-container language-icon-container"
      style="min-width: 50%"
      tabindex="0"
      data-id="header-mobile-menu"
      matTooltip="{{ t('common.menu') }}"
      [attr.aria-label]="'Menu button'"
      (click)="openMenu()"
      #tooltip="matTooltip"
      (keydown)="tooltip.hide()"
    >
      <fa-icon class="language-icon mobile-menu" [icon]="faBars"></fa-icon>
    </div>
  </ng-template>
</header>
