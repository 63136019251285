export interface SSOModel {
  email: string
  firstName: string
  middleName: string
  lastName: string
  isNameMatchedWithGovId: boolean
  isUserAgreementChecked: boolean
  displayLastNameFirst: boolean
  birthDate: string
  country: {
    countryId: number
    countryname: string
  }
  isActive: boolean
  studentId?: string
  parentApproved: boolean
  parentEmail?: string
  isSSO: boolean
  ssoProvider: string
  userId: string
}

export const DefaultSSOModel: SSOModel = {
  email: '',
  firstName: '',
  middleName: '',
  lastName: '',
  isNameMatchedWithGovId: false,
  isUserAgreementChecked: true,
  displayLastNameFirst: false,
  birthDate: '',
  country: {
    countryId: 0,
    countryname: '',
  },
  isActive: false,
  parentApproved: false,
  isSSO: true,
  ssoProvider: 'clever',
  userId: '',
}

export interface SSOPutModel {
  firstName: string
  middleName: string
  lastName: string
  token: string
  email?: string
  isUserAgreementChecked: boolean
  isNameMatchedWithGovId: boolean
  userAgreeementDate: Date
  displayLastNameFirst: boolean
  birthDate: string

  country: {
    countryName: string
    countryId: number
  }
  studentId: string
  isActive: boolean
  parentApproved: boolean
  parentEmail: string
  legacyCandidateId: string
  userId: string
  preferredLanguageId: number
}

export const DefaultPutSSOModel: SSOPutModel = {
  token: '',
  userId: '',
  firstName: '',
  middleName: '',
  lastName: '',
  isUserAgreementChecked: false,
  isNameMatchedWithGovId: true,
  userAgreeementDate: new Date(),
  displayLastNameFirst: false,
  birthDate: '',
  country: {
    countryId: 0,
    countryName: '',
  },
  studentId: '',
  isActive: true,
  parentEmail: '',
  parentApproved: false,
  legacyCandidateId: '',
  preferredLanguageId: 5
}
