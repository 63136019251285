<mat-form-field appearance="outline" *transloco = "let t">
  <mat-label>
    {{label}}
  </mat-label>
  <input
      matInput
      type="text"
      [required]="isRequired"
      placeholder="{{label}}"
      [formControl]="control"
      data-id="alternateName-matInput"
    />
    <mat-error *ngIf="hasError('alternateName')">
      {{ getAlternateNameValidationMessage() }}
    </mat-error>
</mat-form-field>
