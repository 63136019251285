import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { zip } from 'rxjs';
import { finalize, first, map, mergeMap, takeUntil } from 'rxjs/operators';

import * as sessionActions from './session.actions';
import { ProfileActions } from '../profile';
import { AuthService } from '../../services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../core.state';

@Injectable()
export class SessionEffects {
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sessionActions.clear),
      mergeMap(() => {
        // Add additional clear actions here
        this.store.dispatch(ProfileActions.clear());

        // Add watch steps for clear completion actions here
        const profileClear = this.actions$.pipe(
          ofType(ProfileActions.clearSuccess),
          takeUntil(this.actions$.pipe(ofType(ProfileActions.clearFailure))),
          first(),
        );

        // Add clear watch steps here before logout to confirm everything is cleared before redirect
        const result$ = zip([profileClear, this.auth.logout()]).pipe(
          map(() => sessionActions.clearSuccess()),
          finalize(() => {
            this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
          }),
          first(),
        );

        return result$;
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<AppState>,
    private auth: AuthService,
  ) {}
}
