export const DEFAULTCOUNTRY = [
  {
    countryId: 222,
    isO3166Code: 'US',
    stateProvinces: [
      {
        stateProvinceID: 735,
        countryID: 222,
        territoryID: null,
        stateProvinceName: 'APO/FPO Address (AA)',
        stateProvinceCode: 'AA ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 736,
        countryID: 222,
        territoryID: null,
        stateProvinceName: 'APO/FPO Address (AE)',
        stateProvinceCode: 'AE ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 737,
        countryID: 222,
        territoryID: null,
        stateProvinceName: 'APO/FPO Address (AP)',
        stateProvinceCode: 'AP ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 674,
        countryID: 222,
        territoryID: 5,
        stateProvinceName: 'Alaska',
        stateProvinceCode: 'AK ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 675,
        countryID: 222,
        territoryID: 1,
        stateProvinceName: 'Alabama',
        stateProvinceCode: 'AL ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 676,
        countryID: 222,
        territoryID: 3,
        stateProvinceName: 'Arkansas',
        stateProvinceCode: 'AR ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 677,
        countryID: 222,
        territoryID: 5,
        stateProvinceName: 'Arizona',
        stateProvinceCode: 'AZ ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 678,
        countryID: 222,
        territoryID: 5,
        stateProvinceName: 'California',
        stateProvinceCode: 'CA ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 679,
        countryID: 222,
        territoryID: 3,
        stateProvinceName: 'Colorado',
        stateProvinceCode: 'CO ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 680,
        countryID: 222,
        territoryID: 2,
        stateProvinceName: 'Connecticut',
        stateProvinceCode: 'CT ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 681,
        countryID: 222,
        territoryID: 1,
        stateProvinceName: 'District of Columbia',
        stateProvinceCode: 'DC ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 682,
        countryID: 222,
        territoryID: 2,
        stateProvinceName: 'Delaware',
        stateProvinceCode: 'DE ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 683,
        countryID: 222,
        territoryID: 1,
        stateProvinceName: 'Florida',
        stateProvinceCode: 'FL ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 684,
        countryID: 222,
        territoryID: 1,
        stateProvinceName: 'Georgia',
        stateProvinceCode: 'GA ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 685,
        countryID: 222,
        territoryID: 4,
        stateProvinceName: 'Hawaii',
        stateProvinceCode: 'HI ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 686,
        countryID: 222,
        territoryID: 4,
        stateProvinceName: 'Iowa',
        stateProvinceCode: 'IA ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 687,
        countryID: 222,
        territoryID: 5,
        stateProvinceName: 'Idaho',
        stateProvinceCode: 'ID ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 688,
        countryID: 222,
        territoryID: 4,
        stateProvinceName: 'Illinois',
        stateProvinceCode: 'IL ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 689,
        countryID: 222,
        territoryID: 4,
        stateProvinceName: 'Indiana',
        stateProvinceCode: 'IN ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 690,
        countryID: 222,
        territoryID: 3,
        stateProvinceName: 'Kansas',
        stateProvinceCode: 'KS ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 691,
        countryID: 222,
        territoryID: 1,
        stateProvinceName: 'Kentucky',
        stateProvinceCode: 'KY ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 692,
        countryID: 222,
        territoryID: 3,
        stateProvinceName: 'Louisiana',
        stateProvinceCode: 'LA ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 693,
        countryID: 222,
        territoryID: 2,
        stateProvinceName: 'Massachusetts',
        stateProvinceCode: 'MA ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 694,
        countryID: 222,
        territoryID: 1,
        stateProvinceName: 'Maryland',
        stateProvinceCode: 'MD ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 695,
        countryID: 222,
        territoryID: 2,
        stateProvinceName: 'Maine',
        stateProvinceCode: 'ME ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 696,
        countryID: 222,
        territoryID: 4,
        stateProvinceName: 'Michigan',
        stateProvinceCode: 'MI ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 697,
        countryID: 222,
        territoryID: 4,
        stateProvinceName: 'Minnesota',
        stateProvinceCode: 'MN ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 698,
        countryID: 222,
        territoryID: 3,
        stateProvinceName: 'Missouri',
        stateProvinceCode: 'MO ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 699,
        countryID: 222,
        territoryID: null,
        stateProvinceName: 'Mariana Islands',
        stateProvinceCode: 'MP ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 700,
        countryID: 222,
        territoryID: 1,
        stateProvinceName: 'Mississippi',
        stateProvinceCode: 'MS ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 701,
        countryID: 222,
        territoryID: 5,
        stateProvinceName: 'Montana',
        stateProvinceCode: 'MT ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 702,
        countryID: 222,
        territoryID: 1,
        stateProvinceName: 'North Carolina',
        stateProvinceCode: 'NC ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 703,
        countryID: 222,
        territoryID: 4,
        stateProvinceName: 'North Dakota',
        stateProvinceCode: 'ND ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 704,
        countryID: 222,
        territoryID: 3,
        stateProvinceName: 'Nebraska',
        stateProvinceCode: 'NE ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 705,
        countryID: 222,
        territoryID: 2,
        stateProvinceName: 'New Hampshire',
        stateProvinceCode: 'NH ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 706,
        countryID: 222,
        territoryID: 2,
        stateProvinceName: 'New Jersey',
        stateProvinceCode: 'NJ ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 707,
        countryID: 222,
        territoryID: 3,
        stateProvinceName: 'New Mexico',
        stateProvinceCode: 'NM ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 708,
        countryID: 222,
        territoryID: 5,
        stateProvinceName: 'Nevada',
        stateProvinceCode: 'NV ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 709,
        countryID: 222,
        territoryID: 2,
        stateProvinceName: 'New York',
        stateProvinceCode: 'NY ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 710,
        countryID: 222,
        territoryID: 4,
        stateProvinceName: 'Ohio',
        stateProvinceCode: 'OH ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 711,
        countryID: 222,
        territoryID: 3,
        stateProvinceName: 'Oklahoma',
        stateProvinceCode: 'OK ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 712,
        countryID: 222,
        territoryID: 5,
        stateProvinceName: 'Oregon',
        stateProvinceCode: 'OR ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 713,
        countryID: 222,
        territoryID: 2,
        stateProvinceName: 'Pennsylvania',
        stateProvinceCode: 'PA ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 714,
        countryID: 222,
        territoryID: 2,
        stateProvinceName: 'Rhode Island',
        stateProvinceCode: 'RI ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 715,
        countryID: 222,
        territoryID: 1,
        stateProvinceName: 'South Carolina',
        stateProvinceCode: 'SC ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 716,
        countryID: 222,
        territoryID: 4,
        stateProvinceName: 'South Dakota',
        stateProvinceCode: 'SD ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 717,
        countryID: 222,
        territoryID: 1,
        stateProvinceName: 'Tennessee',
        stateProvinceCode: 'TN ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 718,
        countryID: 222,
        territoryID: 3,
        stateProvinceName: 'Texas',
        stateProvinceCode: 'TX ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 719,
        countryID: 222,
        territoryID: 5,
        stateProvinceName: 'Utah',
        stateProvinceCode: 'UT ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 720,
        countryID: 222,
        territoryID: 1,
        stateProvinceName: 'Virginia',
        stateProvinceCode: 'VA ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 721,
        countryID: 222,
        territoryID: 2,
        stateProvinceName: 'Vermont',
        stateProvinceCode: 'VT ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 722,
        countryID: 222,
        territoryID: 5,
        stateProvinceName: 'Washington',
        stateProvinceCode: 'WA ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 723,
        countryID: 222,
        territoryID: 4,
        stateProvinceName: 'Wisconsin',
        stateProvinceCode: 'WI ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 724,
        countryID: 222,
        territoryID: 1,
        stateProvinceName: 'West Virginia',
        stateProvinceCode: 'WV ',
        isActive: true,
        taxAcctCodeID: '0',
      },
      {
        stateProvinceID: 725,
        countryID: 222,
        territoryID: 5,
        stateProvinceName: 'Wyoming',
        stateProvinceCode: 'WY ',
        isActive: true,
        taxAcctCodeID: '0',
      },
    ],
    subregionId: 1,
    subregion: {
      subregionID: 1,
      regionID: 1,
      subregionName: 'US',
      subregionDesc: 'US',
      isActive: true,
    },
    name: 'United States',
    localeName: 'N/A',
    description: 'Description',
    countryCode: 'USA',
    isActive: true,
    isParentApprovalNeededForMinor: true,
    isStudentIdNeeded: false,
    isNationalIdRequired: false,
    nationalIdFieldName: '',
    useAlternateName: false,
  },
];
