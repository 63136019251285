import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SignupComponent } from './components/signup/signup.component';
import { MissingUserInfoComponent } from '../clever-sso/missing-user-info/missing-user-info.component';
import { iesResolver } from '../clever-sso/services/ies-user.resolver';
import { registrationNeededGuard } from '../clever-sso/services/registration-needed.guard';
import { countryResolver } from './resolvers/country.resolver';

const routes: Routes = [
  {
    path: 'signup',
    title: 'Portal Registration :: Certiport',
    component: SignupComponent,
    resolve: { countriesLoaded: countryResolver },
  },
  {
    path: 'sso',
    title: 'Portal Registration :: Certiport',
    component: MissingUserInfoComponent,
    canActivate: [registrationNeededGuard],
    resolve: { user: iesResolver },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SignupRoutingModule {}
