<div *transloco="let t">
  <lib-thin-layout>
    <br />
    <form [formGroup]="dobFormGroup">
      <div class="row align-contents">
        <h1
          topazTitle="1"
          class="text-center d-none d-lg-block"
          data-id="res-s3-dobtitle"
        >
          {{ t('signup.dobTitle') }}
        </h1>
      </div>

      <div class="row align-contents">
        <div class="col-11">
          <lib-custom-banner
            *ngIf="dobFormGroup.invalid && formSubmit"
            data-id="res-s3-err-banner"
          >
            <p class="m-0">
              <b>{{ t('common.uiErrorBanner.errorsHaveBeenFound') }}</b>
            </p>
          </lib-custom-banner>
        </div>
        <div class="col-1"></div>
      </div>

      <div class="row align-contents">
        <div class="col-11">
          <mat-form-field appearance="outline">
            <mat-label data-id="res-s3-dob-label">{{
              t('common.dob_input')
            }}</mat-label>
            <input
              matInput
              required
              autocomplete="off"
              placeholder="DD MMM YYYY"
              formControlName="birthDate"
              [matDatepicker]="formPicker"
              [max]="todayDate"
              (dateInput)="getDate('input', $event)"
              (dateChange)="getDate('change', $event)"
              data-id="res-s3-dob-input"
            />
            <mat-datepicker-toggle matIconSuffix [for]="formPicker">
              <fa-icon
                matDatepickerToggleIcon
                [icon]="faCalendar"
                [ngClass]="[hasError('birthDate') ? 'red' : '']"
              ></fa-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #formPicker></mat-datepicker>
            <mat-hint align="end" data-id="res-s3-dob-label"
              >DD MMM YYYY</mat-hint
            >
            <mat-error *ngIf="hasError('birthDate')" data-id="res-s3-dob-err">
              {{ getValidationText('birthDate') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-1">
          <div
            #tooltip="matTooltip"
            matSuffix
            aria-label="tooltip help"
            class="tooltip-bttn"
            mat-button
            attr.aria-label="{{ t('signup.tooltip.dateOfBirth') }}"
            matTooltip="{{ t('signup.tooltip.dateOfBirth') }}"
            [matTooltipPosition]="'above'"
            role="link"
            data-id="res-s3-dob-tooltip"
          >
            <fa-icon
              matIconPrefix
              size="lg"
              fa-sr-only
              [icon]="faCircleInfo"
              [attr.aria-hidden]="false"
            ></fa-icon>

            <span class="fa-sr-only">Info icon</span>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="showStudentId">
        <div class="col-11">
          <mat-form-field appearance="outline">
            <mat-label data-id="res-s3-studentid-label">
              {{ isItaly ? italy_studentId : t('common.studentID') }}
            </mat-label>
            <input
              matInput
              required
              type="text"
              formControlName="studentID"
              placeholder="{{
                isItaly ? italy_studentId : t('common.studentID')
              }}"
              [maxlength]="isItaly ? 20 : 50"
              data-id="res-s3-studentid-input"
            />
            <mat-error
              *ngIf="hasError('studentID')"
              data-id="res-s3-studentid-err"
            >
              {{ getStudentIdValidationErrorMsg() }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="showCaptcha">
        <div class="col-11">
          <div class="recaptcha">
            <re-captcha
              formControlName="reCaptchaToken"
              siteKey="{{ siteKey }}"
              (resolved)="checkCaptcha($event)"
            ></re-captcha>
            <mat-error *ngIf="captchaResolved" data-id="res-s3-captcha-err">
              {{ t('signup.error.requiredCaptcha') }}
            </mat-error>
          </div>
        </div>
      </div>
    </form>
  </lib-thin-layout>

  <mat-divider class="mt-2 mb-1"></mat-divider>
  <div class="row">
    <div class="col-md">
      <div class="buttons mdc-bttn-160 d-flex d-none d-lg-block">
        <button
          mat-button
          class="fw-600 flex-m-order-2"
          [routerLink]="['/']"
          data-id="res-s3-cancel-btn"
        >
          {{ t('common.cancel') }}
        </button>
      </div>
    </div>
    <div class="col-md">
      <div class="buttons step-action mdc-bttn-160">
        <button
          mat-stroked-button
          matStepperPrevious
          color="primary"
          class="fw-600 flex-m-order-1"
          type="button"
          (click)="back()"
          data-id="res-s3-back-btn"
        >
          {{ t('common.previous') }}
        </button>
        <button
          mat-flat-button
          class="fw-600 flex-m-order-2"
          type="submit"
          color="primary"
          (click)="onSubmitStep3()"
          data-id="res-s3-submit-btn"
        >
          {{ t('common.submit') }}
        </button>
      </div>
    </div>
  </div>
</div>
