import { Component, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  EnvironmentVariablesService,
  LoginStorageTokens,
} from '@certiport/login-library';
import { TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import {
  ToastService,
  TopazDefaultToastComponent,
} from '@pearsonvue/topaz-angular-ui';
import { TopazToastType } from '@pearsonvue/topaz-angular-ui/lib/components/toast/types/toast.type';
import { Observable, Subscription } from 'rxjs';

import { HeaderConfig } from '@src/libs/ui/src/lib/models/headerConfig';
import { UserDetails } from '@src/libs/ui/src/lib/models/store/userDetails';

import {
  AuditEventService,
  AuditEventType,
} from '@src/libs/utility/src/lib/services/audit-event.service';
import { APP_CONFIG, Config } from '@src/app/config/config';
import { AppState, ProfileActions, selectUserDetails } from './core/store';
import { LanguagePickerService } from './services/common/language-picker.service';
import { SessionStorageService } from './services/common/session-storage.service';
import { NotificationsService } from './services/common/notifications.service';
import { ProfileService } from './services/profile.service';
import { LanguageInfo } from './models/languageinfo.model';
import { CountryService } from '@src/app/services/common/country.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public myScriptElement: HTMLScriptElement | undefined;

  title = 'Certiport';
  textDirectionality = '';
  showHeader = true;

  subscriptions: Subscription[] = [];

  get fetchAuthStatus(): Observable<boolean> {
    return this.profileService.getAuthStatus();
  }

  get fetchHeaderConfig(): Observable<HeaderConfig> {
    return this.profileService.getHeaderConfig();
  }

  get fetchUserDetails(): Observable<UserDetails> {
    return this.store.select(selectUserDetails);
  }

  get fetchHeaderRoles(): Array<number> {
    return this.profileService.getHeaderRoles();
  }

  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private env: EnvironmentVariablesService,
    protected store: Store<AppState>,
    private auditEventService: AuditEventService,
    private langPickerService: LanguagePickerService,
    private translocoService: TranslocoService,
    private sessionStorageService: SessionStorageService,
    private profileService: ProfileService,
    private toastService: ToastService,
    private notificationService: NotificationsService,
    private countryService: CountryService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeader = !event.url.includes('support');
      }
    });

    this.env.setRootUrl(`${this.config.microServiceURI}/authentication`);
    const refreshToken: string | null = localStorage.getItem(
      LoginStorageTokens.Refresh,
    );

    this.activatedRoute.queryParams.subscribe(params => {
      const langCode: LanguageInfo['languageCode'] =
        params['defaultlang'] || params['defaultLang'];
      this.langPickerService.setLanguageByLangCode(langCode);
    });

    langPickerService.selectedLanguageProps$.pipe().subscribe(langInfo => {
      this.textDirectionality = !langInfo ? 'ltr' : langInfo?.direction;
    });

    this.translocoService.setActiveLang(
      this.sessionStorageService.getItem('languageCode') ?? 'enu',
    );

    this.countryService.getCountryList().subscribe(country => {
      this.countryService.countries.next(country);
    });

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        this.auditEventService.gtmEvent('page', item.url, 'portal');

        let appInsightKey = `portal${item.url.replace('\\', '-')}`;
        if (item.url === '/') {
          appInsightKey = 'portal-login';
        }

        this.auditEventService.appInsightEvent(
          appInsightKey,
          AuditEventType.PageView,
        );
      }
    });

    this.subscriptions.push(
      this.notificationService.getGlobalToast().subscribe(toast => {
        if (toast.status) {
          this.toastService.open(TopazDefaultToastComponent, {
            toastType: toast.status as TopazToastType,
            toastHeader: toast.header,
            toastContent: toast.content,
          });
        }
      }),
    );

    this.env.setRootUrl(`${this.config.microServiceURI}/authentication`);
    this.env.setPortalRootUrl(`${this.config.microServiceURI}`);
    this.env.setIesUrl(`${this.config.iesBaseUrl}`);

    this.addScript('../../../assets/js/appInsightsRegistration.js');
    if (!this.config.profileFlag) {
      this.store.dispatch(ProfileActions.fetch());
    }
  }

  addScript(src: string) {
    this.myScriptElement = document.createElement('script');
    this.myScriptElement.src = src;
    document.body.appendChild(this.myScriptElement);

    if (!this.config.profileFlag) {
      this.store.dispatch(ProfileActions.fetch());
    }
  }
}
