import { createSelector } from "@ngrx/store";
import { AppState } from "../core.state";
import { ApplicationState } from "./application.state";


export const selectApplicationState = (state:AppState) => state.application;
export const preLogoutSelector = createSelector(
  selectApplicationState, 
  (state: ApplicationState) => state.preLogout || false
);
export const resetTimerSelector = createSelector(
    selectApplicationState, 
    (state: ApplicationState) => state.resetTimer || false
)