import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  AuthenticationOutput,
  StorageService,
  AuthenticationService,
  IUserDetail,
  LoginErrorsOutput,
  UserDetailService,
} from '@certiport/login-library';
import { TranslocoService } from '@jsverse/transloco';
import { TopazToastType } from '@pearsonvue/topaz-angular-ui/lib/components/toast/types/toast.type';
import {
  ToastService,
  TopazDefaultToastComponent,
} from '@pearsonvue/topaz-angular-ui';
import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
} from '@fortawesome/pro-regular-svg-icons';
import { Store } from '@ngrx/store';
import { Subscription, catchError, map, take } from 'rxjs';

import {
  AppState,
  selectSession,
  selectSessionLoading,
  SessionActions,
} from '@src/app/core/store';
import { SessionStorageService } from '@src/app/services/common/session-storage.service';
import { AppendDefaultLangPipe } from '@src/app/pipe/appendDefaultLang';
import { AuthService } from '@src/app/core/services/auth.service';
import { APP_CONFIG, Config } from '@src/app/config/config';
import {
  GlobalBannerObject,
  NotificationsService,
} from '@src/app/services/common/notifications.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AppendDefaultLangPipe],
})
export class LoginComponent implements OnDestroy, OnInit {
  @ViewChild('usernameRef') usernameRef!: ElementRef<HTMLInputElement>;

  public showPassword: boolean = false;
  public myScriptElement: HTMLScriptElement | undefined;

  isLoading: boolean = false;
  invalidControl: boolean = false;
  showAuthError: boolean = false;
  otherBanners: GlobalBannerObject[] = [];

  subscriptions: Subscription[] = [];
  returnUrl: string = '';

  cleverRedirect = this.config.cleverUrl;
  faEnvelope = faEnvelope;
  faLock = faLock;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  loginFormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private userDetailService: UserDetailService,
    private authService: AuthenticationService,
    private storageService: StorageService,
    private router: Router,
    private _route: ActivatedRoute,
    private store: Store<AppState>,
    private sessionStorageService: SessionStorageService,
    private translocoService: TranslocoService,
    private toastService: ToastService,
    private notificationService: NotificationsService,
  ) {
    this.authService.logout().pipe(take(1)).subscribe();
    this._route.queryParams.pipe(take(1)).subscribe(({ ReturnUrl }) => {
      this.returnUrl = ReturnUrl;
    });

    this.translocoService.setActiveLang(
      this.sessionStorageService.getItem('languageCode') ?? 'enu',
    );

    this.addScript('../../../assets/js/appInsightsRegistration.js');
    // if (refreshToken) this.router.navigate(['']);

    this.subscriptions.push(
      this.notificationService.getGlobalToast().subscribe(toast => {
        if (toast.status) {
          this.toastService.open(TopazDefaultToastComponent, {
            toastType: toast.status as TopazToastType,
            toastHeader: toast.header,
            toastContent: toast.content,
          });
        }
      }),
    );
    this.subscriptions.push(
      this.store
        .select(selectSessionLoading)
        .pipe(map(loading => (this.isLoading = !!loading)))
        .subscribe(),
    );
    this.subscriptions.push(
      this.notificationService.getNotificationBanner().subscribe(banners => {
        this.otherBanners = banners.filter(
          banner => banner.displayPage === 'login',
        );
      }),
    );
  }

  ngOnInit(): void {
    if (this.config.iesFlag) {
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  // TODO: handle login via session id
  hasSessionId() {
    this._route.queryParamMap.pipe(take(1)).subscribe(map => {
      const sid = map.get('sid');
      if (sid) {
        return this.authService
          .AuthenticateWithSid(sid)
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.store.select(selectSession).subscribe(session => {
                if (session.PortalUserSessionID) {
                  if (!this.config.profileFlag) {
                    const baseLoginUrl = `${this.config.portalRootUrl}/Portal/SSL/LoginRedirect.aspx?sessionId=${session.PortalUserSessionID}`;
                    window.location.href = this.returnUrl
                      ? baseLoginUrl + `&ReturnUrl=${this.returnUrl}`
                      : baseLoginUrl;
                  } else {
                    this.router.navigate([this.returnUrl], {
                      queryParamsHandling: 'preserve',
                    });
                  }
                }
              });
            } else {
              this.showAuthError = true;
            }
          });
      } else {
        return true;
        // return this.authService.validate().pipe(
        //   take(1),
        //   catchError((err, caught) => {
        //     this.router.navigate(['login'], {
        //       queryParamsHandling: 'preserve',
        //       queryParams: { returnUrl: state.url },
        //     });
        //     return of(false);
        //   }),
        // );
        //}
        // return this.refreshService.RefreshToken().pipe(
        //   map(() => {
        //     return true;
        //   }),
        //   catchError((e, caught) => {
        //     this.authService.logout().pipe(take(1)).subscribe();
        //     this.router.navigate(['login']);
        //     return of(false);
        //   }),
        // });
      }
    });
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  handleLogin(e: SubmitEvent): void {
    e.preventDefault();
    this.showAuthError = false;
    this.invalidControl = false;

    if (this.loginFormGroup.invalid) {
      this.invalidControl = true;
      return;
    }

    this.authService
      .login(
        this.loginFormGroup.value.username as string,
        this.loginFormGroup.value.password as string,
      )
      .pipe(
        take(1),
        catchError(err => {
          this.showAuthError = true;
          setTimeout(() => {
            this.usernameRef.nativeElement.focus();
          }, 50);
          return err;
        }),
      )
      .subscribe(result => {
        const authOutput = (
          result as unknown as HttpResponse<AuthenticationOutput>
        ).body;
        if (result) {
          this.store.select(selectSession).subscribe(session => {
            const baseLoginUrl = `${this.config.portalRootUrl}/Portal/SSL/LoginRedirect.aspx?sessionId=${authOutput?.PortalUserSessionID}`;
            window.location.href = this.returnUrl
              ? baseLoginUrl + `&ReturnUrl=${this.returnUrl}`
              : baseLoginUrl;
          });
          // this.store
          //   .select(state => state.profile.profile)
          //   .subscribe(profile => {
          //     this.router.navigate(['/profile'], {
          //       queryParamsHandling: 'preserve',
          //     });
          //   });
        } else {
          this.showAuthError = true;
        }
      });
  }

  handleRegister(): void {
    this.router.navigate(['/signup'], {
      queryParamsHandling: 'preserve',
    });
  }

  addScript(src: string) {
    this.myScriptElement = document.createElement('script');
    this.myScriptElement.src = src;
    document.body.appendChild(this.myScriptElement);
  }
}
