import { inject } from '@angular/core';
import { ActivatedRouteSnapshot,  ResolveFn, RouterStateSnapshot } from '@angular/router';
import { CountryService } from '@src/app/services/common/country.service';
import { filter, first } from 'rxjs';

export const countryResolver: ResolveFn<any> = (reoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(CountryService).countries$.pipe(
        filter(countries => countries.length > 1),
        first()
    );
}
