import { InjectionToken } from '@angular/core';

export type Config = {
  production: boolean;
  name: string;
  portalRootUrl: string;
  portalRedirectUrl: string;
  microServiceURI: string;
  cleverUrl: string;
  translocoLanguageImportPath: string;
  autoLogoutWarningWindowMinutes: number;
  autoLogoutTickInterval: number;
  RefreshDelayInMilliseconds: number;
  RefreshBlackoutTimeBeforeWarning: number;
  showCookiePreferencesLink: boolean;
  showLogoutTimer: boolean;
  siteKey: string;
  gaTrackingCode: string;
  apInCode: string;
  baseLoginUri: string;
  subLoginUri: string;
  ssoUri: string;
  regUri: string;
  profileFlag: boolean;
  iesFlag: boolean;
  iesBaseUrl: string;
  warningWindowinMS: number;
  countdownTimerInMS: number;
  authenticationServiceUrl: string;
  iesRegionLangs: string[];
  profileFlag_Roles: boolean;
  profileFlag_Demographics: boolean;
  profileFlag_Programs: boolean;
  profileFlag_SSO: boolean;
  profileFlag_Preferences: boolean;

};

export const APP_CONFIG: InjectionToken<Config> = new InjectionToken<Config>(
  'Application Config',
);
