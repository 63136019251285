import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AuthenticationOutput,
  UserDetailService,
} from '@certiport/login-library';
import { TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import {
  ToastService,
  TopazDefaultToastComponent,
} from '@pearsonvue/topaz-angular-ui';
import { BehaviorSubject, Observable, map, tap, throwError } from 'rxjs';

import { CpHttpClientService } from '@src/libs/data-access/src/lib/services/cp-http-client.service';

import {
  DefaultRegistrationModel,
  RegistrationModel,
} from '../models/registration.model';
import { APP_CONFIG, Config } from '../config/config';
import { AppState, ProfileActions, SessionActions } from '../core/store';
import { HttpResponseModel } from '@src/libs/data-access/src/lib/models/http-response.model';
import { AuthService } from '../core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  baseUrl: string = `${this.config.microServiceURI}`;

  under13RegistrationRequest: BehaviorSubject<RegistrationModel> =
    new BehaviorSubject<RegistrationModel>(DefaultRegistrationModel);

  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private _http: CpHttpClientService,
    private store: Store<AppState>,
    private toastService: ToastService,
    private translocoService: TranslocoService,
    private auth: AuthService,
    private userDetailService: UserDetailService,
  ) {}

  resetUnder13(): void {
    this.under13RegistrationRequest.next(DefaultRegistrationModel);
  }

  validateUserName(userName: string): Observable<any> {
    let data = { username: userName };
    return this._http
      .post(
        `${this.config.microServiceURI}${
          this.config.iesFlag
            ? '/authentication/Registration/validateEmail'
            : '/usermanagement/signup/validateEmail'
        }`,
        data,
      )
      .pipe(
        map(val => {
          return {
            status: 'Success',
            data: val,
          };
        }),
      );
  }

  register(
    data: RegistrationModel,
    autoLogin: boolean = true,
  ): Observable<any> {
    return this._http
      .post<AuthenticationOutput>(
        `${this.config.microServiceURI}/authentication/Authentication/registration`,
        data,
      )
      .pipe(
        tap((authOut: HttpResponseModel<AuthenticationOutput>) => {
          if (autoLogin) {
            this.auth.setRefreshToken(authOut.data!);
            this.userDetailService.setUserDetail(
              this.auth.buildUserDetail(authOut.data as AuthenticationOutput),
            );
            this.store.dispatch(
              SessionActions.updateSuccess({ session: authOut.data! }),
            );

            if (!this.config.profileFlag) {
              this.store.dispatch(ProfileActions.fetch());
            }
          }
        }),
        map(val => ({
          status: 'Success',
          data: val,
        })),
      );
  }

  registerSSO(data: RegistrationModel): Observable<boolean> {
    return this._http
      .put<RegistrationModel>(
        `${this.baseUrl}/authentication/Authentication/registration`,
        data,
      )
      .pipe(
        map(({ success, data }) => {
          return success && data;
        }),
      );
  }

  errorHandler(error: HttpErrorResponse, showToast: boolean) {
    const err = new Error(error.message);
    if (showToast) {
      this.toastService.open(TopazDefaultToastComponent, {
        toastType: 'warn',
        toastHeader: this.translocoService.translate(
          'common.apiErrorToast.apiConnection.title',
        ),
        toastContent: this.translocoService.translate(
          'common.apiErrorToast.apiConnection.body',
        ),
      });
    }
    return throwError(() => err);
  }
}
