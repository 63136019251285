<topaz-side-sheet-content *transloco="let t">
  <button
    *ngIf="showAllRoles"
    disabled
    mat-flat-button
    color="primary"
    attr.aria-label="{{ t('header.roleDir') }}"
    class="mt-1 mr-1 ml-1 all-my-roles icon-container support-icon-container"
    tabindex="0"
    data-id="sidenav-mobile-allRoles-btn"
    (click)="allRoleRedirect()"
    (keydown)="allRoleKeydown($event)"
  >
    <div
      [matTooltip]="t('header.allRoles_tooltip')"
      data-id="sidenav-mobile-allRoles-lbl"
    >
      {{ t('common.roles.allRoles') }}
    </div>
  </button>

  <mat-divider class="mt-1 mb-1"></mat-divider>

  <div class="mr-1 ml-1">
    @for (item of headerConfig.tabs; track item; let i = $index) {
    <div
      style="display: inline-block; width: 100%"
      data-id="sidenav-mobile-item-{{ i }}"
      (click)="toggle[i] = !toggle[i]"
    >
      <h2
        class="active-parent-item"
        style="float: left"
        data-id="sidenav-mobile-lbl-{{ i }}"
      >
        {{ item.label }}
      </h2>
      <span style="float: right; padding: 0.5rem">
        <fa-icon [icon]="toggle[i] ? faChevronUp : faChevronDown"></fa-icon>
      </span>
    </div>
    <div [hidden]="!toggle[i]">
      <mat-list role="list" class="subview-links">
        @for(subItem of item.subViews; track subItem; let j = $index) {
        <mat-list-item
          role="list"
          class="sidenav-mobile-link-mobile"
          data-id="sidenav-mobile-{{ i }}-item-lbl-{{ j }}"
          [ngClass]="{
            'topaz-nav-link--active': subItem.label === activeLink
          }"
          (click)="close(item, subItem)"
        >
          {{ subItem.label }}
        </mat-list-item>
        }
      </mat-list>
    </div>
    }
  </div>

  <mat-divider class="mt-1 mb-1"></mat-divider>

  <div class="mr-1 ml-1 mb-1 logout-container">
    <button
      mat-flat-button
      color="primary"
      class="topaz-button-small-simple"
      data-id="sidenav-mobile-logout-btn"
      (click)="handleLogoutClick()"
    >
      {{ t('common.logOut') }}
    </button>
  </div>
</topaz-side-sheet-content>
