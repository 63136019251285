import { AppState, SessionActions } from '@src/app/core/store';
import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AuthenticationService,
  AuthenticationStateService,
  generateLoginRedirect,
} from '@certiport/login-library';
import { first, map } from 'rxjs/operators';

import { APP_CONFIG } from '../../config/config';

export const authenticationGuard: CanActivateFn = (route, state) => {
  const config = inject(APP_CONFIG);
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  const window = inject(Window);
  const store = inject(Store<AppState>);
  const authenticationStateService = inject(AuthenticationStateService);

  const flag = config.iesFlag;
  const baseLoginUri = config.baseLoginUri;
  const subLoginUri = config.subLoginUri;
  const ssoUri = config.ssoUri;
  const registrationUrl = config.regUri;
  const returnUrl = state.url;

  console.log(
    'auth guard',
    flag,
    baseLoginUri,
    subLoginUri,
    ssoUri,
    returnUrl,
    registrationUrl,
  );

  return authService.validate(flag).pipe(
    first(),
    map(response => {
      if (response === 206) {
        window.location.href = `${registrationUrl}&returnUrl=${encodeURIComponent(
          returnUrl,
        )}`;
        return false;
      } else if (response >= 200 && response < 300) {
        // If the user is authenticated, update the session state and navigate to the return URL
        store.dispatch(
          SessionActions.updateSuccess({
            session: authenticationStateService.state.value,
          }),
        );
        return true;
      } else {
        authService.logout();
        // login redirect url
        window.location.href = generateLoginRedirect(
          baseLoginUri,
          subLoginUri,
          ssoUri,
          returnUrl || '',
        );
        return false;
      }
    }),
  );
};
