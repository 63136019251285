import { createReducer,on } from "@ngrx/store"
import *as  ApplicationAction from "./application.action"
import { ApplicationInitialState } from "./application.state"

export const applicationReducer = createReducer(
    ApplicationInitialState,
    on(ApplicationAction.preLogout, state => ({...state, preLogout: true})),
    on(ApplicationAction.resetPreLogout, state => ({...state, preLogout: false})),
    on(ApplicationAction.refreshToken, state => ({...state, loading: true})),
    on(ApplicationAction.refreshTokenSuccess, (state) => ({
        ...state,
        loading:false,
    })),
    on(ApplicationAction.refreshTokenFailure, (state, {error}) => ({
        ...state,
        loading:false,
        error
    })) 
)