import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { UnsavedGuardService } from '@src/app/guards/has-unsaved/unsaved.service'

@Component({
  selector: 'app-has-unsaved',
  templateUrl: './unsavedPopup.component.html',
  styleUrls: ['./unsavedPopup.component.scss'],
})
export class UnsavedPopupComponent {
 
  constructor(private unsavedguardservice: UnsavedGuardService, public dialogRef: MatDialogRef<UnsavedPopupComponent>){}

  closeUnsavedModal(isNavigate:boolean){
    this.unsavedguardservice.changedUrl(isNavigate)
    this.dialogRef.close()
  }

  confirm() {
    this.dialogRef.close(false);
  }

  cancel() {
      this.dialogRef.close(true);
  }

}
