import {
  AuthenticationOutput,
  DefaultAuthenticationOutput,
} from '@certiport/login-library';

export interface SessionState {
  loading: boolean;
  session: AuthenticationOutput;
  error: any;
}

export const sessionInitialState: SessionState = {
  loading: false,
  session: DefaultAuthenticationOutput,
  error: {},
};
