import { AuthenticationOutput } from '@certiport/login-library';

export interface IESModel extends AuthenticationOutput {
  LoginCount: number;
  RegistrationNeeded: boolean;
  PartialRegistrationObject: PartialRegistrationObject;
}

export interface PartialRegistrationObject {
  UserId: string;
  LegacyCandidateId: null | string;
  PearsonUserID: string;
  Email: string;
  UserName: string;
  FirstName: string;
  LastName: string;
  BirthDate: string | null;
  CountryId: string; //format TBD
  StudentId: string | null;
  ModifiedDate: string;
  IsActive: false;
  PreferredLanguageId: number; //format TBD
  ParentApproved: false;
  ParentEmail: null;
}

export const DefaultIESModel: IESModel = {
  UserDisplayName: '',
  UserId: null,
  PortalUserSessionID: '',
  LoginExpiry: '',
  UserRoles: [],
  RefreshToken: '',
  LoginCount: 0,
  RegistrationNeeded: true,
  PartialRegistrationObject: {
    Email: '',
    FirstName: '',
    LastName: '',
    BirthDate: '',
    IsActive: false,
    ParentApproved: false,
    UserId: '',
    LegacyCandidateId: null,
    PearsonUserID: '',
    UserName: '',
    CountryId: '',
    StudentId: null,
    ModifiedDate: '',
    PreferredLanguageId: 5,
    ParentEmail: null
  },
};

export interface IESPutModel {
  firstName: string;
  middleName: string;
  lastName: string;
  token: string;
  email?: string;
  isUserAgreementChecked: boolean;
  isNameMatchedWithGovId: boolean;
  userAgreeementDate: Date;
  displayLastNameFirst: boolean;
  birthDate: string;
  country: {
    countryName: string;
    countryId: number;
  };
  studentId: string;
  isActive: boolean;
  parentApproved: boolean;
  parentEmail: string;
  legacyCandidateId: string;
  userId: string;
  preferredLanguageId: number;
}

export const DefaultPutIESModel: IESPutModel = {
  token: '',
  userId: '',
  firstName: '',
  middleName: '',
  lastName: '',
  isUserAgreementChecked: false,
  isNameMatchedWithGovId: false,
  userAgreeementDate: new Date(),
  displayLastNameFirst: false,
  birthDate: '',
  country: {
    countryId: 0,
    countryName: '',
  },
  studentId: '',
  isActive: true,
  parentEmail: '',
  parentApproved: false,
  legacyCandidateId: '',
  preferredLanguageId: 5
};
