import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, Observable, map, of } from 'rxjs';

import { CpHttpClientService } from '@src/libs/data-access/src/lib/services/cp-http-client.service';
import { RegistrationService } from '@src/app/services/registration.service';

import {
  DefaultSSOModel,
  SSOModel,
  DefaultPutSSOModel,
  SSOPutModel,
} from '../models/sso-model';
import { LoaderService } from '@pearsonvue/topaz-angular-ui';
import { TranslocoService } from '@jsverse/transloco';
import { APP_CONFIG, Config } from '@src/app/config/config';

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  baseUrl: string = `${this.config.microServiceURI}/usermanagement`;
  ssoUserInfo: BehaviorSubject<SSOPutModel> = new BehaviorSubject<SSOPutModel>(
    DefaultPutSSOModel,
  );

  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private _http: CpHttpClientService,
    private registrationService: RegistrationService,
    private loaderService: LoaderService,
    private translocoService: TranslocoService,
  ) {}

  sendSSOUser(ssoToken: string): Observable<any> {
    return this._http.post<boolean>(`${this.baseUrl}/SSOuser`, ssoToken).pipe(
      map(({ success, data }) => {
        if (success) {
          return data;
        } else {
          return null;
        }
      }),
    );
  }

  checkSSSOUser(ssoToken: string): Observable<boolean> {
    return this._http.post<boolean>(`${this.baseUrl}/SSOuser`, ssoToken).pipe(
      map(({ success, data }) => {
        return success && data;
      }),
    );
  }

  fetchSSOUser(token: string): Observable<SSOModel> {
    this.loaderService.showLoader();
    return this._http
      .get<SSOModel>(`${this.baseUrl}/ssouser?token=${token}`)
      .pipe(
        map(({ success, data }) => {
          if (success) {
            return data;
          } else {
            return DefaultSSOModel;
          }
        }),
      );
  }

  updateSSOUser(user: any, userId: string): Observable<any> {
    return this._http
      .put<SSOPutModel>(`${this.baseUrl}/ssouser?userId=${userId}`, user)
      .pipe(
        map(({ success, data }) => {
          if (success) {
            return data;
          }
          return null;
        }),
      );
  }

  resetSSOUserInfo() {
    this.ssoUserInfo.next(DefaultPutSSOModel);
  }
}
