import { Action, ActionReducer, MetaReducer } from '@ngrx/store';

import {
  ProfileEffects,
  ProfileState,
  profileInitialState,
  profileReducer,
} from './profile';
import {
  SessionEffects,
  SessionState,
  sessionInitialState,
  sessionReducer,
} from './session';
import { CoreActions } from '.';
import { ApplicationState, ApplicationInitialState, applicationReducer, ApplicationEffects } from './application';

export interface AppState {
  session: SessionState;
  profile: ProfileState;
  application: ApplicationState

}
export const InitialState: AppState = {
  session: sessionInitialState,
  profile: profileInitialState,
  application: ApplicationInitialState
};

export interface AppStore {
  session: ActionReducer<SessionState, Action>;
  profile: ActionReducer<ProfileState, Action>;
  application: ActionReducer<ApplicationState, Action>;
}

export const appStore: AppStore = {
  session: sessionReducer,
  profile: profileReducer,
  application: applicationReducer
};

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    return reducer(state, action);
  };
}
export function clear(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === CoreActions.CoreActionKeys.LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const appEffects = [SessionEffects, ProfileEffects,ApplicationEffects];
export const metaReducers: MetaReducer<any>[] = [debug, clear];
