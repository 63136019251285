import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import {
  TOPAZ_ERROR_IMAGE_LIST,
  TopazErrorPageData,
} from '@pearsonvue/topaz-angular-ui'

@Component({
  selector: 'lib-customerror',
  templateUrl: './customerror.component.html',
  styleUrls: ['./customerror.component.scss'],
})
export class CustomerrorComponent {
  public notAuthorized: TopazErrorPageData
  @Input() errorImg: string = '404-unplug'
  @Input() errorHeading: string = 'Oops!'
  @Input() errorDescription: string =
    'Error occoured, please contact customer support!!!!!'
  @Input() buttonLabel: string = 'Home'

  constructor(public router: Router) {
    this.notAuthorized = new TopazErrorPageData()
  }
  ngOnInit() {
    this.notAuthorized.errorImage = TOPAZ_ERROR_IMAGE_LIST.get(this.errorImg)
    this.notAuthorized.errorH1Message = this.errorHeading
    this.notAuthorized.errorDescription = this.errorDescription
    this.notAuthorized.returnUrl = '/'
    this.notAuthorized.errorButtonLabel = this.buttonLabel
  }
}
