import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-full-width-layout',
  templateUrl: './full-width-layout.component.html',
  styleUrls: ['./full-width-layout.component.scss'],
})
export class FullWidthLayoutComponent {
  @Input() customClass?= "";
}
