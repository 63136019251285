import { AbstractControl, ValidationErrors } from "@angular/forms";

export function AgeValidator(control: AbstractControl): ValidationErrors | null {
    const v = control.value;
    const age = getAge(v)
    if (age < 13) {
        return { under13: true };
    }
    return null;
}

export function getAge(bdate: Date) {
    const dob = new Date(bdate);
    const dateDiff = Math.abs(Date.now() - dob.getTime());
    const age = Math.round(((dateDiff / (1000 * 3600 * 24)) / 365) * 100) / 100;
    return age
}