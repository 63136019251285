import { AbstractControl } from "@angular/forms";

function ValidateMinLength(control: AbstractControl) {
  if (!(control?.value?.length >= 16)) {
    return { minLength: true };
  }
  return null;
}
function ValidateMaxLength(control: AbstractControl) {
  if (!(control?.value?.length <= 20)) {
    return { maxLength: true };
  }
  return null;
}
function ValidateSpecialChar(control: AbstractControl) {
  return !control?.value?.match('^[a-zA-Z0-9- ]+$') ? { hasSpecialCharacter: true } : null;
}

export class StudentIdValidators {
  public static Validators = [
    ValidateMinLength, ValidateMaxLength, ValidateSpecialChar
  ];

  public static studentIdErrors = [
    'minLength',
    'maxLength',
    'hasSpecialCharacter',
  ]
}