<div
  *transloco="let t"
  aria-modal="true"
  role="dialog"
  aria-labelledby="modal-title"
  aria-describedby="modal-description">
  <div class="topaz-dialog-header pb-1">
    <h5 topazTitle="3" id="modal-title" data-id="unsaved-title">
      {{t('myprofile.unsaved_changes')}}
    </h5>
    
  </div>
    <div mat-dialog-content class="mb-2" data-id="unsaved-Msg">
        {{t('myprofile.unsaved_contect')}}
    </div>

  <div mat-dialog-actions align="end">
    <button
      mat-flat-button
      mat-dialog-close class="mr-1" (click)="confirm()" data-id="unsaved-btn-no">
      {{ t('common.no') }}
    </button>

    <button
      mat-flat-button
      mat-dialog-close
      color="warn" (click)="cancel()" data-id="unsaved-btn-yes">
      {{ t('common.yes') }}
    </button>
  </div>
</div>

