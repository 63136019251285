import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';

import { IESModel } from '../models/ies-model';
import { IesService } from './ies.service';

export const iesResolver: ResolveFn<IESModel> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(IesService).IESUserObject.asObservable();
};
