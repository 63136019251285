<div *transloco="let t">
  <ng-content></ng-content>

  <mat-divider class="mt-2 mb-1"></mat-divider>
  <div class="row">
    <div class="col-md">
      <div class="buttons step-action mdc-bttn-160 justify-content-end">
        <button mat-button class="fw-600 flex-m-order-2" (click)="cancel()">
          {{ t('common.cancel') }}
        </button>
        <button
          mat-flat-button
          class="fw-600 flex-m-order-1"
          type="submit"
          color="primary"
          (click)="submit()"
        >
          {{ t('userCredential.updateConfirmName') }}
        </button>
      </div>
    </div>
  </div>
</div>
