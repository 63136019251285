import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { NavBarTab, TabSubView } from '@pearsonvue/topaz-angular-ui';
import { Observable, map } from 'rxjs';

import {
  DefaultTab,
  HeaderConfig,
  OrgAdminTab,
  ProctorTab,
  TCGroupAdminTab,
  TeacherTab,
  TestCandidateTab,
} from '@src/libs/ui/src/lib/models/headerConfig';

import { CpHttpClientService } from '@src/libs/data-access/src/lib/services/cp-http-client.service';
import { APP_CONFIG, Config } from '../config/config';
import { DefaultProfile, PatchObj, Profile } from '../core/models';
import { AppState, selectRoles, selectSession } from '../core/store';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    protected store: Store<AppState>,
    private translationService: TranslocoService,
    private _http: CpHttpClientService,
  ) {}

  fetch(): Observable<Profile> {
    return this._http
      .get<Profile>(`${this.config.microServiceURI}/usermanagement/myprofile`)
      .pipe(
        map(({ success, data }) => {
          if (success) {
            return data;
          } else {
            return DefaultProfile;
          }
        }),
      );
  }

  private loggedOut(): Profile {
    return DefaultProfile;
  }

  update(patchs: PatchObj[]): Observable<Profile> {
    return this.fetch();
  }

  getAuthStatus(): Observable<boolean> {
    return this.store
      .select(selectSession)
      .pipe(map(session => !!session.PortalUserSessionID));
  }

  getHeaderRoles(): Array<number> {
    return [1, 312, 3, 23, 390];
  }

  getHeaderConfig(): Observable<HeaderConfig> {
    return this.store.select(selectRoles).pipe(
      map(roles => {
        var headerConfig: HeaderConfig = { tabs: [] };

        if (roles.length) {
          roles.forEach(role => {
            switch (role.roleID) {
              //Test Candidate
              case 1:
                headerConfig.tabs.push(this.translateTab(TestCandidateTab));
                break;
              //Instructor/Teacher
              case 312:
                headerConfig.tabs.push(this.translateTab(TeacherTab));
                break;
              //Proctor
              case 3:
                headerConfig.tabs.push(this.translateTab(ProctorTab));
                break;
              //OrgAdmin
              case 23:
                headerConfig.tabs.push(this.translateTab(OrgAdminTab));
                break;
              //Test Center Group Admin
              case 390:
                headerConfig.tabs.push(this.translateTab(TCGroupAdminTab));
                break;
            }
          });
        } else {
          headerConfig.tabs.push(this.translateTab(DefaultTab));
        }

        return headerConfig;
      }),
    );

    /*return of({
      tabs: [{
        label: 'Test candidate', subViews: []
      }]
    });
    return of({
      tabs: [{
        label: 'Test candidate', subViews: [
          { label: 'My Dashboard' },
          { label: 'My Transcript' },
          { label: 'My Pathway' },
          { label: 'My OnVUE' }
        ]
      }]
    });
    return of({
      tabs: [
        {
          label: 'Test candidate',
          subViews: [
            { label: 'My Dashboard' },
            { label: 'My Transcript' },
            { label: 'My Pathway' },
            { label: 'My OnVUE' }
          ]
        },
        {
          label: 'Proctor',
          subViews: [
            { label: 'Create and manage exam sessions' }
          ]
        },
        {
          label: 'Organization Administrator',
          subViews: [
            {
              label: 'Dashboard', data:
              {
                category: 'Dashboard',
                description: 'View overall statistics',
              }
            },
            { label: 'Purchase', data: { category: '', description: '' } },
            { label: 'Reports', data: { category: '', description: '' } },
            { label: 'Exam delivery', data: { category: '', description: '' } },
            { label: 'Org profile', data: { category: '', description: '' } },
            { label: 'Training' },
            { label: 'Exam group', data: { category: '', description: '' } }
          ]
        },
        {
          label: 'Instructor',
          subViews: [
            { label: 'Dashboard' },
            { label: 'Exam groups', data: { category: '', description: '' } },
            { label: 'Reports' }
          ]
        },
        {
          label: 'Test center group admin',
          subViews: [
            { label: 'Dashboard' },
            { label: 'Reports' }
          ]
        }
      ]
    });*/
  }

  private translateTab(tab: NavBarTab): NavBarTab {
    var cloneTab = structuredClone(tab);

    cloneTab.label = this.translationService.translate(tab.label);
    cloneTab.subViews.forEach(
      (view: TabSubView) =>
        (view.label = this.translationService.translate(view.label)),
    );
    return cloneTab;
  }
}
