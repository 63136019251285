import { Component, Input } from '@angular/core';
import { map, take, timer } from 'rxjs';

@Component({
  selector: 'lib-clever',
  templateUrl: './clever.component.html',
  styleUrls: ['./clever.component.scss'],
})
export class CleverComponent {
  @Input() cleverUrl = "";
  isShowLoading = false;

  cleverLogin(): void {
    this.isShowLoading = true;
    timer(0, 2000)
      .pipe(
        take(2),
        map((v) => v % 5 === 0)
      )
      .subscribe({
        next: (v) => {
          this.isShowLoading = v;
        },
        complete: () => (this.isShowLoading = false)
      });
    window.location.href = this.cleverUrl;
  }
}
