import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { TopazDialogMaxSize } from '@pearsonvue/topaz-angular-ui';
import {
  faCheck,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFlag,
  faLock,
  faX,
  faCaretDown,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { map, Observable, startWith, Subject, take, takeUntil } from 'rxjs';

import { CountryService } from '@src/app/services/common/country.service';
import { SlideInOutAnimation } from '@src/libs/utility/src/lib/animations/animations';
import { PasswordValidators } from 'src/libs/utility/src/lib/validators/password-validators';

import { UseragreementmodalComponent } from '../../components/useragreementmodal/useragreementmodal.component';
import { APP_CONFIG, Config } from '@src/app/config/config';
import { Country } from '@src/app/models/countries';
import { CountryErrors } from '@src/libs/utility/src/lib/validators/country-validators';
import { EmailExistsErrors } from '@src/libs/utility/src/lib/validators/email-exists-validator';
import { RegistrationFormService } from '../../services/registration-form.service';

@Component({
  selector: 'app-step-1',
  templateUrl: './step-1.component.html',
  styleUrls: ['./step-1.component.scss'],
  animations: [SlideInOutAnimation],
})
export class Step1Component implements OnDestroy, OnInit {
  @Input() loginFormGroup!: FormGroup;
  @Input() showImage!: boolean;
  @Output() next = new EventEmitter();
  @ViewChild('tos') checkbox!: MatCheckbox;

  countries: Observable<Country[]> = this.countryService.get();
  public filteredCountries!: Observable<Country[]>;
  passwordErrors: string[] = PasswordValidators.PasswordErrors;

  private _destroy = new Subject<void>();

  cleverUrl: string = this.config.cleverUrl;
  isToSChecked: boolean = false;
  dialogOpen: boolean = false;
  dialogToSStatus: boolean = false;
  showPassword: boolean = false;
  showPasswordCriterion: boolean = false;
  showSpinner: boolean = false;
  showValidationError: boolean = false;
  animationState = 'out';
  formSubmit: boolean = false;

  faFlag = faFlag;
  faEnvelope = faEnvelope;
  faLock = faLock;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faX = faX;
  faCheck = faCheck;
  faCircle = faCircle;
  faCaretDown = faCaretDown;

  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private _dialog: MatDialog,
    private translocoService: TranslocoService,
    private countryService: CountryService,
    private registrationFormService: RegistrationFormService,
  ) {
    registrationFormService.showEmailSpinner
      .pipe(takeUntil(this._destroy))
      .subscribe(value => {
        this.showSpinner = value;
      });
  }

  ngOnInit(): void {
    this.loginFormGroup
      .get('country')!
      .valueChanges.pipe(
        takeUntil(this._destroy),
        startWith(''),
        map(value => this.countryService.filterCountries(value ?? '')),
      )
      .subscribe(countries => (this.filteredCountries = countries));
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  get formValid(): boolean {
    return (
      this.loginFormGroup.touched &&
      this.loginFormGroup.valid &&
      this.checkbox?.checked
    );
  }

  onChangeToSCheckbox($event: MatCheckboxChange) {
    if (this.dialogOpen) {
      this.checkbox.checked = this.dialogToSStatus;
      this.isToSChecked = !this.checkbox.checked;
    } else {
      this.isToSChecked = !$event.checked;
    }
    if (this.isToSChecked) {
      this.loginFormGroup.get('tos')?.setErrors({ required: true });
    }
  }

  getPasswordError(validator: string): boolean {
    return this.loginFormGroup.get('password')?.hasError(validator) || false;
  }

  hasError(field: string): boolean {
    return (
      !!this.loginFormGroup.get(field)?.errors &&
      !!this.loginFormGroup.get(field)?.touched
    );
  }

  passwordError(): boolean {
    return (
      !!this.loginFormGroup.get('password')?.errors &&
      !!this.loginFormGroup.get('password')?.touched &&
      !this.showPasswordCriterion &&
      this.animationState === 'out'
    );
  }

  getValidationText(field: string): string {
    if (this.loginFormGroup.get(field)?.hasError('email')) {
      return this.translocoService.translate('signup.error.email');
    }
    if (this.loginFormGroup.get(field)?.hasError(CountryErrors.CountryExists)) {
      return this.translocoService.translate(`signup.error.required_${field}`);
    }

    if (
      this.loginFormGroup.get(field)?.hasError(EmailExistsErrors.EmailExists)
    ) {
      return this.translocoService.translate('signup.error.verifyEmail');
    }

    return this.loginFormGroup.get(field)?.hasError('required')
      ? this.translocoService.translate(`signup.error.required_${field}`)
      : '';
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  onPasswordFocusInEvent($event: FocusEvent): void {
    this.animationState = 'in';
    this.showPasswordCriterion = true;
  }

  onPasswordFocusOutEvent($event: FocusEvent): void {
    if (
      this.loginFormGroup.get('password')?.hasError('required') ||
      this.loginFormGroup.get('password')?.valid
    ) {
      this.animationState = 'out';
      setTimeout(() => {
        if (this.animationState === 'out') {
          this.showPasswordCriterion = false;
        }
      }, 100);
    }

    if (!this.loginFormGroup.get('tos')?.value) {
      this.loginFormGroup.get('tos')?.setErrors({ required: true });
    }
  }

  openUserAgreementModal($event: MouseEvent): void {
    this._dialog.open(UseragreementmodalComponent, {
      autoFocus: false,
      maxWidth: TopazDialogMaxSize.MEDIUM,
      panelClass: 'UserAgreementModal',
    });

    this.dialogOpen = true;
    this.dialogToSStatus = this.checkbox.checked;

    this._dialog.afterAllClosed.pipe(take(1)).subscribe(() => {
      this.dialogOpen = false;
      this.dialogToSStatus = false;
    });
  }

  submit(): void {
    this.loginFormGroup.markAllAsTouched();

    if (this.formValid) {
      this.formSubmit = false;
      this.next.emit();
    } else {
      this.formSubmit = true;
    }

    if (!this.checkbox?.checked) {
      this.isToSChecked = true;
    }
  }
}
