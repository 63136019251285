<topaz-toaster></topaz-toaster>
<div class="topaz-wrapper" *transloco="let t" [attr.dir]="textDirectionality">
  <lib-header
    *ngIf="showHeader"
    [auth]="fetchAuthStatus"
    [headerConfig]="fetchHeaderConfig"
    [userDetails]="fetchUserDetails"
    [headerRoles]="fetchHeaderRoles"
  ></lib-header>
  <div class="topaz-content-wrapper">
    <router-outlet></router-outlet>
  </div>
  <lib-footer></lib-footer>
</div>
