import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  TopazCapsuleModule,
  ErrorPageComponent,
  TopazBannerModule,
  TopazDirectivesModule,
  TopazFooterModule,
  TopazGlobalBarModule,
  TopazLoadingModule,
  TopazProgressBarModule,
  TopazSideSheetModule,
  TopazToastModule,
  TopazSvgModule,
  TopazFilterModule,
  TopazSearchModule,
} from '@pearsonvue/topaz-angular-ui';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TopazFooterModule,
    TopazGlobalBarModule,
    TopazProgressBarModule,
    TopazBannerModule,
    TopazLoadingModule,
    TopazDirectivesModule,
    TopazToastModule,
    TopazCapsuleModule,
    TopazSideSheetModule,
    ErrorPageComponent,
    TopazSvgModule,
    TopazFilterModule,
    TopazSearchModule,
  ],
  exports: [
    TopazFooterModule,
    TopazGlobalBarModule,
    TopazProgressBarModule,
    TopazBannerModule,
    TopazLoadingModule,
    TopazDirectivesModule,
    TopazToastModule,
    TopazCapsuleModule,
    TopazSideSheetModule,
    ErrorPageComponent,
    TopazSvgModule,
    TopazFilterModule,
    TopazSearchModule,
  ],
})
export class TopazUiModule {}
