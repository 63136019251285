import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { generateLoginRedirect } from '@certiport/login-library';
import { map, take } from 'rxjs';

import { APP_CONFIG } from '@src/app/config/config';
import { IesService } from './ies.service';

export const registrationNeededGuard: CanActivateFn = (route, state) => {
  const config = inject(APP_CONFIG);
  const router = inject(Router);
  const service = inject(IesService);

  const token = route.queryParams['code'] || '';

  let redirectUrl = '';
  const stateParam = decodeURIComponent(route.queryParams['state'] || '');
  const returnUrl = decodeURIComponent(route.queryParams['redirect'] || '');

  if (!!stateParam) {
    redirectUrl = stateParam.substring(9); //.replace('redirect=', '');
  } else if (!!returnUrl) {
    redirectUrl = returnUrl;
  }

  if (!token) {
    // If the token is not provided, redirect to the login page
    const baseLoginUri = config.baseLoginUri;
    const subLoginUri = config.subLoginUri;
    const ssoUri = config.ssoUri;

    window.location.href = generateLoginRedirect(
      baseLoginUri,
      subLoginUri,
      ssoUri,
      redirectUrl || '',
    );
    return false;
  }

  return service.fetchIESUser(token).pipe(
    take(1),
    map(result => {
      if (result.RegistrationNeeded)
        return true; // If the user needs to register, allow access
      else if (!result.PortalUserSessionID) {
        // If the user is not authenticated, redirect to the login page
        const baseLoginUri = config.baseLoginUri;
        const subLoginUri = config.subLoginUri;
        const ssoUri = config.ssoUri;

        window.location.href = generateLoginRedirect(
          baseLoginUri,
          subLoginUri,
          ssoUri,
          redirectUrl || '',
        );
        return false;
      } else {
        // TODO: Enable this after testing: Security Improvement
        // if (!validDomain(redirectUrl)) {
        //   // If the return URL is an absolute path and is in a certiport/local domain, navigate to it
        //   // Note: This is a security measure to prevent redirection to external domains
        //   window.location.href = `${config.portalRootUrl}/Portal/SSL/LoginRedirect.aspx?sessionId=${result.portalUserSessionID}`;
        //   return false;
        // }

        // If the user is authenticated, navigate to the return URL
        if (redirectUrl.startsWith('/')) {
          //TODO: remove profileFlag later
          if (config.profileFlag) {
            // If the return URL is a relative path, navigate to it
            const urlTree = router.parseUrl(redirectUrl);
            const path = urlTree.root.children['primary'].segments
              .map(it => it.path)
              .join('/');
            const queryParams = { ...urlTree.queryParams };

            router.navigate([path], { queryParams });
            return false;
          } else {
            //TODO: remove else block later
            // If profile flag is disabled, navigate to default legacy portal page
            // Note: This is a temporary solution to handle potental injection of relative path while profile flag is disabled
            window.location.href = `${config.portalRootUrl}/Portal/SSL/LoginRedirect.aspx?sessionId=${result.PortalUserSessionID}`;
            return false;
          }
        } else if (
          redirectUrl &&
          redirectUrl.indexOf('certiport.com') >= 0 &&
          config.name !== 'local'
        ) {
          // If the return URL is an absolute path and is in a certiport/local domain, navigate to it
          window.location.href = redirectUrl;
          return false;
        } else {
          // If there is no return URL provided, navigate to the default page
          //TODO: remove profileFlag later
          if (config.profileFlag && result.LoginCount <= 1) {
            // If the user is logging in for the first time, navigate to the profile page
            router.navigate(['/profile'], {
              queryParamsHandling: 'merge',
            });
            return false;
          }

          //TODO: define default page functionality and implement logic here to handle it
          //TODO: remove profileFlag later
          // If the return URL is not provided, navigate to the default page
          // if (config.profileFlag) {
          //   // If the user is not logging in for the first time, navigate to the default portal page
          //   router.navigate(['/profile'], {
          //     queryParamsHandling: 'preserve',
          //   });
          //   return false;
          // }

          // If profile flag is disabled and the return URL is not provided, navigate to default legacy portal page
          window.location.href = `${config.portalRootUrl}/Portal/SSL/LoginRedirect.aspx?sessionId=${result.PortalUserSessionID}`;
          return false;
        }
      }
    }),
  );
};

// TODO: flesh out valid domains
const validDomain = (url: string) => {
  const config = inject(APP_CONFIG);
  if (url.includes('certiport.com')) return true;
  else if (config.name === 'local') return true;
  else if (url.startsWith('/')) return true;
  else return false;
};
