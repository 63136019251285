import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { generateLoginRedirect } from '@certiport/login-library';

import { APP_CONFIG, Config } from '@src/app/config/config';

@Component({
  selector: 'app-login-v2',
  templateUrl: './login-v2.component.html',
  styleUrl: './login-v2.component.scss',
})
export class LoginV2Component implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_CONFIG) readonly config: Config,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.config.iesFlag) {
      this.document.location.href = generateLoginRedirect(
        this.config.baseLoginUri,
        this.config.subLoginUri,
        this.config.ssoUri,
        '',
      );
    } else {
      this.router.navigate(['/login']);
    }
  }
}
