import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  GoogleTagManagerConfiguration,
  GoogleTagManagerModule,
} from 'angular-google-tag-manager';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { APP_CONFIG, Config } from '@src/app/config/config';
import { AppInsightsService } from './services/app-insights.service';
import { AuditEventService } from './services/audit-event.service';

export function initializeApp(
  config: Config,
  googleTagManagerConfiguration: GoogleTagManagerConfiguration,
) {
  return () => {
    googleTagManagerConfiguration.set({ id: config.gaTrackingCode });
  };
}

@NgModule({
  imports: [CommonModule, GoogleTagManagerModule],
  providers: [
    AppInsightsService,
    AuditEventService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [APP_CONFIG, GoogleTagManagerConfiguration],
      multi: true,
    },
  ],
})
export class SrcLibsUtilityModule {}
