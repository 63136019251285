import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoModule } from '@jsverse/transloco';

import { CpHttpClientService } from './services/cp-http-client.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslocoModule,
  ],
  providers: [
    CpHttpClientService
  ]
})
export class SrcLibsDataAccessModule { }
