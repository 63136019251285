import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'signup-user-agreement',
  templateUrl: './useragreementmodal.component.html',
  styleUrls: ['./useragreementmodal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UseragreementmodalComponent {
  faXmark = faXmark;

  constructor(private _dialog: MatDialog) {}

  closeUserAgreementModal() {
    this._dialog.closeAll();
  }
}
