<div *transloco="let t">
  <ng-content></ng-content>

  <mat-divider class="mt-2 mb-1"></mat-divider>
  <div class="row">
    <div class="col-md">
      <div class="buttons mdc-bttn-160 d-flex d-none d-lg-block">
        <button mat-button class="fw-600 flex-m-order-2" [routerLink]="['/']" data-id="cancel-btn">
          {{ t('common.cancel') }}
        </button>
      </div>
    </div>
    <div class="col-md">
      <div class="buttons step-action mdc-bttn-160 justify-content-end">
        <button
          mat-stroked-button
          class="fw-600 flex-m-order-1"
          color="primary"
          (click)="back()" data-id="previous-btn">
          {{ t('common.previous') }}
        </button>
        <button
          mat-flat-button
          class="fw-600 flex-m-order-2"
          type="submit"
          color="primary"
          (click)="submit()"
          data-id="next-btn"
        >
          {{ t('common.next') }}
        </button>
      </div>
    </div>
  </div>
</div>
