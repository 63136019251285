<div *transloco="let t">
  <div class="text-center text-uppercase or-login-with">
    {{ t('login.orJoinWith') }}
  </div>
  <div class="buttons full-width">
    <button
      mat-stroked-button
      color="primary"
      (click)="cleverLogin()"
      topazButtonLoader
      [loading]="isShowLoading"
      data-id="clever-bttn"
    >
      <img
        src="assets/images/Clever_SSO_logo.svg"
        alt="Clever icon"
        class="mt2"
        style="margin-top: -5px"
      />
      {{ t('login.cleverLogin') }}
    </button>
  </div>
</div>
