<topaz-banner
  useIcon
  glow
  disableScrolling
  disableCloseButton
  [bannerType]="bannerType"
  [restoreFocus]="false"
>
  <div banner-content>
    <ng-content></ng-content>
  </div>
</topaz-banner>
