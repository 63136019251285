export interface Country {
  countryId: number;
  countryName: string;
}
const DefaultCountry: Country = {
  countryId: 222,
  countryName: 'USA',
};

export interface Roles {
  roleID: number;
  name: string;
  description: string;
  modified: string;
}

export interface TranslatedRoles extends Roles {
  translatedName: string;
}

export interface Accommodations {
  code: string;
  status: boolean;
  expirationDate: string;
}

export interface Profile {
  userId: string;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  alternateName: {
    firstName: string;
    middleName: string;
    lastName: string;
  } | null;
  isNameMatchedWithGovId: boolean;
  displayLastNameFirst: boolean;
  birthDate: string;
  preferredLanguageId: number;
  preferredLanguageCode: string;
  country: Country;
  //mandatory for italy and saudi
  studentId?: string | null;
  isActive: boolean;
  loginCount?: number | null;

  //under 13 flow
  isUserAgreementChecked: boolean;
  userAgreementDate: string;
  parentEmail?: string | null;

  roles: Roles[];
  accommodations: Accommodations[];

  alternateEmail: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: number;
  userAddressId: string;
  userName: string;
  isMigrated: boolean;

  //preferences
  mobilePhoneNumber: string;
  textMessagesOptedIn: boolean;
  marketingOptedIn: boolean;

  //Demographics
  enrolledAsStudent: boolean;
  currentlyEmployed: boolean;
}

export const DefaultProfile: Profile = {
  userId: '',
  email: '',
  firstName: '',
  middleName: '',
  lastName: '',
  alternateName: null,
  isNameMatchedWithGovId: false,
  displayLastNameFirst: false,
  birthDate: '',
  preferredLanguageId: 5,
  preferredLanguageCode: 'ENU',
  country: DefaultCountry,
  isActive: false,
  loginCount: null,
  userName: '',
  isMigrated: false,
  //under 13 flow
  isUserAgreementChecked: false,
  userAgreementDate: '',

  roles: [],
  accommodations: [],
  alternateEmail: '',
  phoneNumber: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: 0,
  userAddressId: '',

  //preferences
  mobilePhoneNumber: '',
  textMessagesOptedIn: false,
  marketingOptedIn: false,

  //Demographics
  enrolledAsStudent: false,
  currentlyEmployed: false,
};

