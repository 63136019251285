import { Component, forwardRef, Injector, Inject, OnInit, Input } from '@angular/core'
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { Subject, takeUntil, startWith, distinctUntilChanged, tap } from 'rxjs';

@Component({
  selector: 'lib-national-id',
  templateUrl: './national-id.component.html',
  styleUrls: ['./national-id.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NationalIdComponent),
      multi: true,
    },
  ],
})
export class NationalIdComponent implements ControlValueAccessor, OnInit {
  @Input() label = 'Student Id';
  @Input() isItaly = false;
  control!: FormControl;
  isRequired = false;

  private _destroy$ = new Subject<void>();
  private _onTouched!: () => any;

  constructor(
    @Inject(Injector) private injector: Injector,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.setFormControl();
    this.isRequired = this.control?.hasValidator(Validators.required) ?? false;
  }

  setFormControl() {
    try {
      const formControl = this.injector.get(NgControl);

      switch (formControl.constructor) {
        case FormControlName:
          this.control = this.injector
            .get(FormGroupDirective)
            .getControl(formControl as FormControlName);
          break;
        default:
          this.control = (formControl as FormControlDirective)
            .form as FormControl;
          break;
      }
    } catch (err) {
      this.control = new FormControl();
    }
  }

  writeValue(value: any): void {
    if (this.control && this.control.value != value)
      this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (val: any | null) => any): void {
    this.control?.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        startWith(this.control.value),
        distinctUntilChanged(),
        tap(val => fn(val)),
      )
      .subscribe(() => this.control?.markAsUntouched());
  }

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn;
  }

  hasError(field: string): boolean {
    return !!this.control?.errors && !!this.control?.touched;
  }

  getStudentIdValidationErrorMsg() {
    const getStudentId = this.control;
    if (getStudentId?.hasError('required')) {
      return !this.isItaly
        ? this.translocoService.translate('signup.error.required_studentID')
        : this.translocoService.translate('signup.error.required_codiceFicale');
    }
    if (getStudentId?.hasError('hasSpecialCharacter')) {
      return this.translocoService.translate(
        'signup.error.specialChars_codiceFicale',
      );
    }
    if (getStudentId?.hasError('minLength')) {
      return this.translocoService.translate(
        'signup.error.minLength_codiceFicale',
      );
    }
    if (getStudentId?.hasError('maxLength')) {
      return this.translocoService.translate(
        'signup.error.maxLength_codiceFicale',
      );
    }
    return '';
  }
}
