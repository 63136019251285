import { Injectable } from '@angular/core';
import { TopazBannerType } from '@pearsonvue/topaz-angular-ui';
import { BehaviorSubject, Observable } from 'rxjs';

export interface GlobalBannerObject {
  displayPage: string;
  status: TopazBannerType;
  content: string;
}

export interface GlobalToastObject {
  status: string;
  header: string;
  content: string;
}

var DefaultMessageObj: GlobalBannerObject = {
  displayPage: '',
  status: 'warn',
  content: '',
};
var DefaultToast: GlobalToastObject = {
  status: '',
  header: '',
  content: '',
};

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  globalToasts: BehaviorSubject<GlobalToastObject> =
    new BehaviorSubject<GlobalToastObject>(DefaultToast);
  autoLoginBanner: BehaviorSubject<GlobalBannerObject[]> = new BehaviorSubject<
    GlobalBannerObject[]
  >([DefaultMessageObj]);

  constructor() {}

  pushGlobalToast(value: GlobalToastObject): void {
    this.globalToasts.next(value);
  }
  getGlobalToast(): Observable<GlobalToastObject> {
    return this.globalToasts.asObservable();
  }

  pushNotificationBanner(banner: GlobalBannerObject): void {
    const currentBanners = this.autoLoginBanner.value;
    currentBanners.push(banner);
    this.autoLoginBanner.next(currentBanners);
  }
  getNotificationBanner(): Observable<GlobalBannerObject[]> {
    return this.autoLoginBanner.asObservable();
  }
}
