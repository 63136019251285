import { createAction, props } from "@ngrx/store";

const enum ApplicationActionKeys{
    PreLogout = '[Application]PreLogout',
    ResetTimer = '[Application]ResetTimer',
    ResetPreLogout = '[Application]ResetPreLogout',
    RefreshToken = '[Application]RefreshToken',    
    RefreshTokenSuccess = '[Application]RefreshTokenSuccess',    
    RefreshTokenFailure = '[Application]RefreshTokenFailure'    
}


export const preLogout = createAction(ApplicationActionKeys.PreLogout)
export const resetTimer = createAction(ApplicationActionKeys.ResetTimer)
export const resetPreLogout = createAction(ApplicationActionKeys.ResetPreLogout)
export const refreshToken = createAction(ApplicationActionKeys.RefreshToken)
export const refreshTokenSuccess = createAction(ApplicationActionKeys.RefreshTokenSuccess)
   
export const refreshTokenFailure = createAction(ApplicationActionKeys.RefreshTokenFailure,
    props<{error: string}>()
)