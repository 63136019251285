
<mat-form-field appearance="outline" *transloco="let t">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      required
      autocomplete="off"
      placeholder="{{label}}"
      [formControl]="control"
      [matDatepicker]="formPicker"
      [max]="todayDate" data-id="dob-matInput"/>
    <mat-datepicker-toggle matIconSuffix [for]="formPicker">
      <fa-icon
        matDatepickerToggleIcon
        [icon]="faCalendar"
        [ngClass]="[hasError() ? 'red' : '']"
      ></fa-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #formPicker></mat-datepicker>
    <mat-hint align="end">DD MMM YYYY</mat-hint>
    <mat-error *ngIf="hasError()">
      {{ getValidationText() }}
    </mat-error>
  </mat-form-field>
