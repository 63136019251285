import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  TimeoutDialogConfig,
  TimeoutSelectorComponent,
  TopazTimeoutDialogService,
} from '@pearsonvue/topaz-angular-ui';
import { APP_CONFIG, Config } from '@src/app/config/config';
import {
  AppState,
  ApplicationActions,
  SessionActions,
} from '@src/app/core/store';
import { preLogoutSelector } from '@src/app/core/store/application/application.selector';
import { Subscription } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'timeout',
  templateUrl: './timeout.component.html',
})
export class TimeOutComponent implements OnInit {
  private timeoutDialogSubscribeValue!: Subscription;

  subscriptions: Subscription[] = [];
  constructor(
    private timeoutDialogService: TopazTimeoutDialogService,
    private store: Store<AppState>,
    @Inject(APP_CONFIG) readonly config: Config,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.store.select(preLogoutSelector).subscribe(data => {
      if (data) {
        const timoutConfig: TimeoutDialogConfig = {
          countdownTimerInMS: this.config.countdownTimerInMS,
          dialogHeaderText: this.translocoService.translate(
            'common.autoLogout.dialogHeaderText',
          ),
          dialogBodyText: this.translocoService.translate(
            'common.autoLogout.dialogBodyText',
          ),
          dialogBodyCountdownToken: '{0}',
          logoutButtonText: this.translocoService.translate(
            'common.logOut',
          ),
          continueButtonText: this.translocoService.translate(
            'common.autoLogout.continueButtonText',
          ),
          hideLogoutButton: false,
          hideContinueButton: false,
        };
        this.timeoutDialogService.open(TimeoutSelectorComponent, timoutConfig);
        this.timeoutDialogSubscribeValue =
          this.timeoutDialogService.selectedTimeoutSubject$.subscribe(value => {
            if (value === 1 || value === 2) {
              // this is logout or close dialog by timeout
              this.store.dispatch(SessionActions.clear());
            }
            if (value === 0) {
              // this is when continuing.
              this.store.dispatch(ApplicationActions.resetPreLogout());
              this.store.dispatch(ApplicationActions.resetTimer());
            }

            this.timeoutDialogSubscribeValue.unsubscribe();
          });
      }
    });
  }
}
