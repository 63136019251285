import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AuthenticationOutput,
  AuthenticationService,
  AutoLogoutTimerService,
  IUserDetail,
  RefreshService,
  StorageService,
  UserDetailService,
} from '@certiport/login-library';
import { Store } from '@ngrx/store';
import {
  Observable,
  Subscription,
  catchError,
  finalize,
  map,
  of,
  tap,
} from 'rxjs';

import { AppState, CoreActions, ProfileActions } from '../store';
import { SessionActions } from '../store/session';
import { APP_CONFIG, Config } from '@src/app/config/config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  subscriptions: Subscription[] = [];

  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private store: Store<AppState>,
    private userDetailService: UserDetailService,
    private authService: AuthenticationService,
    private refreshService: RefreshService,
    private autoLogoutTimerService: AutoLogoutTimerService,
    private storageService: StorageService
  ) {}

  login(username: string, password: string): Observable<any> {
    this.store.dispatch(SessionActions.update());

    return this.authService.login(username, password).pipe(
      tap((authOut: HttpResponse<AuthenticationOutput>) => {
        this.setRefreshToken(authOut.body!);
      }),
      map(authOut => {
        this.userDetailService.setUserDetail(
          this.buildUserDetail(authOut.body as AuthenticationOutput),
        );
        this.store.dispatch(
          SessionActions.updateSuccess({ session: authOut.body! }),
        );

        if (!this.config.profileFlag) {
          this.store.dispatch(ProfileActions.fetch());
        }
        return true;
      }),
      catchError((err, caught) => {
        this.store.dispatch(SessionActions.updateFailure({ error: err }));
        return of(false);
      }),
    );
  }

  loginWithSessionId(sid: string): Observable<boolean> {
    return this.authService.AuthenticateWithSid(sid).pipe(
      tap((authOut: HttpResponse<AuthenticationOutput>) => {
        this.setRefreshToken(authOut.body!);
      }),
      map(result => {
        this.userDetailService.setUserDetail(
          this.buildUserDetail(result.body as AuthenticationOutput),
        );
        this.store.dispatch(
          SessionActions.updateSuccess({ session: result.body! }),
        );

        if (!this.config.profileFlag) {
          this.store.dispatch(ProfileActions.fetch());
        }
        return true;
      }),
      catchError((err, caught) => {
        this.store.dispatch(SessionActions.updateFailure({ error: err }));
        return of(false);
      }),
    );
  }

  //TODO: Implement this
  //  validate(): Observable<boolean> {
  //   this.authService.validate1().pipe(
  //     map(result => {
  //       if (result?.body?.RefreshToken) {
  //         /*localStorage.setItem(
  //           LoginStorageTokens.Refresh,
  //           result.body?.RefreshToken!,
  //         );*/
  //         this.store.dispatch(
  //           SessionActions.updateSuccess({ session: result.body! }),
  //         );
  // if (inject(APP_CONFIG).profileFlag) {
  //   this.store.dispatch(ProfileActions.fetch());
  // }
  //         return true;
  //       }
  //       return false;
  //     }),
  //     catchError((err, caught) => {
  //       this.store.dispatch(SessionActions.updateFailure({ error: err }));
  //       return of(false);
  //     }),
  //   );
  // }

  logout(): Observable<boolean> {
    this.autoLogoutTimerService.stop();
    return this.authService.logout().pipe(
      finalize(() => {
        this.clearSessionData();
      }),
      map(() => true),
      catchError((err, caught) => {
        return of(false);
      }),
    );
  }

  refresh(): Observable<boolean> {
    return this.refreshService.RefreshToken().pipe(
      catchError((e, caught) => {
        this.clearSessionData();
        return of(false);
      }),
    );
  }

  setRefreshToken(authOut: AuthenticationOutput) {
    this.storageService.setItem(
      'cptlogin__refreshToken',
      authOut.RefreshToken || '',
    );
  }

  buildUserDetail(user: AuthenticationOutput): IUserDetail {
    return {
      userName: user.UserDisplayName || '',
      userId: user.UserId || -1,
      userRoles: user.UserRoles || [],
      loginExpiry: new Date(user.LoginExpiry || ''),
    };
  }

  private clearSessionData(): void {
    localStorage.clear();
    this.userDetailService.setUserDetail(EmptyUserDetails);
    this.store.dispatch(CoreActions.logout());
  }
}

const EmptyUserDetails: IUserDetail = {
  userName: '',
  userId: 0,
  userRoles: [],
  loginExpiry: new Date(0),
};
