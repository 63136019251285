import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function notMatchValidator(
  notMatchTo: string,
  reverse?: boolean,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.parent && reverse) {
      const c = (control.parent?.controls as any)[notMatchTo] as AbstractControl
      if (c) {
        c.updateValueAndValidity()
      }
      return null
    }

    return !!control.parent &&
      !!control.parent.value &&
      control.value !== (control.parent?.controls as any)[notMatchTo].value
      ? null
      : { matching: true }
  }
}
