import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CountryService } from '@src/app/services/common/country.service';

export enum CountryErrors {
  CountryExists = 'countryExists',
}

export class CountryValidators {
  static createExistsValidator(countryService: CountryService): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const result = countryService.getCountryByDesc(control.value);
      return result ? null : { countryExists: true };
    };
  }
}
