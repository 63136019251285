<lib-full-width-layout *transloco="let t" [customClass]="'login'">
  <lib-wide-layout>
    <div class="col-lg">
      <mat-card aria-labelledby="long-card-title">
        <header>
          <mat-card-title>
            <h1 data-id="login-title-h1" topazTitle="1" class="text-center">
              {{ t('common.login') }}
            </h1>
          </mat-card-title>
        </header>

        <mat-card-content>
          <form
            #loginForm
            class="d-flex flex-column gap-2"
            [formGroup]="loginFormGroup"
            (ngSubmit)="handleLogin($event)"
          >
            <topaz-banner
              *ngIf="invalidControl"
              glow
              useIcon
              disableScrolling
              disableCloseButton
              bannerType="warn"
              [restoreFocus]="false"
            >
              <div banner-content>
                <p class="m-0">
                  <b data-id="login-invalidControl-title">{{
                    t('common.uiErrorBanner.errorsHaveBeenFound')
                  }}</b>
                </p>
              </div>
            </topaz-banner>
            <topaz-banner
              *ngIf="showAuthError"
              useIcon
              glow
              disableScrolling
              disableCloseButton
              bannerType="warn"
              [restoreFocus]="false"
              [restoreFocus]="false"
            >
              <div banner-content>
                <p class="m-0">
                  <b data-id="login-authError-title">
                    {{ t('login.invalidLogin') }}
                  </b>
                </p>
                <p class="m-0" data-id="login-authError-body">
                  {{ t('login.tryLoginAgain') }}
                  <a [routerLink]="['/signup']">{{ t('common.joinFor') }}</a>
                </p>
              </div>
            </topaz-banner>

            @for (item of otherBanners; track item; let i = $index) {
            <topaz-banner
              glow
              useIcon
              disableScrolling
              disableCloseButton
              [bannerType]="item.status"
              [restoreFocus]="false"
            >
              <div banner-content>
                <p class="m-0">
                  <b data-id="login-banner-title-{{ i }}">{{ item.content }}</b>
                </p>
              </div>
            </topaz-banner>
            }

            <br />

            <mat-form-field appearance="outline">
              <mat-label data-id="login-email-lbl">
                {{ t('common.email') }} / {{ t('common.username') }}
              </mat-label>
              <input
                #usernameRef
                matInput
                required
                formControlName="username"
                type="text"
                autocomplete="off"
                data-id="login-email-input"
              />
              <fa-icon [icon]="faEnvelope" matIconPrefix></fa-icon>
              <mat-error
                *ngIf="loginFormGroup.controls.username.invalid"
                data-id="login-email-error"
              >
                {{ t('login.emailEmpty') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label data-id="login-password-lbl">{{
                t('common.password')
              }}</mat-label>
              <input
                matInput
                required
                id="loginpassword"
                formControlName="password"
                placeholder="********"
                name="password"
                autocomplete="off"
                data-id="login-password-input"
                [type]="showPassword ? 'text' : 'password'"
              />
              <fa-icon [icon]="faLock" matIconPrefix></fa-icon>
              <fa-icon
                matIconSuffix
                [icon]="showPassword ? faEye : faEyeSlash"
                (click)="togglePasswordVisibility()"
              ></fa-icon>
              <mat-error
                *ngIf="loginFormGroup.controls.password.invalid"
                class="p-0"
                data-id="login-password-error"
              >
                {{ t('login.passwordEmpty') }}
              </mat-error>
            </mat-form-field>

            <div class="d-flex justify-content-end">
              <h4 class="forgot-pwd">
                <a
                  routerLink="forgot-usnpw"
                  queryParamsHandling="preserve"
                  data-id="login-account-recovery-link"
                  [routerLink]="['/account-recovery']"
                >
                  {{ t('login.forgotPassword') }}
                </a>
              </h4>
            </div>

            <div class="buttons full-width login-btn">
              <button
                mat-flat-button
                topazButtonLoader
                color="primary"
                type="submit"
                data-id="login-login-btn"
                [loading]="isLoading"
              >
                {{ t('common.login') }}
              </button>
            </div>
          </form>

          <div class="buttons full-width">
            <button
              mat-stroked-button
              topazButtonLoader
              color="primary"
              data-id="login-register-btn"
              (click)="handleRegister()"
            >
              {{ t('common.joinFor') }}
            </button>
          </div>

          <hr />

          <!--clever redirect-->
          <lib-clever [cleverUrl]="cleverRedirect"></lib-clever>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-lg d-flex align-items-center justify-content-center">
      <div class="ml-2">
        <img
          src="assets/images/portal_login_img.png"
          alt="{{ t('login.img_alttext') }}"
          data-id="login-marketing-img"
          class="d-none d-lg-block"
        />
      </div>
    </div>
  </lib-wide-layout>
</lib-full-width-layout>
