<div *transloco="let t">
  <lib-custom-banner *ngIf="!formValid && formSubmit">
    <p class="m-0" data-id="ies-error-banner">
      <b>{{ t('common.uiErrorBanner.errorsHaveBeenFound') }}</b>
    </p>
  </lib-custom-banner>
  <form [formGroup]="usercredentialForm">
    <ul>
      <li>
        <div class="row">
          <div class="col-sm col-md-6">
            <div class="row">
              <label
                class="text-uppercase col-6 col-md-12 col-lg-12"
                data-id="ies-firstname-label"
                >{{ t('common.firstName') }} *</label
              >
              <p class="col" data-id="ies-firstname-value">
                {{ userInfo.PartialRegistrationObject.FirstName }}
              </p>
            </div>
          </div>
          <div class="col-sm col-md-6">
            <div class="row">
              <label
                class="col-6 col-md-12 col-lg-12 text-uppercase"
                data-id="ies-lastName-label"
                >{{ t('common.lastName') }} *</label
              >
              <p class="col" data-id="ies-lastName-value">
                {{ userInfo.PartialRegistrationObject.LastName }}
              </p>
            </div>
          </div>
        </div>
      </li>
      <li>
        <label data-id="ies-myGovid-label">
          {{ t('userCredential.myGovid') }} *
          <button
            #tooltip="matTooltip"
            matSuffix
            attr.aria-label="{{ t('signup.tooltip.govID') }}"
            class="tooltip-bttn"
            mat-button
            [matTooltip]="t('signup.tooltip.govID')"
            [matTooltipPosition]="'above'"
            role="link"
            data-id="ies-myGovid-buttn"
          >
            <fa-icon
              matIconPrefix
              size="xs"
              [icon]="faCircleInfo"
              data-id="ies-myGovid-icon"
            ></fa-icon>
          </button>
        </label>
        <div class="remove-radio-shadow">
          <mat-radio-group
            formControlName="myGovStudentId"
            [ngClass]="{
              'is-invalid': hasError('myGovStudentId') && formSubmit
            }"
            #myGovStudentId
            data-id="ies-myGovStudentId-label"
          >
            <mat-radio-button
              value="Yes"
              (change)="openGovPopup($event.value)"
              class="mr-1"
              data-id="ies-myGovStudentId-yes-text"
            >
              {{ t('common.yes') }}</mat-radio-button
            >
            <mat-radio-button
              value="No"
              (change)="openGovPopup($event.value)"
              class="mr-1"
              data-id="ies-myGovStudentId-no-text"
            >
              {{ t('common.no') }}</mat-radio-button
            >
            <mat-error
              data-id="ies-required_govID"
              *ngIf="hasError('myGovStudentId')"
            >
              {{ t('signup.error.required_govID') }}
            </mat-error>
          </mat-radio-group>
        </div>
      </li>
      <li>
        <div class="row">
          <label
            class="text-uppercase col-6 col-md-12 col-lg-12"
            data-id="ies-email"
            >{{ t('common.email') }}*
          </label>
          <p class="col-11" data-id="ies-email-value">
            {{ userInfo.PartialRegistrationObject.UserName }}
          </p>
        </div>
      </li>
      <li class="row">
        <div class="col-11">
          <mat-form-field appearance="outline">
            <mat-label data-id="ies-dob-label">{{
              t('common.dob_input')
            }}</mat-label>
            <input
              matInput
              required
              autocomplete="off"
              placeholder="DD MMM YYYY"
              formControlName="birthDate"
              [matDatepicker]="formPicker"
              [max]="todayDate"
              data-id="ies-dob-input"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="formPicker"
              data-id="ies-dob-picker"
            >
              <fa-icon matDatepickerToggleIcon [icon]="faCalendar"></fa-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #formPicker></mat-datepicker>
            <mat-hint align="end" data-id="ies-dob-hint">DD MMM YYYY</mat-hint>
            <mat-error *ngIf="hasError('birthDate')" data-id="ies-dob-error">
              {{ getValidationText('birthDate') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-1 mt-1 justify-content-center">
          <button
            #tooltip="matTooltip"
            matSuffix
            aria-label="tooltip help"
            class="tooltip-bttn mt-1 mleft-15"
            mat-button
            attr.aria-label="{{ t('signup.tooltip.dateOfBirth') }}"
            matTooltip="{{ t('signup.tooltip.dateOfBirth') }}"
            [matTooltipPosition]="'above'"
            role="link"
            data-id="ies-dob-tooltip"
          >
            <fa-icon
              matIconPrefix
              size="sm"
              fa-sr-only
              [icon]="faCircleInfo"
              [attr.aria-hidden]="false"
              data-id="ies-dob-faicon"
            ></fa-icon>
            <span class="fa-sr-only">Info icon</span>
          </button>
        </div>
      </li>
      <li class="row">
        <div class="col-11">
          <mat-form-field appearance="outline">
            <mat-label data-id="ies-county-label">{{
              t('common.country')
            }}</mat-label>
            <input
              required
              matInput
              type="text"
              formControlName="country"
              [matAutocomplete]="countryAuto"
              data-id="ies-county-input"
            />
            <fa-icon
              [icon]="faCaretDown"
              size="sm"
              matIconSuffix
              data-id="ies-caredown"
            ></fa-icon>
            <mat-autocomplete
              #countryAuto="matAutocomplete"
              (optionSelected)="setupCountryValidators()"
            >
              <mat-option
                *ngFor="let item of filteredCountries | async"
                [value]="item.name"
                data-id="ies-county-option"
              >
                {{ item.name }}
              </mat-option>
            </mat-autocomplete>
            <fa-icon
              matIconPrefix
              size="sm"
              [icon]="faFlag"
              [ngClass]="[hasError('country') ? 'red' : '']"
            ></fa-icon>
            <mat-error *ngIf="hasError('country')" data-id="ies-county-error">
              {{ getValidationText('country') }}
            </mat-error>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showStudentId" class="row">
        <div class="col-11">
          <mat-form-field appearance="outline">
            <mat-label data-id="ies-studentId-label">
              {{ isItaly ? italy_studentId : t('common.studentID') }}
            </mat-label>
            <input
              matInput
              required
              type="text"
              formControlName="studentID"
              placeholder="{{
                isItaly ? italy_studentId : t('common.studentID')
              }}"
              [maxlength]="isItaly ? 20 : 50"
              data-id="ies-studentId-input"
            />
            <mat-error
              *ngIf="hasError('studentID')"
              data-id="ies-studentId-error"
            >
              {{ getStudentIdValidationErrorMsg() }}
            </mat-error>
          </mat-form-field>
        </div>
      </li>
      <div>
        <div class="basic-checkboxes show-pwd-container mb-1">
          <mat-checkbox
            #tos
            [formControlName]="'tos'"
            [ngClass]="{ 'is-invalid': isToSChecked }"
            (change)="onChangeToSCheckbox($event)"
            data-id="ies-tos-checkbox-label"
          >
            {{ t('signup.tosAgree.1') }}
            <a
              class="topaz-link-bold"
              href="javascript:void(0)"
              (click)="openUserAgreementModal($event)"
              data-id="ies-tosAgree-link"
            >
              {{ t('signup.tosAgree.2') }}</a
            >*
            <mat-error
              class="is-invalid"
              *ngIf="isToSChecked"
              data-id="ies-tosAgree-error"
            >
              {{ t('signup.error.required_ToS') }}
            </mat-error>
          </mat-checkbox>
        </div>
      </div>
    </ul>

    <div class="buttons full-width">
      <button
        #submitButton
        mat-flat-button
        topazButtonLoader
        type="button"
        color="primary"
        [loading]="showSpinner"
        (click)="submit()"
        data-id="ies-submit-bttn"
      >
        {{ t('userCredential.register') }}
      </button>
    </div>

    <div class="buttons full-width">
      <button
        #submitButton
        mat-button
        topazButtonLoader
        type="button"
        (click)="backtoLogin()"
        data-id="ies-cancel-bttn"
      >
        {{ t('common.cancel') }}
      </button>
    </div>
  </form>
</div>
