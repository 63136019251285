<div *transloco="let t">
  <lib-wide-layout>
    <form [formGroup]="identityFormGroup">
      <div class="row certPositionorder">
        <div class="col-lg">
          <h1 topazTitle="1" class="text-center d-none d-lg-block" data-id="res-s2-title">
            {{ t('signup.identityTitle') }}
          </h1>
          <lib-custom-banner *ngIf="!this.formValid && formSubmit">
            <p class="m-0" data-id="res-step2-errorbanner">
              <b>{{ t('common.uiErrorBanner.errorsHaveBeenFound') }}</b>
            </p>
          </lib-custom-banner>
          <div>
            <mat-form-field appearance="outline">
              <mat-label  data-id="res-s2-frname-label">{{ t('common.firstName') }}</mat-label>
              <input
                matInput
                required
                maxlength="50"
                [formControlName]="'firstName'"
                [placeholder]="t('common.firstName')"
                data-id="res-s2-frname-input"
              />
              <mat-error *ngIf="hasError('firstName')" data-id="res-s2-frname-err">
                {{ getValidationText('firstName') }}
              </mat-error>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label data-id="res-s2-mdname-label">{{ t('common.middleName') }}</mat-label>
              <input
                matInput
                maxlength="50"
                [formControlName]="'middleName'"
                [placeholder]="t('common.middleName')"
                data-id="res-s2-mdname-input"
              />
              <mat-error *ngIf="identityFormGroup?.get('middleName')?.invalid" data-id="res-s2-mdname-err">
                {{ getValidationText('middleName') }}
              </mat-error>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label data-id="res-s2-laname-label">{{ t('common.lastName') }}</mat-label>
              <input
                matInput
                required
                maxlength="50"
                [formControlName]="'lastName'"
                [placeholder]="t('common.lastName')"
                data-id="res-s2-laname-input"
              />
              <mat-error *ngIf="hasError('lastName')" data-id="res-s2-laname-err">
                {{ getValidationText('lastName') }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="basic-checkboxes">
            <mat-checkbox
              #isNameMatchedWithGovId
              [formControlName]="'isNameMatchedWithGovId'"
              [ngClass]="{ 'is-invalid': isGovIdChecked }"
              (change)="onChangeGovIdCheckbox($event)"
            >
              <span class="checkbox-txt" data-id="res-s2-govid-label">
                {{ t('signup.chk_matchGovID') }}
                <span>* &nbsp;</span>
              </span>
              <mat-error class="is-invalid" *ngIf="isGovIdChecked" data-id="res-s2-govid-err">
                {{ t('signup.error.required_govID') }}
              </mat-error>
              <button
                #tooltip="matTooltip"
                matSuffix
                class="tooltip-bttn"
                mat-button
                attr.aria-label="{{ t('signup.tooltip.govID') }}"
                [matTooltip]="t('signup.tooltip.govID')"
                [matTooltipPosition]="'above'"
                role="link"
                data-id="res-s2-tooltip-govID"
              >
                <fa-icon
                  matIconPrefix
                  size="xs"
                  fa-sr-only
                  [icon]="faCircleInfo"
                  [attr.aria-hidden]="false"
                ></fa-icon>
                <span class="fa-sr-only">Info icon</span>
              </button>

              <mat-error class="is-invalid" *ngIf="isGovIdChecked" data-id="res-s2-govid-err">
                {{ t('signup.error.required_govID') }}
              </mat-error>
            </mat-checkbox>
          </div>

          <div class="basic-checkboxes">
            <mat-checkbox
              #displayLastNameFirst
              [formControlName]="'displayLastNameFirst'"
              data-id="res-s2-lastNameFirst-check"
            >
              <span class="checkbox-txt" data-id="res-s2-lastNameFirst-check-txt">
                {{ t('signup.chk_lastNameFirst') }}
              </span>
              <button
                #tooltip="matTooltip"
                matSuffix
                attr.aria-label="{{ t('signup.tooltip.govID') }}"
                mat-button
                class="tooltip-bttn"
                [matTooltip]="t('signup.tooltip.govID')"
                [matTooltipPosition]="'above'"
                data-id="res-s2-lastNameFirst-tooltip-btn"
              >
                <fa-icon
                  matIconPrefix
                  size="xs"
                  [icon]="faCircleInfo"
                  aria-hidden="false"
                ></fa-icon>
              </button>
            </mat-checkbox>
          </div>
        </div>

        <div class="col-lg">
          <div class="example-wrapper">
            <lib-card>
              <div>
                <header>
                  <mat-card-title>
                    <h5 topazTitle="5" class="text-center" data-id="res-s2-exampleCert-title">
                      <label class="excert">
                        {{ t('signup.exampleCert') }}
                      </label>
                    </h5>
                  </mat-card-title>
                </header>
              </div>
              <p class="text-center" data-id="res-s2-exampleCert-desc">{{ t('signup.exampleCert_desc') }}</p>
              <p></p>
              <div class="position-relative">
                <img
                  src="assets/images/reg_cert_example.svg"
                  attr.aria-label="{{ t('signup.exampleCert_desc') }}"
                  style="display: block"
                  class="img-fluid example-cert"
                  alt="{{ t('signup.exampleCert_desc') }}"
                />
                <button
                  #tooltip="matTooltip"
                  matSuffix
                  attr.aria-label="{{ t('signup.tooltip.govID') }}"
                  [matTooltip]="getDisplayName()"
                  [matTooltipPosition]="'above'"
                  class="overlay-text overlay"
                  data-id="res-s2-tooltip-govID"
                >
                  {{ getDisplayName() }}
                </button>
              </div>
            </lib-card>
          </div>
        </div>
      </div>
    </form>
  </lib-wide-layout>
</div>
