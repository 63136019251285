import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-step-2',
  templateUrl: './step-2.component.html',
  styleUrls: ['./step-2.component.scss'],
})
export class Step2Component implements AfterViewInit {
  @Input() identityFormGroup!: FormGroup;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @ViewChild('isNameMatchedWithGovId') matchGovID!: MatCheckbox;
  @ViewChild('displayLastNameFirst') displayLastNameFirst!: MatCheckbox;

  isGovIdChecked: boolean = false;
  displayName: string = '';
  formSubmit: boolean = false;

  faCircleInfo = faCircleInfo;

  constructor(private translocoService: TranslocoService) {}

  ngAfterViewInit(): void {
    if (this.identityFormGroup.get('isNameMatchedWithGovId')?.value) {
      this.isGovIdChecked = false;
    }
  }

  get formValid(): boolean {
    return (
      this.identityFormGroup?.touched &&
      this.identityFormGroup?.valid &&
      this.matchGovID?.checked
    );
  }

  hasError(field: string): boolean {
    return (
      !!this.identityFormGroup.get(field)?.errors &&
      !!this.identityFormGroup.get(field)?.touched
    );
  }

  getValidationText(field: string): string {
    return this.identityFormGroup?.get(field)?.hasError('required')
      ? this.translocoService.translate(`signup.error.required_${field}`)
      : '';
  }

  getMiddleName(val: any): string {
    if (val.get('middleName')?.value.length > 0) {
      return `${val.get('middleName')?.value} `;
    } else {
      return '';
    }
  }

  checkErrorForGovId() {
    if (!this.identityFormGroup.get('isNameMatchedWithGovId')?.value) {
      this.isGovIdChecked = true;
      this.identityFormGroup
        .get('isNameMatchedWithGovId')
        ?.setErrors({ required: true });
    }
  }

  getDisplayName(): string {
    const first = this.identityFormGroup?.get('firstName')?.value || '';
    const middle = this.identityFormGroup?.get('middleName')?.value || '';
    const last = this.identityFormGroup?.get('lastName')?.value || '';
    const firstLast = `${first} ${middle} ${last}`;
    const lastFirst = `${last} ${first} ${middle}`;

    return this.displayLastNameFirst?.checked ? lastFirst : firstLast;
  }

  back(): void {
    this.previous.emit();
  }

  onChangeGovIdCheckbox(event: MatCheckboxChange) {
    this.isGovIdChecked = !event.checked;
    if (this.isGovIdChecked) {
      this.identityFormGroup
        .get('isNameMatchedWithGovId')
        ?.setErrors({ required: true });
    }
  }

  submit(): void {
    this.formSubmit = true;
    this.identityFormGroup.markAllAsTouched();

    if (!this.matchGovID?.checked) {
      this.isGovIdChecked = true;
    }

    if (this.identityFormGroup?.valid && this.matchGovID?.checked) {
      this.next.emit(this.identityFormGroup.value);
    }
  }
}
