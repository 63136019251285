export interface RegistrationModel {
  userId?: string | null
  email: string
  country: ApiCountryData
  firstName: string
  middleName: string
  lastname: string
  password: string
  isNameMatchedWithGovId: boolean
  isUserAgreementChecked: boolean
  displayLastNameFirst: boolean
  birthDate: string
  studentId: string
  parentApproved: boolean
  parentEmail?: string | null
  isActive: boolean
  reCaptchaToken: string
  automation: boolean
  preferredLanguageId: number
}

export interface ApiCountryData {
  countryId: number
  countryname: string
}

export const DefaultRegistrationModel: RegistrationModel = {
  email: 'student@email.com',
  country: {
    countryId: 0,
    countryname: ''
  },
  firstName: '',
  middleName: '',
  lastname: '',
  password: '',
  isNameMatchedWithGovId: false,
  isUserAgreementChecked: true,
  displayLastNameFirst: false,
  birthDate: '',
  studentId: '',
  parentApproved: true,
  parentEmail: null,
  isActive: true,
  reCaptchaToken: '',
  automation: false,
  preferredLanguageId: 5
}
