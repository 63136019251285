import { Pipe, PipeTransform } from '@angular/core';
import { LanguagePickerService } from '../services/common/language-picker.service';

@Pipe({ name: 'appendDefaultLang', pure: false })
export class AppendDefaultLangPipe implements PipeTransform {
  private languageCode = '';
  constructor(private languagePicker: LanguagePickerService) {
    this.languagePicker.selectedLanguageProps$.subscribe(langOption => {
      if (langOption?.languageCode) {
        this.languageCode = langOption?.languageCode;
      }
    });
  }

  private hasDefaultLangField(url: string) {
    return new RegExp('defaultlang', 'i').test(url);
  }

  transform(
    originUrl: string,
    _languageCode: string = this.languageCode ?? 'enu',
  ): string {
    if (this.hasDefaultLangField(originUrl)) {
      return originUrl;
    }

    const separator = originUrl.includes('?') ? '&' : '?';
    return `${originUrl}${separator}defaultlang=${_languageCode}`;
  }
}
