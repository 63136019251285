import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@pearsonvue/topaz-angular-ui';
import { TranslocoService } from '@jsverse/transloco';
import { Observable, catchError, map, throwError } from 'rxjs';

import { HttpResponseModel } from '../models/http-response.model';
import { CpHttpMethod } from '../models/cp-http-method.enum';
import { CustomToastComponent } from '@src/libs/ui/src/lib/components/custom-toast/custom-toast.component';
import { ToastData } from '@src/libs/ui/src/lib/components/custom-toast/model/toast-data.model';
import { ToastConfigService } from '@src/libs/ui/src/lib/components/custom-toast/services/config/toast-config-service.service';

export interface GenericCpHttpResponse<T> {
  success: boolean;
  data: T;
}

@Injectable({
  providedIn: 'root',
})
export class CpHttpClientService {
  constructor(
    private _http: HttpClient,
    private _toastService: ToastService<ToastData>,
    private _translocoService: TranslocoService,
    private _toastConfigService: ToastConfigService,
  ) {}

  get<T>(url: string, option?: any): Observable<HttpResponseModel<T>> {
    return this._httpClient<T>(url, CpHttpMethod.GET, option);
  }

  post<T>(
    url: string,
    data: any,
    option?: any,
  ): Observable<HttpResponseModel<T>> {
    return this._httpClient<T>(url, CpHttpMethod.POST, data, option);
  }

  put<T>(
    url: string,
    data: any,
    option?: any,
  ): Observable<HttpResponseModel<boolean>> {
    return this._httpClient<boolean>(url, CpHttpMethod.PUT, data, option);
  }

  patch<T>(
    url: string,
    data: any,
    option?: any,
  ): Observable<HttpResponseModel<boolean>> {
    return this._httpClient<boolean>(url, CpHttpMethod.PATCH, data, option);
  }

  delete(url: string, options?: any): Observable<HttpResponseModel<boolean>> {
    return this._httpClient<boolean>(url, CpHttpMethod.DELETE, null, options);
  }

  _httpClient<T>(
    url: string,
    method: CpHttpMethod,
    data?: any,
    option?: any,
  ): Observable<HttpResponseModel<T>> {
    let _headers = {};
    if (option?.headers) _headers = { ...option.headers };
    const _options = {
      ...option,
      headers: {
        ..._headers,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      // eslint-disable-next-line @typescript-eslint/prefer-as-const
      observe: 'response' as 'response',
    };

    switch (method) {
      case CpHttpMethod.GET:
        return this._http.get<T>(url, _options).pipe(
          map(val => {
            return {
              success: true,
              status: (val as HttpResponse<T>).status,
              data: (val as HttpResponse<T>).body,
            } as HttpResponseModel<T>;
          }),
          catchError(errorResponse => {
            const err = <HttpErrorResponse>errorResponse;
            return this._errorHandler(err, true);
          }),
        );
      case CpHttpMethod.POST:
        return this._http
          .post<T>(url, data, { ..._options, observe: 'response' })
          .pipe(
            map(val => {
              return {
                success: true,
                status: (val as HttpResponse<T>).status,
                data: (val as HttpResponse<T>).body,
              } as HttpResponseModel<T>;
            }),
            catchError(errorResponse => {
              const err = <HttpErrorResponse>errorResponse;
              return this._errorHandler(err, true);
            }),
          );
      case CpHttpMethod.PUT:
        return this._http.put<T>(url, data, _options).pipe(
          map(val => {
            return {
              success: true,
              data: (val as HttpResponse<T>).body,
            } as HttpResponseModel<T>;
          }),
          catchError(errorResponse => {
            const err = <HttpErrorResponse>errorResponse;
            return this._errorHandler(err, true);
          }),
        );
      case CpHttpMethod.PATCH:
        return this._http.patch<T>(url, data, _options).pipe(
          map(val => {
            return {
              success: true,
              data: (val as HttpResponse<T>).body,
            } as HttpResponseModel<T>;
          }),
          catchError(errorResponse => {
            const err = <HttpErrorResponse>errorResponse;
            return this._errorHandler(err, true);
          }),
        );
      case CpHttpMethod.DELETE:
        return this._http.delete<T>(url, _options).pipe(
          map(val => {
            return {
              success: true,
              data: (val as HttpResponse<T>).body,
            } as HttpResponseModel<T>;
          }),
          catchError(errorResponse => {
            const err = <HttpErrorResponse>errorResponse;
            return this._errorHandler(err, true);
          }),
        );
    }
  }

  _errorHandler(error: HttpErrorResponse, showToast: boolean) {
    const err = new Error(error.message);
    if (showToast) {
      this._toastConfigService.setDefaultConfig();
      this._toastService.open(CustomToastComponent, {
        toastType: 'warn',
        header: this._translocoService.translate(
          'common.apiErrorToast.apiConnection.title',
        ),
        contents: [
          this._translocoService.translate(
            'common.apiErrorToast.apiConnection.body',
          ),
        ],
        disableCloseButton: false,
      });
    }
    return throwError(() => err);
  }
}
