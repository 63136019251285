import { NavBarTab } from '@pearsonvue/topaz-angular-ui';

export interface RoleNavBarTab extends NavBarTab {
  roleId?: number;
}

export interface HeaderConfig {
  tabs: RoleNavBarTab[];
}

export const DefaultTab: NavBarTab = {
  label: '',
  subViews: [
    {
      label: '',
    },
  ],
};
export const TestCandidateTab: RoleNavBarTab = {
  label: 'common.roles.testCandidate',
  roleId: 1,
  subViews: [
    {
      label: 'header.testCandidate.dashboard',
      active: false,
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?roleid=1',
      },
    },
    {
      label: 'header.testCandidate.transcript',
      active: false,
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=195&roleid=1',
      },
    },
    {
      label: 'header.testCandidate.pathway',
      active: false,
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=797&roleid=1',
      },
    },
    {
      label: 'header.testCandidate.onvue',
      active: false,
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=801&roleid=1',
      },
    },
  ],
};
export const ProctorTab: RoleNavBarTab = {
  label: 'common.roles.proctor',
  roleId: 3,
  subViews: [
    {
      label: 'header.proctor.session',
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=801&roleid=1',
      },
    },
  ],
};
export const OrgAdminTab: RoleNavBarTab = {
  label: 'common.roles.orgAdmin',
  roleId: 23,
  subViews: [
    {
      label: 'common.dashboard',
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=801&roleid=1',
      },
    },
    {
      label: 'header.orgAdmin.purchase',
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=801&roleid=1',
      },
    },
    {
      label: 'common.reports',
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=801&roleid=1',
      },
    },
    {
      label: 'header.orgAdmin.examDelivery',
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=801&roleid=1',
      },
    },
    {
      label: 'header.orgAdmin.orgProfile',
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=801&roleid=1',
      },
    },
    {
      label: 'header.orgAdmin.training',
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=801&roleid=1',
      },
    },
    {
      label: 'header.orgAdmin.examGroups',
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=801&roleid=1',
      },
    },
  ],
};
export const TeacherTab: RoleNavBarTab = {
  label: 'common.roles.teacher',
  roleId: 312,
  subViews: [
    {
      label: 'common.dashboard',
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=801&roleid=1',
      },
    },
    {
      label: 'header.orgAdmin.examGroups',
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=801&roleid=1',
      },
    },
    {
      label: 'common.reports',
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=801&roleid=1',
      },
    },
  ],
};
export const TCGroupAdminTab: RoleNavBarTab = {
  label: 'common.roles.tcGroupAdmin',
  roleId: 390,
  subViews: [
    {
      label: 'common.dashboard',
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=801&roleid=1',
      },
    },
    {
      label: 'common.reports',
      data: {
        category: 'legacy',
        description: '',
        path: '/portal/DesktopDefault.aspx?tabid=801&roleid=1',
      },
    },
  ],
};
