import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { UnsavedPopupComponent } from '@src/app/components/unsaved/unsavedPopup.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnsavedGuardService {
  confirmation: boolean = false;
  navigateTo: boolean = false;
  showConfirmModal: boolean = false;
  unsavedForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _dialog: MatDialog) {}

  canDeactivate(
    component: UnsavedPopupComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.unsavedForm.asObservable().subscribe(result => {
      this.showConfirmModal = result;
    });

    if (this.showConfirmModal) {
      return this.openConfirmPopup();
    } else {
      return true;
    }
  }

  openConfirmPopup() {
    const dialogRef = this._dialog.open(UnsavedPopupComponent, {
      height: '15rem',
      width: '35rem',
    });

    return dialogRef.afterClosed();
  }

  changedUrl(isNavigate: boolean) {
    this.confirmation = true;
    return isNavigate;
  }
}
