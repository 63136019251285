import { Inject, Injectable } from '@angular/core';
import { LoaderService } from '@pearsonvue/topaz-angular-ui';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { APP_CONFIG, Config } from '@src/app/config/config';
import { CpHttpClientService } from '@src/libs/data-access/src/lib/services/cp-http-client.service';
import { DefaultIESModel, IESModel, IESPutModel } from '../models/ies-model';
import {
  DefaultRegistrationModel,
  RegistrationModel,
} from '../../../models/registration.model';

@Injectable({
  providedIn: 'root',
})
export class IesService {
  baseUrl: string = `${this.config.microServiceURI}/usermanagement`;
  IESUserInfo: BehaviorSubject<RegistrationModel> =
    new BehaviorSubject<RegistrationModel>(DefaultRegistrationModel);
  IESUserObject: BehaviorSubject<IESModel> = new BehaviorSubject<IESModel>(
    DefaultIESModel,
  );

  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private _http: CpHttpClientService,
    private loaderService: LoaderService,
  ) {}

  sendSSOUser(ssoToken: string): Observable<any> {
    return this._http.post<boolean>(`${this.baseUrl}/SSOuser`, ssoToken).pipe(
      map(({ success, data }) => {
        if (success) {
          return data;
        } else {
          return null;
        }
      }),
    );
  }

  checkSSSOUser(ssoToken: string): Observable<boolean> {
    return this._http.post<boolean>(`${this.baseUrl}/SSOuser`, ssoToken).pipe(
      map(({ success, data }) => {
        return success && data;
      }),
    );
  }

  fetchIESUser(code: string): Observable<IESModel> {
    setTimeout(() => {
      this.loaderService.showLoader();
    }, 10);
    let body = {
      code: code,
    };
    return this._http
      .post<IESModel>(
        `${this.config.microServiceURI}/authentication/Authentication/sso/IES`,
        body,
      )
      .pipe(
        map(({ success, data }) => {
          this.loaderService.hideLoader();
          if (success) {
            this.IESUserObject.next(data);
            return data;
          } else {
            this.IESUserObject.next(DefaultIESModel);
            return DefaultIESModel;
          }
        }),
      );
  }

  updateSSOUser(user: any, userId: string): Observable<any> {
    return this._http
      .put<IESPutModel>(`${this.baseUrl}/ssouser?userId=${userId}`, user)
      .pipe(
        map(({ success, data }) => {
          if (success) {
            return data;
          }
          return null;
        }),
      );
  }

  resetSSOUserInfo() {
    this.IESUserInfo.next(DefaultRegistrationModel);
  }
}
