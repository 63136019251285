import { createSelector } from '@ngrx/store';

import { AppState } from '../core.state';
import { ProfileState } from './profile.state';

export const selectProfileState = (state: AppState) => state.profile;

export const selectProfileLoading = createSelector(
  selectProfileState,
  (state: ProfileState) => state.loading,
);
export const selectProfileError = createSelector(
  selectProfileState,
  (state: ProfileState) => state.error,
);
export const selectPreferedLanguageId = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profile?.preferredlanguageId || 5,
);
export const selectRoles = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profile?.roles || [],
);

export const selectUserDetails = createSelector(
  selectProfileState,
  (state: ProfileState) => {
    return {
      firstName: state.profile?.firstName || '',
      lastName: state.profile?.lastName || '',
      lastNameFirst: state.profile?.displayLastNameFirst || false,
    };
  },
);

export const selectProfileBasicInfo = createSelector(
  selectProfileState,
  (state: ProfileState) => {
    return {
      firstName: state.profile?.firstName,
      middleName: state.profile?.middleName,
      lastName: state.profile?.lastName,
      alternateName: state.profile?.alternateName,
      isNameMatchedWithGovId: state.profile?.isNameMatchedWithGovId,
      displayLastNameFirst: state.profile?.displayLastNameFirst,
      birthDate: state.profile?.birthDate,
      preferredlanguageId: state.profile?.preferredlanguageId,
      studentId: state.profile?.studentId,
      country: state.profile?.country,
    };
  },
);

export const selectProfileAccommodation = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profile?.accommodations || [] 
);

export const selectLoginCount = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profile?.loginCount || 0 
);
