import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';

import { LanguagePickerService } from '@src/app/services/common/language-picker.service';
import { APP_CONFIG, Config } from '@src/app/config/config';

@Component({
  selector: 'app-missing-user-info',
  templateUrl: './missing-user-info.component.html',
  styleUrls: ['./missing-user-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MissingUserInfoComponent {
  faCircleInfo = faCircleInfo;
  isIes: boolean = this.config.iesFlag;

  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private router: Router,
    public langPickerService: LanguagePickerService,
  ) {}

  ngOnInit() {

    this.router.events.subscribe(() => {
      let selectedLangCode = this.langPickerService.getLanguageSelectedByUser();
      if (selectedLangCode) {
        this.langPickerService.setLanguageByLangCode(selectedLangCode);
      }
    });
  }
}
