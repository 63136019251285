import { DefaultProfile, Profile } from '../../models';

export interface ProfileState {
  loading: boolean;
  profile: Profile;
  error: string;
}

export const profileInitialState: ProfileState = {
  loading: false,
  profile: DefaultProfile,
  error: '',
};
