<div *transloco="let t">
  <topaz-footer>
    <topaz-footer-icons>
      <div class="social-media-icons">
          <a href="http://www.facebook.com/certiport" target="_blank">
            <topaz-social-icon ariaLabel="Facebook" icon="facebook" link=""></topaz-social-icon>
          </a>
          <a href="http://www.linkedin.com/company/18021?trk=tyah" target="_blank">
           <topaz-social-icon ariaLabel="LinkedIn" icon="linkedin"></topaz-social-icon></a>
          <a href="http://www.twitter.com/certiport" target="_blank">
            <topaz-social-icon ariaLabel="Twitter" icon="twitter"></topaz-social-icon>
          </a>
          <a href="http://www.youtube.com/user/certiportcomms" target="_blank">
            <topaz-social-icon ariaLabel="Youtube" icon="youtube"></topaz-social-icon></a>
      </div>
    </topaz-footer-icons>
    <topaz-footer-links>
      <div class="links">
        <a tabindex="0" class="mr-1" (click)="openLink('Terms')">{{
          t('footer.terms')
        }}</a>
        |
        <a class="ml-1 mr-1" tabindex="0" (click)="openLink('Privacy')">{{
          t('footer.privacy')
        }}</a>
        |
        <a
          tabindex="0"
          class="ml-1 mr-1 language-icon-container"
          (click)="openLanguageSelectorDialog()"
        >
          <fa-icon class="language-icon" [icon]="faEarthAmericas"></fa-icon>
          <span>{{ getLanguageLocaleAndName() }}</span>
        </a>
        |
        <a
          tabindex="0"
          class="ml-1 mr-1 optanon-toggle-display"
          onclick="Optanon.TriggerGoogleAnalyticsEvent('onetrust cookie consent', 'banner open preferences');"
        >
          <fa-icon [icon]="faCookieBite"></fa-icon>
          {{ t('footer.cookiePreferences') }}
        </a>
      </div>
    </topaz-footer-links>
    <topaz-footer-copyright>
      <p>
        <span [style.display]="'inline-block'"
          >{{ t('footer.copyright') }}
        </span>
      </p>
    </topaz-footer-copyright>
  </topaz-footer>
</div>
