export enum EndpointType {
    Basic = 1,
    Button = 2
}

export enum EndpointBodyType {
    String = 1,
    List = 2
}

export enum LineElementType {
    String = 1,
    Link = 2,
    EmbeddedLink = 3
}