import {
  AbstractControl,
  ValidationErrors,
  AsyncValidatorFn,
} from '@angular/forms';
import {
  BehaviorSubject,
  debounceTime,
  finalize,
  first,
  map,
  Observable,
  switchMap,
  tap,
} from 'rxjs';

import { RegistrationService } from '@src/app/services/registration.service';

export enum EmailExistsErrors {
  EmailExists = 'emailExists',
}

export class EmailExistsValidator {
  static createValidator(
    registrationService: RegistrationService,
    showSpinner: BehaviorSubject<boolean>,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      showSpinner.next(true);
      return control.valueChanges.pipe(
        debounceTime(400),
        switchMap(value => registrationService.validateUserName(control.value)),
        map(
          result =>
            (result.data.data.isValid
              ? null
              : { emailExists: true }) as unknown as ValidationErrors | null,
        ),
        finalize(() => showSpinner.next(false)),
        first(),
      );
    };
  }
}
