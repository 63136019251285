import { createReducer, on } from '@ngrx/store';

import * as SessionActions from './session.actions';
import { sessionInitialState } from './session.state';
import { DefaultAuthenticationOutput } from '@certiport/login-library';

export const sessionReducer = createReducer(
  sessionInitialState,
  on(SessionActions.update, state => ({ ...state, loading: true })),
  on(SessionActions.updateSuccess, (state, { session }) => ({
    ...state,
    session,
    loading: false,
    error: {},
  })),
  on(SessionActions.updateFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),

  on(SessionActions.clear, state => ({
    ...state,
    profile: DefaultAuthenticationOutput,
    error: {},
  })),

  on(SessionActions.clearFailure, (state, { error }) => ({
    ...state,
    profile: DefaultAuthenticationOutput,
    error: error,
  })),
);
