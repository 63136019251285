import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function customEmailValidator(control: AbstractControl) {

    const value = control.value;
    const regExp  = /^(?=.{7,128}$)(?:[A-Za-z0-9-'+_]+\.)*(?:[A-Za-z0-9'+_-])+@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+(?:[a-zA-Z]{2,63})$/i;
    const validEmail = new RegExp(regExp, 'i').test(value)
    if(value){
      return validEmail ? null : { emailvalid: true };
    }
    return null
    
}
