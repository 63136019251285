import { Component, ContentChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Step2Component } from '@src/app/modules/signup/wizard/step-2/step-2.component'

@Component({
  selector: 'lib-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss'],
})
export class ModalWrapperComponent {
  @ContentChild(Step2Component) step2Component!: Step2Component

  constructor(private _dialog: MatDialog) {}

  cancel(): void {
    this._dialog.closeAll()
  }

  submit(): void {
    this.step2Component.submit()
  }
}
