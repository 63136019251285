<mat-form-field appearance="outline" *transloco="let t">
    <mat-label>
      {{label}}
    </mat-label>
    <input
      matInput
      type="text"
      [required]="isRequired"
      placeholder="{{label}}"
      [formControl]="control"
      data-id="studentId-matInput"
    />
    <mat-error *ngIf="hasError('studentId')">
      {{ getStudentIdValidationErrorMsg() }}
    </mat-error>
  </mat-form-field>
