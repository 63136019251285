import { AbstractControl } from "@angular/forms";

function ValidateMinLength(control: AbstractControl) {
  if (!(control?.value?.length >= 8)) {
    return { minLength: true };
  }
  return null;
}
function ValidateSpecialChar(control: AbstractControl) {
  if (!control?.value?.match('[!#$%^&*()+=\\[{\\]};:<>|/?,-.@_]')) {
    return { hasSpecialCharacter: true };
  }
  return null;
}
function ValidateUpper(control: AbstractControl) {
  if (!control?.value?.match('[A-Z]')) {
    return { hasUpperCase: true };
  }
  return null;
}
function ValidateNumber(control: AbstractControl) {
  if (!control?.value?.match('[0-9]')) {
    return { hasNumber: true };
  }
  return null;
}

export class PasswordValidators {
  public static Validators = [
    ValidateMinLength, ValidateSpecialChar, ValidateUpper, ValidateNumber
  ];

  public static PasswordErrors = [
    'minLength',
    'hasSpecialCharacter',
    'hasUpperCase',
    'hasNumber'
  ]
}