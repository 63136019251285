import { Component, forwardRef, Injector, Inject, OnInit, Input } from '@angular/core'
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { Subject, takeUntil, startWith, distinctUntilChanged, tap } from 'rxjs';

@Component({
  selector: 'lib-alternate-name',
  templateUrl: './alternate-name.component.html',
  styleUrls: ['./alternate-name.css'],
   providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AlternateNameComponent),
        multi: true,
      },
    ],
})
export class AlternateNameComponent implements ControlValueAccessor, OnInit {
  @Input() label = 'FName MN LName';
  @Input() isJapan = false;
  control!: FormControl;
  isRequired = false;

  private _destroy$ = new Subject<void>();
  private _onTouched!: () => any;



  constructor( @Inject(Injector) private injector: Injector,
  private translocoService: TranslocoService) {}

  ngOnInit(){
    this.setFormControl();
    this.isRequired = this.control?.hasValidator(Validators.required) ?? false;
  }


    setFormControl() {
      try {
        const formControl = this.injector.get(NgControl);

        switch (formControl.constructor) {
          case FormControlName:
            this.control = this.injector
              .get(FormGroupDirective)
              .getControl(formControl as FormControlName);
            break;
          default:
            this.control = (formControl as FormControlDirective)
              .form as FormControl;
            break;
        }
      } catch (err) {
        this.control = new FormControl();
      }
    }


  writeValue(value: any): void {
    if (this.control && this.control.value != value)
      this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (val: any | null) => any): void {
    this.control?.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        startWith(this.control.value),
        distinctUntilChanged(),
        tap(val => fn(val)),
      )
      .subscribe(() => this.control?.markAsUntouched());
  }
registerOnTouched(fn: () => any): void {
  this._onTouched = fn;
}

  hasError(field: string): boolean {
    return !!this.control?.errors && !!this.control?.touched;
  }

  getAlternateNameValidationMessage(){
    const getAlternateName = this.control;
    if(getAlternateName?.hasError("hasSpecialCharacter")){
      return this.translocoService.translate('signup.error.specialChars_alternateName');
    }
    return '';
  }


}

