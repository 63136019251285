<div *transloco="let t">
  <div
    mat-dialog-content
    class="text-justify fs-12 overflow-auto mat-dialog-content"
  >
    <p [innerHTML]="t('signup.userAgreement_1') | safeHtml"></p>

    <p>{{ t('signup.userAgreement_2') }}</p>

    <p>{{ t('signup.userAgreement_3') }}</p>

    <p>{{ t('signup.userAgreement_4') }}</p>

    <p>{{ t('signup.userAgreement_5') }}</p>
  </div>
</div>
