import { Component } from '@angular/core';

@Component({
  selector: 'app-header-wrapper',
  templateUrl: './header-wrapper.component.html',
  styleUrl: './header-wrapper.component.scss',
})
export class HeaderWrapperComponent {
  constructor() {}
}
