import { Component, Input, OnInit } from '@angular/core';

import { Endpoint } from './models/basic-endpoint';
import { EndpointBodyType, EndpointType, LineElementType } from './models/endpointType';
import { Params } from '@angular/router';

@Component({
  selector: 'lib-endpoint-handler',
  templateUrl: './endpoint-handler.component.html',
  styleUrls: ['./endpoint-handler.component.scss'],
})
export class EndpointHandlerComponent implements OnInit {
  @Input() endpoint: Endpoint = {
    id: '',
    type: EndpointType.Basic,
    title: '',
    body: [],
  };
  @Input() queryParams: Params = {};

  endpointType = EndpointType;
  endpointBodyType = EndpointBodyType;
  lineElementType = LineElementType;

  routerLink = this.endpoint.routerLink || ['/'];
  buttonLabel = this.endpoint.buttonLabel || 'Dashboard';

  ngOnInit(): void {
    this.routerLink = this.endpoint.routerLink || ['/'];
    this.buttonLabel = this.endpoint.buttonLabel || 'Dashboard';
  }
}
