import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { AppInsightsService } from './app-insights.service';

export enum AuditEventType {
  PageView = 'PageView',
  Click = 'Click',
}

@Injectable({
  providedIn: 'root',
})
export class AuditEventService {
  constructor(
    private appInsightsService: AppInsightsService,
    private gtmService: GoogleTagManagerService,
  ) {}

  gtmEvent(event: string, pageName: string, source: string) {
    const gtmTag = {
      event: event,
      pageName: pageName,
      source: source,
    };
    this.gtmService.pushTag(gtmTag);
  }

  appInsightEvent(appInsightKey: string, eventType: AuditEventType) {
    switch (eventType) {
      case AuditEventType.PageView:
        this.appInsightsService.trackPageView(appInsightKey);
        break;
      case AuditEventType.Click:
      default:
        break;
    }
  }
}
