import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-wide-layout',
  templateUrl: './wide-layout.component.html',
  styleUrls: ['./wide-layout.component.scss'],
})
export class WideLayoutComponent {
  @Input() customClass?= "";
}
