<lib-card *transloco="let t">
  <div header>
    <header>
      <h1 topazTitle="1" class="text-center" data-id="under13-endpoint-title">
        {{ t(endpoint.title) }}
      </h1>
    </header>
  </div>

  <ng-content select="[pre]"></ng-content>

  <div *ngFor="let bodyElement of endpoint.body">
    <p
      *ngIf="bodyElement.type === endpointBodyType.String"
      class="{{ bodyElement?.class }}"
    >
      <!--EndpointBodyType.String-->
      <span *ngFor="let lineElement of bodyElement.value, let i = index">
        <!--LineElement.String-->
        <span *ngIf="lineElement.type === lineElementType.String" data-id="under13-endpoint-{{i}}">
          {{ t(lineElement.value) }}
        </span>
        <!--LineElement.Link-->
        <a
          *ngIf="lineElement.type === lineElementType.Link"
          target="_blank"
          [href]="lineElement.href"
        >
          {{ t(lineElement.value) }}
        </a>
        <!-- LineElement.EmbeddedLink-->
        <span
          *ngIf="lineElement.type === lineElementType.EmbeddedLink"
          [innerHtml]="t(lineElement.value) | safeHtml"
        ></span>
      </span>
    </p>

    <ul *ngIf="bodyElement.type === endpointBodyType.List">
      <!--EndpointBodyType.List-->
      <li *ngFor="let listElement of bodyElement.list, let i = index">
        <span *ngFor="let lineElement of listElement.value" >
          <!--LineElement.String-->
          <span *ngIf="lineElement.type === lineElementType.String" data-id=`under13-endpoint-line-{{i}}`>
            {{ t(lineElement.value) }}
          </span>
          <!--LineElement.Link-->
          <a
            *ngIf="lineElement.type === lineElementType.Link"
            target="_blank"
            [href]="lineElement.href"
          >
            {{ t(lineElement.value) }}
          </a>
        </span>
      </li>
    </ul>
  </div>

  <ng-content select="[post]"></ng-content>

  <div
    *ngIf="endpoint.type === endpointType.Button"
    mat-dialog-actions
    align="center"
  >
    <button
      mat-flat-button
      color="primary"
      [routerLink]="routerLink"
      [queryParams]="queryParams"
    >
      {{ t(buttonLabel) }}
    </button>
  </div>
</lib-card>
