import { Component, Input } from '@angular/core'
import { TopazBannerType } from '@pearsonvue/topaz-angular-ui';

@Component({
  selector: 'lib-custom-banner',
  templateUrl: './custom-banner.component.html',
  styleUrls: ['./custom-banner.component.scss'],
})
export class CustomBannerComponent {
  @Input() bannerType = 'warn' as TopazBannerType;
}
