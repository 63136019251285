import { Component, OnInit } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import {
  NavBarTab,
  TabSubView,
  TopazOverlayRef,
} from '@pearsonvue/topaz-angular-ui';

import { HeaderConfig, RoleNavBarTab } from '../../models/headerConfig';

@Component({
  selector: 'lib-mobile-side-menu',
  templateUrl: './mobile-side-menu.component.html',
  styleUrl: './mobile-side-menu.component.scss',
})
export class MobileSideMenuComponent implements OnInit {
  headerConfig!: HeaderConfig;
  showAllRoles = false;
  activeLink = '';

  toggle: boolean[] = [];

  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  constructor(private ref: TopazOverlayRef) {}

  ngOnInit(): void {
    this.headerConfig = this.ref.data.headerConfig;
    this.showAllRoles = this.ref.data.showAllRoles;
    this.activeLink = this.ref.data.activeLink;
  }

  close(tab: RoleNavBarTab, subTab: TabSubView): void {
    this.ref.close({ activeTab: tab, activeSubTab: subTab });
  }

  allRoleRedirect(): void {
    alert('All Role Redirect Clicked');
  }
  allRoleKeydown($event: KeyboardEvent): void {
    if ($event.code === 'Space' || $event.key === 'Enter') {
      this.allRoleRedirect();
    }
  }
  handleLogoutClick(): void {
    this.ref.close({ logout: true });
  }
}
