<mat-card
  appearance="outlined"
  aria-labelledby="long-card-title"
  class="lib-card {{ customClass }}"
>
  <header>
    <mat-card-title>
      <ng-content select="title"></ng-content>
    </mat-card-title>
  </header>
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
