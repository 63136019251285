<div
  *transloco="let t"
  aria-modal="true"
  role="dialog"
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <div class="topaz-dialog-header pb-1">
    <h5 topazTitle="3" id="modal-title" data-id="userAgreement-title">
      {{ t('common.userAgreement') }}
    </h5>
    <button
      mat-icon-button
      mat-dialog-close
      aria-label="close dialog"
      (click)="closeUserAgreementModal()"
      data-id="modal-close-icon"
    >
      <fa-icon [icon]="faXmark" size="lg" matIconPrefix></fa-icon>
    </button>
  </div>
  <lib-tos></lib-tos>
  <div mat-dialog-actions align="end">
    <button
      mat-flat-button
      mat-dialog-close
      color="primary"
      (click)="closeUserAgreementModal()"
      data-id="modal-close-bttn"
    >
      {{ t('common.close') }}
    </button>
  </div>
</div>
