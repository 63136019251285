import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';

export interface ColumnDef {
  fieldId: string,
  title: string,
}

export interface TableConfig {
  isFilterable: boolean,
  isPaginated: boolean,
  isSearchable: boolean,
  isSortable: boolean,
}

@Component({
  selector: 'lib-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements AfterViewInit {
  @Input() caption = '';
  @Input() columnsDef!: ColumnDef[];
  @Input() displayedColumns!: string[];
  @Input() $data?: Observable<T[]>;
  @Input() data?: T[];

  dataSource: MatTableDataSource<T> = new MatTableDataSource<T>();

  constructor(private cd: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    if (this.data) {
      this.dataSource.data = this.data;
    } else if (this.$data) {
      this.$data.subscribe(result => {
        this.dataSource.data = result;
      });
    } else {
      this.dataSource.data = [];
    }
    this.cd.detectChanges();
  }
}
