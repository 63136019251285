import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TranslocoService } from '@jsverse/transloco';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { Subscription } from 'rxjs';

import { CacheService } from '@src/app/services/common/cache.service';
import { getAge } from 'src/libs/utility/src/lib/validators/age-validators';
import { RegistrationFormService } from '../../services/registration-form.service';
import { APP_CONFIG, Config } from '@src/app/config/config';
import { Country } from '@src/app/models/countries';
import { CountryService } from '@src/app/services/common/country.service';

@Component({
  selector: 'app-step-3',
  templateUrl: './step-3.component.html',
  styleUrls: ['./step-3.component.scss'],
})
export class Step3Component implements AfterViewInit, OnDestroy {
  @Input() dobFormGroup!: FormGroup;
  @Output() previous = new EventEmitter();
  @Output() submit = new EventEmitter();

  todayDate = new Date();
  showStudentId = false;
  isItaly = false;
  italy_studentId = 'Codice Fiscale';

  subscriptions: Subscription[] = [];

  siteKey: string = this.config.siteKey;
  isCapthchaToken: boolean = false;
  captchaResolved: boolean = false;
  formSubmit: boolean = false;

  events: string[] = [];
  automationParam: string = '';
  selectedCountry: Country | null = null;

  faCalendar = faCalendar;
  faCircleInfo = faCircleInfo;

  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private translocoService: TranslocoService,
    private registrationFormService: RegistrationFormService,
    private activatedRoute: ActivatedRoute,
    private cache: CacheService,
    private countryService: CountryService,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.automationParam = params['automation'];
    });
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.registrationFormService.studentIDValidation
        .asObservable()
        .subscribe(result => (this.showStudentId = result)),
      this.registrationFormService.isItaly
        .asObservable()
        .subscribe(result => (this.isItaly = result)),
      this.registrationFormService.selectedCountry
        .asObservable()
        .subscribe(result => (this.selectedCountry = result)),
    );
    this.getCacheDate();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  get showCaptcha(): boolean {
    let dob = this.dobFormGroup.get('birthDate')?.value;
    if (dob == '' || dob == null) {
      //do not display if dob is not selected
      return false;
    } else if (!this.selectedCountry?.isParentApprovalNeededForMinor) {
      // display if country does not check for under13
      return true;
    } else if (dob && getAge(new Date(dob)) > 13) {
      if (this.automationParam === 'true' && !this.config.production) {
        // do not display if automation is true and not in production
        return false;
      } else {
        // display if age is greater than 13
        return true;
      }
    } else {
      // do not display if age is less than 13
      return false;
    }
  }

  hasError(field: string): boolean {
    return (
      !!this.dobFormGroup.get(field)?.errors &&
      !!this.dobFormGroup.get(field)?.touched
    );
  }

  getValidationText(field: string): string {
    if (this.dobFormGroup.get(field)?.hasError('pattern')) {
      return !this.isItaly
        ? this.translocoService.translate('signup.error.validStudentID')
        : this.translocoService.translate('signup.error.validStudentID');
    }
    if (this.dobFormGroup.get(field)?.hasError('maxDate')) {
      return this.translocoService.translate('signup.error.maxDate');
    }
    if (this.dobFormGroup.get(field)?.hasError('matDatepickerParse')) {
      return this.translocoService.translate(`signup.error.dateFormat`);
    }
    return this.dobFormGroup.get(field)?.hasError('required')
      ? this.translocoService.translate(`signup.error.required_${field}`)
      : '';
  }

  getStudentIdValidationErrorMsg() {
    const getStudentId = this.dobFormGroup.get('studentID');
    if (getStudentId?.hasError('required')) {
      return !this.isItaly
        ? this.translocoService.translate('signup.error.required_studentID')
        : this.translocoService.translate('signup.error.required_codiceFicale');
    }
    if (getStudentId?.hasError('hasSpecialCharacter')) {
      return this.translocoService.translate(
        'signup.error.specialChars_codiceFicale',
      );
    }
    if (getStudentId?.hasError('minLength')) {
      return this.translocoService.translate(
        'signup.error.minLength_codiceFicale',
      );
    }
    if (getStudentId?.hasError('maxLength')) {
      return this.translocoService.translate(
        'signup.error.maxLength_codiceFicale',
      );
    }
    return '';
  }

  getCacheDate(): void {
    let signupFromData = this.cache.get('signup', 'dob');
    let loginFromData = this.cache.get('signup', 'login');

    if (signupFromData) {
      let chacheDate = signupFromData?.birthDate;
      this.registrationFormService.selectedCountry.next(
        this.countryService.getCountryByDesc(loginFromData?.country) || null,
      );
      this.getDate('cache', chacheDate);
    }
  }

  markAsTouched(): void {
    this.dobFormGroup.get('birthDate')?.markAsTouched();
    this.dobFormGroup.get('studentID')?.markAsTouched();
  }

  back(): void {
    this.markAsTouched();
  }

  onSubmitStep3(): void {
    this.formSubmit = true;
    if (
      !this.captchaResolved &&
      !this.dobFormGroup.get('reCaptchaToken')?.value
    ) {
      this.captchaResolved = true;
    }

    if (this.dobFormGroup.invalid) {
      this.markAsTouched();
      return;
    }
    this.submit.emit();
  }

  checkCaptcha(captchaResponse: any) {
    this.captchaResolved =
      captchaResponse && captchaResponse.length > 0 ? false : true;
  }

  getDate(type: string, event: MatDatepickerInputEvent<Date>) {
    let dob = this.dobFormGroup.get('birthDate')?.value;
    if (type == 'cache' && (dob == '' || dob == null)) {
      dob = event;
    }

    this.dobFormGroup.controls['reCaptchaToken'].clearValidators();

    if (this.automationParam === 'true' && !this.config.production) {
      this.dobFormGroup.controls['reCaptchaToken'].setValidators(null);
      this.dobFormGroup.controls['reCaptchaToken'].setErrors(null);
      this.dobFormGroup.updateValueAndValidity();
      return;
    }

    if (!this.selectedCountry?.isParentApprovalNeededForMinor || (dob && getAge(new Date(dob)) > 13)) {
      this.dobFormGroup.controls['reCaptchaToken'].setValidators([
        Validators.required,
      ]);
    } else {
      this.dobFormGroup.controls['reCaptchaToken'].setValidators(null);
      this.dobFormGroup.controls['reCaptchaToken'].setErrors(null);
    }
    this.dobFormGroup.updateValueAndValidity();
  }
}
