import { Directive, HostListener, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ApplicationActions, AppState } from "../core/store";

@Directive({selector: '[mouseDir]'})
export class MouseDirective implements OnInit{

    constructor(private store: Store<AppState>) { }
    ngOnInit(): void {
    }
    @HostListener('click') onClick(){
        this.store.dispatch(ApplicationActions.resetPreLogout())
        this.store.dispatch(ApplicationActions.resetTimer())
        this.store.dispatch(ApplicationActions.refreshToken())

    }

    @HostListener('dblclick') ondblClick(){
        this.store.dispatch(ApplicationActions.resetPreLogout())
        this.store.dispatch(ApplicationActions.resetTimer())
        this.store.dispatch(ApplicationActions.refreshToken())

    }

    // use this only when event occurs or necessary.
    // @HostListener('mouseover') onMouseOver(){
    //     this.store.dispatch(ApplicationActions.resetPreLogout())
    //     this.store.dispatch(ApplicationActions.resetTimer())
    //     this.store.dispatch(ApplicationActions.refreshToken())

    // }
    @HostListener('window:keydown', ['$event'])onKeyDown() {
        this.store.dispatch(ApplicationActions.resetPreLogout())
        this.store.dispatch(ApplicationActions.resetTimer())
        this.store.dispatch(ApplicationActions.refreshToken())
    }
  
}