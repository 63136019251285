import { Inject, Injectable } from '@angular/core'
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http'
import { TranslocoService } from '@jsverse/transloco'
import {
  ToastService,
  TopazDefaultToastComponent,
} from '@pearsonvue/topaz-angular-ui'
import { Observable, catchError, map, of, throwError } from 'rxjs'

import { CpHttpClientService } from '@src/libs/data-access/src/lib/services/cp-http-client.service'
import { APP_CONFIG, Config } from '@src/app/config/config'

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private http: HttpClient,
    private _http: CpHttpClientService,
    private toastService: ToastService,
    private translocoService: TranslocoService,
  ) {}

  resolveToken(token: string, action: string): Observable<any> {
    const _options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    }
    if(this.config.iesFlag){
      return of({ success: true })
    }
    return this._http
      .get(
        `${this.config.microServiceURI}/usermanagement/token?token=${token}&action=${action}`,
      )
      .pipe(
        map(() => {
          return { success: true }
        }),
      )
  }

  getUserNameForToken(token: string, action: string): Observable<any> {
    return this._http
      .get(
        `${this.config.microServiceURI}/usermanagement/token/username?token=${token}&action=${action}`,
        { responseType: 'text' },
      )
      .pipe(
        map((val: any) => {
          return val
        }),
      )
  }
}
