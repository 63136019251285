<lib-full-width-layout *transloco="let t">
  <lib-wide-layout>
    <lib-card [customClass]="'p-0'">
      <form [formGroup]="formGroup">
        <mat-stepper
          #stepper
          class="br8"
          [linear]="true"
          [orientation]="(stepperOrientation | async)!"
        >
          <ng-template matStepperIcon="edit">
            <span data-id="stepper_{{stepper.selectedIndex + 1}}">{{stepper.selectedIndex + 1}}</span> 
          </ng-template>
          <mat-step
            role="tabpanel"
            #loginStep
            [optional]="false"
            [stepControl]="getForm('login')"
          >
            <ng-template matStepLabel>
              <span data-id="s1-join">{{ t('common.join') }}</span>
            </ng-template>
            <app-step-1
              [loginFormGroup]="getForm('login')"
              [showImage]="showStep1Image"
              (next)="onNext()"
            ></app-step-1>
          </mat-step>
          <mat-step
            role="tabpanel"
            #identityStep
            [optional]="false"
            [stepControl]="formGroup!.get('identity')!"
          >
            <ng-template matStepLabel>
              <span data-id="s2-identitytitle">{{t('signup.identityTitle')}}</span>
            </ng-template>
            <lib-step-wrapper
              [step]="'identity'"
              (previous)="onPrevious()"
              (next)="onNext()"
            >
              <app-step-2
                (next)="onNext()"
                [identityFormGroup]="getForm('identity')"
              ></app-step-2>
            </lib-step-wrapper>
          </mat-step>
          <mat-step
            #dobStep
            role="tabpanel"
            [optional]="false"
            [stepControl]="getForm('dob')"
          >
            <ng-template matStepLabel >
              <span data-id="s3-dobtitle">{{ t('signup.dobTitle') }}</span></ng-template>
            <app-step-3
              [dobFormGroup]="getForm('dob')"
              (previous)="onPrevious()"
              (submit)="onSubmit()"
            ></app-step-3>
          </mat-step>
        </mat-stepper>
      </form>
    </lib-card>
  </lib-wide-layout>
</lib-full-width-layout>
