import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { faCircle, faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { take } from 'rxjs';

import { RegistrationFormService } from '../../signup/services/registration-form.service';

import { SsoService } from '../services/sso.service';
import { SSOPutModel, DefaultPutSSOModel } from '../models/sso-model';

@Component({
  selector: 'app-gov-studentid-popup-wrapper',
  templateUrl: './gov-studentid-popup-wrapper.component.html',
  styleUrls: ['./gov-studentid-popup-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GovStudentidPopupWrapperComponent {
  formGroup: FormGroup;
  ssoUserInfo: SSOPutModel = DefaultPutSSOModel;

  faCircle = faCircle;
  faXmark = faXmark;
  faCircleInfo = faCircleInfo

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private registrationFormService: RegistrationFormService,
    private ssoService: SsoService,
    public dialogRef: MatDialogRef<GovStudentidPopupWrapperComponent>,
  ) {
    this.formGroup = registrationFormService.createForm();
    this.formGroup.get('identity')?.patchValue(data);
  }

  getForm(key: string): FormGroup {
    return this.formGroup.get(key)! as FormGroup;
  }

  onNext($event: any): void {
    //this.ssoService.ssoUserInfo.next(this.formGroup.get('identity')?.value)
    this.dialogRef.close({
      event: true,
      data: this.formGroup.get('identity')?.value,
    });
  }

  closePopup() {
    this.dialogRef.close();
  }
}
