import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { Step2Component } from '../../../../../../app/modules/signup/wizard/step-2/step-2.component'
@Component({
  selector: 'lib-step-wrapper',
  templateUrl: './step-wrapper.component.html',
  styleUrls: ['./step-wrapper.component.scss'],
})
export class StepWrapperComponent {
  @Input() step!: string
  @Output() next = new EventEmitter()
  @Output() previous = new EventEmitter<string>()

  @ContentChild(Step2Component) step2Component!: Step2Component

  back(): void {
    this.previous.emit()
  }

  submit(): void {
    this.step2Component.submit()
  }
}
