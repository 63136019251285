<div class="topaz-table-container" *transloco="let t">
  <table #table cdk-table [dataSource]="dataSource" class="topaz-table">
    <caption>
      {{
        t(caption)
      }}
    </caption>

    <ng-container
      *ngFor="let cloumn of columnsDef"
      [cdkColumnDef]="cloumn.fieldId"
    >
      <th scope="col" cdk-header-cell *cdkHeaderCellDef class="text-break">
        {{ t(cloumn.title) }}
      </th>
      <th scope="row" cdk-cell *cdkCellDef="let element" class="text-break">
        <span class="topaz-table-mobile-header">{{ t(cloumn.title) }}</span>
        {{ element[cloumn.fieldId] }}
      </th>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
