import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, take } from 'rxjs';

import { ProfileService } from '@src/app/services/profile.service';

import * as ProfileActions from './profile.actions';
import { selectSession } from '../session/session.selector';
import { AppState } from '../core.state';
import { Store } from '@ngrx/store';

@Injectable()
export class ProfileEffects {
  profileFetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.fetch),
      mergeMap(() =>
        this.store.select(selectSession).pipe(
          take(1),
          switchMap(auth => {
            if (auth?.UserId) {
              return this.profileService.fetch().pipe(
                map(profile => ProfileActions.fetchSuccess({ profile })),
                catchError(error =>
                  of(ProfileActions.fetchFailure({ error: error.message })),
                ),
              );
            } else {
              // Handle the case where auth?.UserId is not present
              return of(
                ProfileActions.fetchFailure({
                  error: 'User not authenticated',
                }),
              );
            }
          }),
        ),
      ),
    ),
  );

  profileUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.update),
      mergeMap(action =>
        this.profileService.update(action.patches).pipe(
          map(profile => ProfileActions.fetchSuccess({ profile })),
          catchError(error =>
            of(ProfileActions.fetchFailure({ error: error.message })),
          ),
        ),
      ),
    ),
  );

  profileClear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.clear),
      map(() => ProfileActions.clearSuccess()),
      catchError(error =>
        of(ProfileActions.clearFailure({ error: error.message })),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
    protected store: Store<AppState>,
  ) {}
}
