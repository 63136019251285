import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription, of, tap } from 'rxjs';

import { PasswordValidators } from '@src/libs/utility/src/lib/validators/password-validators';
import { StudentIdValidators } from 'src/libs/utility/src/lib/validators/studentid-validators';
import { CountryValidators } from '@src/libs/utility/src/lib/validators/country-validators';
import { ValidateMaxDate } from '@src/libs/utility/src/lib/validators/date-validators';
import { EmailExistsValidator } from '@src/libs/utility/src/lib/validators/email-exists-validator';
import { RegistrationService } from '@src/app/services/registration.service';
import { CountryService } from '@src/app/services/common/country.service';
import { CacheService } from '@src/app/services/common/cache.service';
import { Country } from '@src/app/models/countries';
import { th } from 'date-fns/locale';

@Injectable({
  providedIn: 'root',
})
export class RegistrationFormService {
  emailValidate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  studentIDValidation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  isItaly: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedCountry: BehaviorSubject<Country | null> =
    new BehaviorSubject<Country | null>(null);

  subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private countryService: CountryService,
    private cache: CacheService,
    private registrationService: RegistrationService,
  ) {}

  get showEmailSpinner(): Observable<boolean> {
    return this.emailValidate.asObservable();
  }

  createForm(): FormGroup {
    let formGroup = this.fb.group({
      login: this.fb.group({
        country: [
          '',
          [
            Validators.required,
            CountryValidators.createExistsValidator(this.countryService),
          ],
        ],
        email: [
          '',
          [Validators.required, Validators.email],
          [
            EmailExistsValidator.createValidator(
              this.registrationService,
              this.emailValidate,
            ),
          ],
        ],
        password: ['', [Validators.required, ...PasswordValidators.Validators]],
        tos: ['', Validators.required],
      }),
      identity: this.fb.group({
        firstName: ['', [Validators.required, Validators.maxLength(50)]],
        middleName: ['', [Validators.maxLength(50)]],
        lastName: ['', [Validators.required, Validators.maxLength(50)]],
        isNameMatchedWithGovId: [false, Validators.requiredTrue],
        displayLastNameFirst: [false],
      }),
      dob: this.fb.group({
        birthDate: [null, [Validators.required, ValidateMaxDate]],
        studentID: [''],
        reCaptchaToken: [''],
      }),
    });

    return formGroup;
  }

  setupCountryValidators(formGroup: FormGroup): Observable<string> {
    return (
      formGroup
        .get('login')!
        .get('country')
        ?.valueChanges.pipe(
          tap(selectedCountry => {
            const country = this.countryService.countries.value.find(
              x => x.name === selectedCountry
            );
            if (country) {
              this.selectedCountry.next(country);
            }

            let dobForm = this.getForm(formGroup, 'dob');
            const studentIdControl = dobForm.get('studentID');
            const birthDateControl = dobForm.get('birthDate');

            // Student ID Requirement Check
            studentIdControl?.clearValidators();

            if (country?.isStudentIdNeeded) {
              if (selectedCountry.toLowerCase() === 'italy') {
                studentIdControl?.setValidators([
                  Validators.required,
                  ...StudentIdValidators.Validators,
                ]);
                this.isItaly.next(true);
              } else {
                studentIdControl?.setValidators([Validators.required]);
                this.isItaly.next(false);
              }

              this.studentIDValidation.next(true);
            } else {
              studentIdControl?.clearValidators();
              this.studentIDValidation.next(false);
            }
            let selectedDate = this.getdate();
            if (selectedDate) {
              dobForm.reset();
              birthDateControl?.setValue(selectedDate);
              birthDateControl?.markAsTouched();
            }
            if (!dobForm.pristine) {
              dobForm.updateValueAndValidity();
            }
          }),
        ) || of('')
    );
  }

  getdate(): string {
    let hasCache = this.cache.init('signup', {});
    if (hasCache) {
      let context = this.cache.restore('signup') ?? '';
      return context?.dob?.birthDate;
    }
    return '';
  }

  setupCaching(formGroup: FormGroup): void {
    let hasCache = this.cache.init('signup', {});
    if (hasCache) {
      let context = this.cache.restore('signup') ?? '';

      formGroup.patchValue(context);
      formGroup.markAllAsTouched();
    }
  }

  getForm(formGroup: FormGroup, key: string): FormGroup {
    return formGroup.get(key)! as FormGroup;
  }
}
