<lib-modal-wrapper *transloco="let t">
  <h2 class="mb-2">
    {{ t('userCredential.myGovPopup') }}
    <button
      #tooltip="matTooltip"
      matSuffix
      class="tooltip-bttn"
      mat-button
      attr.aria-label="{{ t('signup.tooltip.govID') }}"
      [matTooltip]="t('signup.tooltip.govID')"
      [matTooltipPosition]="'above'"
      role="link"
    >
      <fa-icon matIconPrefix size="xs" [icon]="faCircleInfo"></fa-icon>
    </button>
    <button
      (click)="closePopup()"
      mat-icon-button
      mat-dialog-close
      aria-label="close dialog"
      class="modal-close-bttn"
    >
      <fa-icon matIconPrefix size="lg" [icon]="faXmark"></fa-icon>
    </button>
  </h2>
  <app-step-2
    [identityFormGroup]="getForm('identity')"
    (next)="onNext($event)"
  ></app-step-2>
</lib-modal-wrapper>
