import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { inject, Inject, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MAT_DIALOG_SCROLL_STRATEGY,
} from '@angular/material/dialog';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { faAddressBook } from '@fortawesome/pro-light-svg-icons';
import { faEarthAmerica } from '@fortawesome/pro-solid-svg-icons';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import {
  TOPAZ_BLOCK_SCROLL_STRATEGY,
  topazDefaultDialogOptions,
} from '@pearsonvue/topaz-angular-ui';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_BASE_URL,
} from 'ng-recaptcha';

import { SrcLibsUiModule } from '@src/libs/ui/src';
import { SrcLibsUtilityModule } from '@src/libs/utility/src';
import { SignupRoutingModule } from 'src/app/modules/signup/signup-routing.module';
import { MaterialUiModule } from '../material-ui.module';
import { TopazUiModule } from '../topaz-ui.module';

import { OtherOptions } from '@src/app/models/defaultOptions-helper';
import { SignupComponent } from './components/signup/signup.component';
import { UseragreementmodalComponent } from './components/useragreementmodal/useragreementmodal.component';
import { Step1Component } from './wizard/step-1/step-1.component';
import { Step2Component } from './wizard/step-2/step-2.component';
import { Step3Component } from './wizard/step-3/step-3.component';
import { MissingUserInfoComponent } from '../clever-sso/missing-user-info/missing-user-info.component';
import { MissingUserFormComponent } from '../clever-sso/missing-user-form/missing-user-form.component';
import { GovStudentidPopupWrapperComponent } from '../clever-sso/gov-studentid-popup-wrapper/gov-studentid-popup-wrapper.component';
import { APP_CONFIG, Config } from '@src/app/config/config';
import { IesNewUserFormComponent } from '../clever-sso/ies-new-user/IesNewUserForm.component';

@NgModule({
  declarations: [
    SignupComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    UseragreementmodalComponent,
    MissingUserInfoComponent,
    MissingUserFormComponent,
    GovStudentidPopupWrapperComponent,
    IesNewUserFormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TranslocoModule,
    TopazUiModule,
    MaterialUiModule,
    SignupRoutingModule,
    SrcLibsUiModule,
    SrcLibsUtilityModule,
    RecaptchaFormsModule,
    RecaptchaModule,
  ],
  exports: [Step2Component],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: topazDefaultDialogOptions,
    },
    {
      provide: TRANSLOCO_SCOPE,
      useValue: '',
    },
    /* Disables scrolling while a dialog is open
    This strategy can be used for other
    overlay based components as well */
    {
      provide: MAT_DIALOG_SCROLL_STRATEGY,
      useFactory: TOPAZ_BLOCK_SCROLL_STRATEGY,
      deps: [Overlay],
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: OtherOptions },
    {
      provide: RECAPTCHA_SETTINGS,
      useFactory: () => {
        const config = inject(APP_CONFIG);
        return {
          siteKey: config.siteKey,
        } as RecaptchaSettings;
      },
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: sessionStorage.getItem('captachLanguage'),
    },
    {
      provide: RECAPTCHA_BASE_URL,
      useValue: 'https://recaptcha.net/recaptcha/api.js',
    },
  ],
})
export class SignupModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faAddressBook, faEarthAmerica);
  }
}
