import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { generateLoginRedirect } from '@certiport/login-library';

import { APP_CONFIG, Config } from '@src/app/config/config';
import { LanguagePickerService } from '@src/app/services/common/language-picker.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-login-v2',
  templateUrl: './login-v2.component.html',
  styleUrl: './login-v2.component.scss',
})
export class LoginV2Component implements OnDestroy, OnInit {
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_CONFIG) readonly config: Config,
    private router: Router,
    private languagePickerService: LanguagePickerService,
  ) {}

  ngOnInit(): void {
    if (this.config.iesFlag) {
      this.languagePickerService.selectedLanguageProps$
        .asObservable()
        .pipe(takeUntil(this.destroy$))
        .subscribe(language => {
          if (!language) {
            return;
          }
          this.document.location.href = generateLoginRedirect(
            this.config.baseLoginUri,
            this.config.subLoginUri,
            this.config.ssoUri,
            '',
            language?.locale.toLowerCase() || 'en',
            this.config.iesRegionLangs,
          );
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
