import { Action, ActionReducer, MetaReducer } from '@ngrx/store';

import {
  ProfileEffects,
  ProfileState,
  profileInitialState,
  profileReducer,
} from './profile';
import {
  SessionEffects,
  SessionState,
  sessionInitialState,
  sessionReducer,
} from './session';
import { CoreActions } from '.';

export interface AppState {
  session: SessionState;
  profile: ProfileState;
}
export const InitialState: AppState = {
  session: sessionInitialState,
  profile: profileInitialState,
};

export interface AppStore {
  session: ActionReducer<SessionState, Action>;
  profile: ActionReducer<ProfileState, Action>;
}

export const appStore: AppStore = {
  session: sessionReducer,
  profile: profileReducer,
};

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    return reducer(state, action);
  };
}
export function clear(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === CoreActions.CoreActionKeys.LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const appEffects = [SessionEffects, ProfileEffects];
export const metaReducers: MetaReducer<any>[] = [debug, clear];
