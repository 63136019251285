import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import {
  faChevronDown,
  faChevronUp,
  faEarthAmericas,
  faBars,
  faCaretDown,
} from '@fortawesome/pro-solid-svg-icons';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { Store } from '@ngrx/store';
import {
  GlobalTabsBarComponent,
  SideSheetService,
  TabSubView,
  TopazOverlayRef,
} from '@pearsonvue/topaz-angular-ui';
import { map, Observable, Subscription, take } from 'rxjs';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { APP_CONFIG, Config } from '@src/app/config/config';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  AppState,
  selectRoles,
  selectSession,
  SessionActions,
} from '@src/app/core/store';
import { LanguagePickerService } from '../../../../../app/services/common/language-picker.service';
import {
  DefaultTab,
  HeaderConfig,
  RoleNavBarTab,
} from '../models/headerConfig';
import { UserDetails } from '../models/store/userDetails';

import { MobileSideMenuComponent } from '../components/mobile-side-menu/mobile-side-menu.component';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() auth!: Observable<boolean>;
  @Input() headerConfig!: Observable<HeaderConfig>;
  @Input() userDetails!: Observable<UserDetails>;
  @Input() headerRoles!: Array<number>;
  @ViewChild('tabsBar') tabsBar!: GlobalTabsBarComponent;
  @ViewChild('drawer') drawer!: MatSidenav;

  activeLink = '';
  authStatus = false;
  userName = '';
  mobileDisplay$!: Observable<boolean>;
  sideMenu!: TopazOverlayRef;

  _headerConfig: HeaderConfig = { tabs: [DefaultTab] };

  subscriptions: Subscription[] = [];

  toggle: boolean[] = [];

  faUser = faUser;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faCaretDown = faCaretDown;
  faBars = faBars;
  faCircleQuestion = faCircleQuestion;
  faEarthAmericas = faEarthAmericas;

  constructor(
    @Inject(APP_CONFIG) private config: Config,
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private store: Store<AppState>,
    private languagePickerService: LanguagePickerService,
    private sideSheet: SideSheetService,
  ) {
    this.mobileDisplay$ = this.breakpointObserver
      .observe('(min-width: 600px)')
      .pipe(map(({ matches }) => (matches ? true : false)));
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.auth.subscribe(auth => (this.authStatus = auth)),
    );
    this.subscriptions.push(
      this.userDetails.subscribe(useDetails => this.setUserDetails(useDetails)),
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.headerConfig.subscribe(hConfig => {
        this._headerConfig = hConfig;
        //TODO: setup tab and subview for initially routed page
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get showTabHeader(): boolean {
    return this._headerConfig.tabs.length > 1 && this.authStatus;
  }

  //#region Icon Methods
  get showMenu(): boolean {
    return this._headerConfig.tabs.length <= 1 && this.authStatus;
  }

  get showAllRoles(): Observable<boolean> {
    return this.store.select(selectRoles).pipe(
      take(1),
      map(roles => roles.some(role => !this.headerRoles.includes(role.roleID))),
    );
  }

  openLanguageSelectorDialog(): void {
    this.languagePickerService.openLanguagePicker();
  }
  openLanguageSelectorKeydown($event: KeyboardEvent): void {
    if ($event.code === 'Space' || $event.key === 'Enter') {
      this.openLanguageSelectorDialog();
    }
  }
  supportRedirect(): void {
    window.open('https://certiport.pearsonvue.com/Support.aspx');
  }
  supportRedirectKeydown($event: KeyboardEvent): void {
    if ($event.code === 'Space' || $event.key === 'Enter') {
      this.supportRedirect();
    }
  }
  allRoleRedirect(): void {
    alert('All Role Redirect Clicked');
  }
  allRoleKeydown($event: KeyboardEvent): void {
    if ($event.code === 'Space' || $event.key === 'Enter') {
      this.allRoleRedirect();
    }
  }
  //#endregion

  //#region Global Bar Methods
  openMenu(): void {
    this.sideMenu = this.sideSheet.open(MobileSideMenuComponent, {
      data: {
        headerConfig: this._headerConfig,
        showAllRoles: this.showAllRoles,
        activeLink: this.activeLink,
      },
    });
    this.sideMenu
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result.type === 'close' && result.data?.logout) {
          this.handleLogoutClick();
        } else if (result.data?.activeTab) {
          this.handleMenuClick(
            result.data?.activeTab,
            result.data?.activeSubTab,
          );
        }
      });
  }
  closeMenu(): void {
    if (this.sideMenu) this.sideMenu.close();
  }

  handleTabChange($event: RoleNavBarTab): void {
    // list if UI roles setup
    this.store
      .select(selectSession)
      .pipe(take(1))
      .subscribe(session => {
        window.location.href = `${this.config.portalRootUrl}/portal/DesktopDefault.aspx?roleid=${$event.roleId}&sessionId=${session.PortalUserSessionID}`;
        this.tabsBar.toggleDrawer(this.drawer);
      });
  }

  handleViewChange($event: TabSubView): void {
    this.closeMenu();
    const snapshot = this.route.snapshot;

    if (this.activeLink === $event.label) {
      return;
    } else {
      this.activeLink = $event.label;
    }

    switch ($event.data?.['category']) {
      case 'legacy':
        this.store
          .select(selectSession)
          .pipe(take(1))
          .subscribe(session => {
            let tree = '';
            snapshot.queryParamMap.keys.forEach(key => {
              tree.concat('&' + key + '=' + snapshot.queryParamMap.get(key));
            });
            tree += '&sessionId=' + session.PortalUserSessionID;
            this.router.createUrlTree([], {
              queryParams: {
                ...snapshot.queryParams,
                sessionId: session.PortalUserSessionID,
              },
            });
            if (this.drawer) {
              this.tabsBar.toggleDrawer(this.drawer);
            }

            window.location.href = `${this.config.portalRootUrl}${$event.data?.['path']}${tree}`;
          });
        break;
      case 'portal':
        if (snapshot.routeConfig?.path === $event.data?.['path']) {
          return;
        }
        if (this.drawer) {
          this.tabsBar.toggleDrawer(this.drawer);
        }

        this.router.navigate([$event.data?.['path']], {
          queryParamsHandling: 'preserve',
        });
        break;
      default:
        break;
    }
  }

  handleMenuClick($roleNav: RoleNavBarTab, $subView: TabSubView): void {
    if ($roleNav.roleId === 1) {
      this.handleViewChange($subView);
    } else {
      this.handleTabChange($roleNav);
    }
  }

  handleLogoClick(): void {
    window.open('https://certiport.pearsonvue.com/', '_blank');
  }
  handleProfileClick(): void {
    if (
      this.tabsBar.activeTab.label === this._headerConfig.tabs[0].label &&
      this.activeLink === this._headerConfig.tabs[0].subViews[4].label
    ) {
      return;
    } else {
      this.router.navigate(['/profile'], { queryParamsHandling: 'preserve' });
    }
  }
  handleLogoutClick(): void {
    this.store.dispatch(SessionActions.clear());
  }
  //#endregion

  setUserDetails(user: UserDetails): void {
    if (user.lastNameFirst) {
      this.userName = `${user.lastName} ${user.firstName}`;
    } else {
      this.userName = `${user.firstName} ${user.lastName}`;
    }
  }
}
