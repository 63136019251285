import { Component } from '@angular/core';
import {
  faCookieBite,
  faEarthAmericas,
} from '@fortawesome/pro-solid-svg-icons';

import { TranslocoService } from '@jsverse/transloco';

import { LanguagePickerService } from '@src/app/services/common/language-picker.service';
import { SessionStorageService } from '@src/app/services/common/session-storage.service';

declare const Optanon: any;

@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  date = new Date();
  year = this.date.getFullYear();

  faEarthAmericas = faEarthAmericas;
  faCookieBite = faCookieBite;
  
  constructor(
    private languagePickerService: LanguagePickerService,
    private t: TranslocoService,
  ) {}

  openLanguageSelectorDialog() {
    this.languagePickerService.openLanguagePicker();
  }

  openLink(linkName: string) {
    switch (linkName) {
      case 'Terms':
        window.open(this.t.translate('footer.termsUrl'), '_blank');
        break;
      case 'Privacy':
        window.open(this.t.translate('footer.privacyUrl'), '_blank');
        break;
      case 'Contact':
        window.open(this.t.translate('footer.contactUrl'), '_blank');
        break;
    }
  }

  getLanguageLocaleAndName() {
    return this.languagePickerService.selectedLanguageLocaleAndName;
  }

  openCookiePreferences(event: Event) {
    Optanon.TriggerGoogleAnalyticsEvent(
      'OneTrust Cookie Consent',
      'banner open preferences',
    );
    event.preventDefault();
  }
}
