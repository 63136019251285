import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { faAddressBook } from '@fortawesome/pro-light-svg-icons';
import { faEarthAmerica } from '@fortawesome/pro-solid-svg-icons';
import { enUS } from 'date-fns/locale';

import { TopazUiModule } from '../../../../app/modules/topaz-ui.module';
import { MaterialUiModule } from '../../../../app/modules/material-ui.module';
import { TranslocoRootModule } from '../../../../app/transloco-root.module';

import { FullWidthLayoutComponent } from './layout/full-width-layout/full-width-layout.component';
import { WideLayoutComponent } from './layout/wide-layout/wide-layout.component';
import { ThinLayoutComponent } from './layout/thin-layout/thin-layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CardComponent } from './components/card/card.component';
import { CleverComponent } from './components/clever/clever.component';
import { ToastConfigService } from './components/custom-toast/services/config/toast-config-service.service';
import { EndpointHandlerComponent } from './components/endpoint-handler/endpoint-handler.component';
import { TableComponent } from './components/table/table.component';
import { TosComponent } from './components/tos/tos.component';
import { CustomBannerComponent } from './components/custom-banner/custom-banner.component';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { CustomerrorComponent } from './components/customerror/customerror.component';
import { StepWrapperComponent } from './components/step-wrapper/step-wrapper.component';
import { ModalWrapperComponent } from './components/modal-wrapper/modal-wrapper.component';
import { NationalIdComponent } from './conontrol-value-accessor/nationId/national-id.component';
import { CtDatePickerComponent } from './conontrol-value-accessor/ct-date-picker/ct-date-picker.component';

import { DATE_FORMATS } from '../../../../app/models/dateFormat';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LanguagePickerService } from '@src/app/services/common/language-picker.service';
import { SafeHtmlPipe } from './pipes/safeHtmlPipe.pipe';
import { MobileSideMenuComponent } from './components/mobile-side-menu/mobile-side-menu.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TopazUiModule,
    FormsModule,
    FontAwesomeModule,
    MaterialUiModule,
    TranslocoRootModule,
    HttpClientModule,
    TopazUiModule,
    ReactiveFormsModule,
    SafeHtmlPipe,
    ReactiveFormsModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    FullWidthLayoutComponent,
    WideLayoutComponent,
    ThinLayoutComponent,
    CardComponent,
    CleverComponent,
    EndpointHandlerComponent,
    TableComponent,
    TosComponent,
    CustomBannerComponent,
    CustomToastComponent,
    StepWrapperComponent,
    ModalWrapperComponent,
    CustomerrorComponent,
    CtDatePickerComponent,
    NationalIdComponent,
    MobileSideMenuComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    FullWidthLayoutComponent,
    WideLayoutComponent,
    ThinLayoutComponent,
    CardComponent,
    CleverComponent,
    EndpointHandlerComponent,
    TableComponent,
    TosComponent,
    CustomBannerComponent,
    StepWrapperComponent,
    ModalWrapperComponent,
    CustomToastComponent,
    CustomerrorComponent,
    CtDatePickerComponent,
    NationalIdComponent,
    SafeHtmlPipe,
  ],
  providers: [
    ToastConfigService,
    LanguagePickerService,
    SafeHtmlPipe,
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMATS,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: enUS,
    },
  ],
})
export class SrcLibsUiModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faAddressBook, faEarthAmerica);
  }
}
