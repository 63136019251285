import { Inject, Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { APP_CONFIG, Config } from '@src/app/config/config';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  appInsights: ApplicationInsights;

  constructor(@Inject(APP_CONFIG) private _config: Config) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: _config.apInCode,
      },
    });
    this.appInsights.loadAppInsights();
  }

  trackPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }
}
