import { createSelector } from '@ngrx/store';

import { AppState } from '../core.state';
import { SessionState } from './session.state';
import { DefaultAuthenticationOutput } from '@certiport/login-library';

export const selectSessionState = (state: AppState) => state.session;

export const selectSessionLoading = createSelector(
  selectSessionState,
  (state: SessionState) => state.loading || false,
);
export const selectSessionError = createSelector(
  selectSessionState,
  (state: SessionState) => state.error || {},
);
export const selectSession = createSelector(
  selectSessionState,
  (state: SessionState) => state.session || DefaultAuthenticationOutput,
);
