import { Inject, Injectable, OnDestroy } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslocoService } from '@jsverse/transloco';
import {
  LocaleDialogConfig,
  LocaleItem,
  TopazLocaleLoaderService,
  TopazLocaleSelectorComponent,
} from '@pearsonvue/topaz-angular-ui';
import {
  es,
  fr,
  ar,
  nl,
  enUS,
  bg,
  zhCN,
  zhTW,
  it,
  ko,
  vi,
  ja,
  tr,
  th,
  sv,
  pt,
  pl,
  id,
  el,
} from 'date-fns/locale';
import { BehaviorSubject, map, Observable, Subscription, take } from 'rxjs';

import { LanguageInfo } from '../../models/languageinfo.model';
import { SessionStorageService } from './session-storage.service';
import languageList from '@src/assets/languageList.json';
import { CpHttpClientService } from '@src/libs/data-access/src/lib/services/cp-http-client.service';
import { APP_CONFIG, Config } from '@src/app/config/config';

@Injectable({
  providedIn: 'root',
})
export class LanguagePickerService implements OnDestroy {
  private _selectedLanguageLocaleAndNameInEnglish = 'English - English';
  private sak_language_uri = `${this.config.microServiceURI}/sak/Languages?isActive=true`;

  languageInfos: LanguageInfo[] = [];
  locales: LocaleItem[] = [];
  subscriptions: Subscription[] = [];
  languageList$: BehaviorSubject<LanguageInfo[] | []> = new BehaviorSubject<
    LanguageInfo[] | []
  >([]);

  public languageInfos$: BehaviorSubject<LanguageInfo[]> = new BehaviorSubject<
    LanguageInfo[]
  >(this.languageInfos);
  public filteredLanuageInfos$: BehaviorSubject<LanguageInfo[]> =
    new BehaviorSubject<LanguageInfo[]>([]);
  public selectedLanguageProps$: BehaviorSubject<LanguageInfo | null> =
    new BehaviorSubject<LanguageInfo | null>(null);
  public locale: BehaviorSubject<LocaleItem | null> =
    new BehaviorSubject<LocaleItem | null>(this.locales[0]);

  dateFnsLocal: { [id: string]: any } = {
    ara: ar,
    bgr: bg,
    chs: zhCN,
    cht: zhTW,
    fra: fr,
    ita: it,
    nld: nl,
    kor: ko,
    jpn: ja,
    vie: vi,
    trk: tr,
    tha: th,
    sve: sv,
    ptb: pt,
    plk: pl,
    ind: id,
    esm: es,
    ell: el,
    enu: enUS,
  };

  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private _translocoService: TranslocoService,
    private _sessionStorage: SessionStorageService,
    private _adapter: DateAdapter<Date>,
    private _http: CpHttpClientService,
    private localeLoaderService: TopazLocaleLoaderService,
  ) {
    this._http
      .get<LanguageInfo[]>(this.sak_language_uri)
      .pipe(take(1))
      .subscribe(data => {
        this.languageInfos = data.data as LanguageInfo[];
        this.languageList$.next(this.languageInfos);
        this.languageInfos.forEach(language =>
          this.locales.push({
            code: language.locale.toLowerCase(),
            label: language.name,
            displayName: language.label,
          } as LocaleItem),
        );
        const selectedLang = this.languageInfos.find(
          x => x.languageCode === languageCode,
        );
        const selectedLocale = this.locales.find(
          x => x.code === selectedLang?.locale,
        );
        this.locale.next(selectedLocale!);
      });

    this.subscriptions.push(
      this.localeLoaderService.selectedLocaleSubject$
        .asObservable()
        .subscribe((locale: LocaleItem) => this.setLanguage(locale)),
    );

    const languageCode = this.getLanguageSelectedByUser();
    if (languageCode) {
      this.languageInfos.forEach(x => {
        x.isChecked = x.languageCode === languageCode;
      });
    }

    const savedLanguageFromStore = languageList.find(
      lang => lang.languageCode === this.getLanguageSelectedByUser(),
    );
    this.selectedLanguageProps$.next(
      savedLanguageFromStore as any as LanguageInfo,
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  setLanguage(locale: LocaleItem | null) {
    let languageInfo = this.languageInfos.find(
      x => x.locale.toLocaleLowerCase() === locale?.code,
    );

    if (!languageInfo) {
      languageInfo = {
        languageCode: 'enu',
        locale: 'enUS',
        name: 'English',
        label: 'English',
        isChecked: 'false',
        direction: 'ltr',
        languageId: 5,
      };
    }

    this.locale.next(locale);

    this.checkAndFilterLang(languageInfo);
    this._selectedLanguageLocaleAndNameInEnglish = `${languageInfo.label} - ${languageInfo.name}`;
    this._sessionStorage.setItem('languageCode', languageInfo.languageCode);
    this._translocoService.setActiveLang(
      languageInfo.languageCode.toLocaleLowerCase(),
    );
    this.selectedLanguageProps$.next(languageInfo);
    this.setLanguageByLangCode(languageInfo.languageCode);
  }

  public get selectedLanguageLocaleAndName() {
    if (
      !(
        this._sessionStorage.getItem('languageName') ||
        this._sessionStorage.getItem('languageNameInEnglish')
      )
    ) {
      return this._selectedLanguageLocaleAndNameInEnglish;
    }
    const langLocakleAndNameInEnglish =
      this._sessionStorage.getItem('languageName')! +
      ' - ' +
      this._sessionStorage.getItem('languageNameInEnglish');
    return langLocakleAndNameInEnglish;
  }

  getLanguageSelectedByUser(): string | null {
    return this._sessionStorage.getItem('languageCode') ?? 'enu';
  }

  openLanguagePicker() {
    const config: LocaleDialogConfig = {
      title: this._translocoService.translate('common.language'),
      subTitle: this._translocoService.translate('common.preferLang'),
      contentHeader: this._translocoService.translate(
        'languagemodal.availLang',
      ),
      locale: this.locale.value!,
      applyCtaLabel: this._translocoService.translate('common.apply'),
      dismissCtaLabel: this._translocoService.translate('common.cancel'),
      // Add more configuration options as needed
    };
    this.localeLoaderService.open(
      TopazLocaleSelectorComponent,
      this.locales,
      config,
    );
  }

  //#region SetLanguage
  public setLanguageOptions(
    prefLanguageInfo: LanguageInfo,
    sessionStorage = this._sessionStorage,
  ) {
    if (!prefLanguageInfo) {
      return;
    }
    sessionStorage.setItem('languageCode', prefLanguageInfo.languageCode);
    sessionStorage.setItem('languageName', prefLanguageInfo.label);
    sessionStorage.setItem('languageNameInEnglish', prefLanguageInfo.name);
    this._adapter.setLocale(this.dateFnsLocal[prefLanguageInfo.languageCode]);
    this._translocoService.setActiveLang(prefLanguageInfo.languageCode);
    this.selectedLanguageProps$.next(prefLanguageInfo);
  }

  public setLanguageByLangCode(langCode: string) {
    if (!langCode) {
      const browserLocale = navigator.language.split('-')[0];
      const cacheLang = sessionStorage.getItem('languageCode') ?? null;

      if (cacheLang) {
        langCode = cacheLang;
      } else if (browserLocale) {
        const languageInfo = this.languageInfos.find(
          x => x.locale === browserLocale,
        );
        if (languageInfo) {
          langCode = languageInfo.languageCode;
        } else {
          // Fallback to a default language code if languageInfo is not found
          langCode = 'enu';
        }
      } else {
        langCode = 'enu';
      }
    }

    const langOption = languageList.find(({ languageCode }) =>
      new RegExp(languageCode, 'i').test(langCode),
    );
    if (langOption) {
      this.setLanguageOptions(langOption);
    }
  }

  public setLanguageByLangId(langId: number) {
    if (!langId) {
      return;
    }

    const langOption = languageList.find(
      ({ languageId }) => languageId === langId,
    );
    if (langOption) {
      this.setLanguageOptions(langOption);
    }
  }

  setLocale(langCode: string) {
    if (langCode) {
      let languageInfo = this.languageInfos.find(
        x => x.languageCode === langCode,
      );
      if (languageInfo) {
        this.checkAndFilterLang(languageInfo);
        this._selectedLanguageLocaleAndNameInEnglish = `${languageInfo.label} - ${languageInfo.name}`;
      }
    }
  }

  checkAndFilterLang(languageInfo: LanguageInfo) {
    languageInfo.isChecked = true;
    this.languageInfos
      .filter(x => x.name != languageInfo?.name && x.isChecked)
      .forEach(x => (x.isChecked = false));
  }
  //#endregion

  public getLanguage() {
    return this._http.get<LanguageInfo[]>('./assets/languageList.json');
  }

  findLanguageById(languageId: number): LanguageInfo | undefined {
    return this.languageInfos.find(
      language => language.languageId === languageId,
    );
  }
  filterLanguages(filter: string): Observable<LanguageInfo[]> {
    return this.languageInfos$.asObservable().pipe(
      map(lanaguages =>
        (lanaguages as LanguageInfo[])
          .filter(language =>
            language.name.toLowerCase().includes(filter.toLowerCase()),
          )
          .sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          }),
      ),
    );
  }
}
