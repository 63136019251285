import { AuthenticationOutput } from '@certiport/login-library';
import { createAction, props } from '@ngrx/store';

const enum SessionActionKeys {
  UPDATE = '[Session] Update',
  UPDATE_SUCCESS = '[Session] Update Success',
  UPDATE_FAILURE = '[Session] Update Failure',
  CLEAR = '[Session] Clear',
  CLEAR_SUCCESS = '[Session] Clear Success',
  CLEAR_FAILURE = '[Session] Clear Failure',
}

export const update = createAction(SessionActionKeys.UPDATE);
export const updateSuccess = createAction(
  SessionActionKeys.UPDATE_SUCCESS,
  props<{ session: AuthenticationOutput }>(),
);
export const updateFailure = createAction(
  SessionActionKeys.UPDATE_FAILURE,
  props<{ error: any }>(),
);

export const clear = createAction(SessionActionKeys.CLEAR);
export const clearSuccess = createAction(SessionActionKeys.CLEAR_SUCCESS);
export const clearFailure = createAction(
  SessionActionKeys.CLEAR_FAILURE,
  props<{ error: string }>(),
);
