import { Component, forwardRef, Injector, Inject, OnInit, Input } from '@angular/core'
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { Subject, takeUntil, startWith, distinctUntilChanged, tap } from 'rxjs';
import { faCalendar } from '@fortawesome/pro-light-svg-icons'

@Component({
  selector: 'lib-date-picker',
  templateUrl: './ct-date-picker.component.html',
  styleUrls: ['./ct-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CtDatePickerComponent),
      multi: true,
    },
  ],
})
export class CtDatePickerComponent implements ControlValueAccessor, OnInit {
  @Input() label = 'Date of birth';
  control!: FormControl;
  isRequired = false;
  faCalendar = faCalendar;
  todayDate = new Date();

  private _destroy$ = new Subject<void>();
  private _onTouched!: () => any;

  constructor(
    @Inject(Injector) private injector: Injector,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.setFormControl();
    this.isRequired = this.control?.hasValidator(Validators.required) ?? false;
  }

  setFormControl() {
    try {
      const formControl = this.injector.get(NgControl);

      switch (formControl.constructor) {
        case FormControlName:
          this.control = this.injector
            .get(FormGroupDirective)
            .getControl(formControl as FormControlName);
          break;
        default:
          this.control = (formControl as FormControlDirective)
            .form as FormControl;
          break;
      }
    } catch (err) {
      this.control = new FormControl();
    }
  }

  writeValue(value: any): void {
    if (this.control && this.control.value != value)
      this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (val: any | null) => any): void {
    this.control?.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        startWith(this.control.value),
        distinctUntilChanged(),
        tap(val => fn(val)),
      )
      .subscribe(() => this.control?.markAsUntouched());
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChanged = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn;
  }

  hasError(): boolean {
    return !!this.control?.errors && !!this.control?.touched;
  }

  getValidationText() {
    if (this.control?.hasError('maxDate')) {
      return this.translocoService.translate('signup.error.maxDate');
    }
    if (this.control?.hasError('matDatepickerParse')) {
      return this.translocoService.translate(`signup.error.dateFormat`);
    }
    return this.control?.hasError('required')
      ? this.translocoService.translate(`signup.error.required_birthDate`)
      : '';
  }
}
