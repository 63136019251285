import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';

import { APP_CONFIG, Config } from '@src/app/config/config';
import { IesService } from './ies.service';
import { SsoService } from './sso.service';
import { IESModel } from '../models/ies-model';
import { SSOModel } from '../models/sso-model';

export const iesResolver: ResolveFn<IESModel | SSOModel> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const config: Config = inject(APP_CONFIG);
  if (config.iesFlag) {
    return inject(IesService).IESUserObject.asObservable();
  } else {
    const token = route.queryParams['token'] || '';
    return inject(SsoService).fetchSSOUser(token);
  }
};
