// date-fns documentation for date formats
// https://date-fns.org/v2.30.0/docs/format

export const DATE_FORMATS = {
  parse: {
    dateInput: [
      'd MMM yyyy', // 2 aug 1993
      'dd MMM yyyy', // 02 aug 1993
      'd MMM yy', // 2 aug 93
      'dd MMM yy', // 02 aug 93
      'd MMMM yyyy', // 2 august 1993
      'dd MMMM yyyy', // 02 august 1993
      'd MMMM yy', // 2 august 93
      'dd MMMM yy', // 02 august 93
      'ddMMyyyy', // 02081993
      'ddMMyy', // 020893
      'dd/MM/yyyy', // 02/08/1993
      'd/M/yyyy', // 2/8/1993
      'dd/MM/yy', // 02/08/93
      'd/M/yy' // 8/2/93
    ],
  },
  display: {
    dateInput: 'dd MMM yyyy',
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'dd MM yyyy',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};
