<mat-form-field appearance="outline" *transloco="let t">
  <mat-label data-id="res-s1-email-label">
    @if(label)
    {
      {{t(label)}}
    }
  </mat-label>
    <input
      matInput
      type="text"
      [required]="isRequired"
      [placeholder]="placeHolder"
      [formControl]="control"
      data-id="email-input"
    />
    <fa-icon
      matIconPrefix
      [icon]="faEnvelope"
      [ngClass]="[hasError('email') ? 'red' : '']"
    ></fa-icon>
    <mat-error *ngIf="hasError('email')">
      {{ getEmilValidation() }}
    </mat-error>
  </mat-form-field>

