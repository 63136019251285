import { BreakpointObserver } from '@angular/cdk/layout';
import {
  STEPPER_GLOBAL_OPTIONS,
  StepperOrientation,
} from '@angular/cdk/stepper';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { LoaderService } from '@pearsonvue/topaz-angular-ui';
import moment from 'moment';
import {
  EMPTY,
  Observable,
  Subscription,
  catchError,
  debounce,
  distinctUntilChanged,
  finalize,
  interval,
  map,
  take,
} from 'rxjs';

import { APP_CONFIG, Config } from '@src/app/config/config';
import { CacheService } from '@src/app/services/common/cache.service';
import { CountryService } from '@src/app/services/common/country.service';
import { getAge } from '@src/libs/utility/src/lib/validators/age-validators';
import { RegistrationModel } from '../../../../models/registration.model';
import { RegistrationService } from '../../../../services/registration.service';
import { RegistrationFormService } from '../../services/registration-form.service';
import { Step3Component } from '../../wizard/step-3/step-3.component';
import { LanguagePickerService } from '@src/app/services/common/language-picker.service';
import { generateLoginRedirect } from '@certiport/login-library';
import { LanguageInfo } from '@src/app/models/languageinfo.model';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true },
    },
  ],
})
export class SignupComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('stepper') stepper!: MatStepper;

  step = 0;
  showStep1Image: boolean = true;
  sessionId: string = '';
  @ViewChild(Step3Component) private _step3?: Step3Component;

  stepperOrientation!: Observable<StepperOrientation>;
  formGroup: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    @Inject(APP_CONFIG) readonly config: Config,
    private cd: ChangeDetectorRef,
    private router: Router,
    private cache: CacheService,
    private countryService: CountryService,
    private registrationService: RegistrationService,
    private registrationFormService: RegistrationFormService,
    private breakpointObserver: BreakpointObserver,
    private loaderService: LoaderService,
    private translocoService: TranslocoService,
    public langPickerService: LanguagePickerService,
  ) {
    this.formGroup = registrationFormService.createForm();
    this.subscriptions.push(
      registrationFormService
        .setupCountryValidators(this.formGroup)
        .subscribe(),
    );

    this.stepperOrientation = this.breakpointObserver
      .observe('(min-width: 600px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    this.registrationService.resetUnder13();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.setupCaching();
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.cache.clear('signup');
    this.loaderService.hideLoader();
  }

  setupCaching(): void {
    this.registrationFormService.setupCaching(this.formGroup);

    this.subscriptions.push(
      this.formGroup.valueChanges
        .pipe(
          distinctUntilChanged(),
          debounce(i => interval(200)),
        )
        .subscribe(formValue => {
          const tempFormValue = JSON.parse(JSON.stringify(formValue));
          tempFormValue.login.password = '';
          this.cache.set('signup', tempFormValue);
        }),
    );

    this.subscriptions.push(
      this.stepper.selectionChange
        .pipe(distinctUntilChanged())
        .subscribe(event => {
          this.cache.set('signup:step', event.selectedIndex);
        }),
    );
  }

  getForm(key: string): FormGroup {
    return this.formGroup.get(key)! as FormGroup;
  }

  onNext(): void {
    this.stepper.next();
  }

  onPrevious(): void {
    this.stepper.previous();
  }

  onSubmit(): void {
    if (this.formGroup?.valid) {
      const loginForm: FormGroup = this.getForm('login');
      const identityForm: FormGroup = this.getForm('identity');
      const dobForm: FormGroup = this.getForm('dob');
      const countryData = this.countryService.countries.value.find(
        x => x.name === loginForm.controls['country'].value,
      );
      if (!identityForm.get('displayLastNameFirst')?.value) {
        identityForm.get('displayLastNameFirst')?.setValue(false);
      }

      let selectedLang: LanguageInfo | undefined =
        this.langPickerService.languageInfos.find(
          lang =>
            lang.languageCode ===
            this.langPickerService.getLanguageSelectedByUser(),
        ) || undefined;

      if (!selectedLang) {
        this.langPickerService.selectedLanguageProps$.pipe().subscribe(val => {
          selectedLang = val;
        });
      }

      let studentID = '';
      if (countryData?.isStudentIdNeeded) {
        if (this.registrationFormService.isItaly.value) {
          studentID = dobForm.value.studentID
            .split(' ')
            .join('')
            .replaceAll('-', '');
        } else {
          studentID = dobForm.value.studentID;
        }
      }

      const registrationData: RegistrationModel = {
        ...loginForm.value,
        ...identityForm.value,
        ...dobForm.value,
        studentId: studentID,
        country: {
          countryId: countryData?.countryId,
          countryName: countryData?.name,
        },
        preferredLanguageId: selectedLang?.languageId ?? 5,
        parentApproved: true,
        isActive: true,
        isUserAgreementChecked: loginForm.get('tos')?.value,
        automation: this._step3?.automationParam === 'true' ? true : false,
        birthDate: moment(dobForm.value.birthDate).format('YYYY-MM-DD'),
      };

      const localeParam = this.langPickerService.languageInfos.find(
        lang =>
          lang.languageCode?.toLowerCase() ==
          selectedLang?.languageCode?.toLowerCase(),
      );

      // under 13 check
      const country = this.countryService.countries.value.find(
        x => x.countryId === registrationData.country.countryId,
      );
      if (
        country?.isParentApprovalNeededForMinor &&
        getAge(new Date(registrationData.birthDate)) < 13
      ) {
        this.registrationService.under13RegistrationRequest?.next(
          registrationData,
        );
        this.cache.setComplexItem('under13form', registrationData);
        this.cache.clear('signup');
        if (this._step3?.automationParam === 'true') {
          this.router.navigate(['/under13'], {
            queryParams: { automation: 'true' },
            queryParamsHandling: 'preserve',
          });
          return;
        }
        this.router.navigate(['/under13']);
        return;
      }
      this.loaderService.showLoader(
        this.translocoService.translate('common.loadingText'),
      );
      this.registrationService
        .register(registrationData, !this.config.iesFlag)
        .pipe(
          take(1),
          finalize(() => this.loaderService.hideLoader()),
          catchError(() => {
            this.loaderService.hideLoader();
            return EMPTY;
          }),
        )
        .subscribe(result => {
          if (result.data.status === 201 || result.data.status === 204) {
            if (this.config.iesFlag) {
              window.location.href = generateLoginRedirect(
                this.config.baseLoginUri,
                this.config.subLoginUri,
                this.config.ssoUri,
                '',
                localeParam?.locale,
              );
            } else {
              this.router.navigate(['/'], {
                queryParamsHandling: 'preserve',
              });
            }
          } else {
            this.sessionId = result.data?.data.PortalUserSessionID;
            this.cache.clear('signup');
            if (!this.config.profileFlag) {
              window.location.href = `${this.config.portalRootUrl}/Portal/SSL/LoginRedirect.aspx?sessionId=${this.sessionId}`;
            } else {
              this.router.navigate(['/profile'], {
                queryParamsHandling: 'merge',
              });
            }
          }
        });
    } else {
      return;
    }
  }
}
