import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  constructor() {}

  init(key: string, value?: any): boolean {
    if (sessionStorage.getItem(key)) {
      return true;
    }
    sessionStorage.setItem(key, JSON.stringify(value ?? '{}'));
    return false;
  }

  set(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  cache(key: string, subKey: string, value: any): void {
    const KEY = this.getKey(key);
    if (KEY != null) {
      const context = JSON.parse(KEY ?? '');
      context[subKey] = JSON.stringify(value);
      sessionStorage.setItem(key, JSON.stringify(context));
    }
  }

  restore(key: string): any {
    const KEY = this.getKey(key);
    if (KEY != null) {
      return JSON.parse(KEY ?? '{}');
    }
    return '{}';
  }

  get(key: string, subKey: string): any {
    const KEY = this.getKey(key);
    if (KEY != null) {
      return JSON.parse(KEY ?? '{}')[subKey];
    }
    return '{}';
  }

  clear(key: string): void {
    sessionStorage.removeItem(key);
  }

  setComplexItem(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value ?? '{}'));
  }

  getComplexItem(key: string): any {
    const KEY = this.getKey(key);
    if (KEY != null) {
      return JSON.parse(KEY ?? '{}');
    }
    return '{}';
  }

  private getKey(key: string): string | null {
    return sessionStorage.getItem(key);
  }
}
