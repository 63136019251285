import { Component } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-customer-support-error',
  templateUrl: './customer-support-error.component.html',
  styleUrls: ['./customer-support-error.component.scss'],
})
export class CustomerSupportErrorComponent {
  constructor(private translocoService: TranslocoService) {}
  img: string = '404-unplug';
  heading: string = this.translocoService.translate(
    'common.apiErrorToast.customErrorMessage.title',
  );
  description: string = `${this.translocoService.translate(
    'common.apiErrorToast.unexpected.title',
  )} ${this.translocoService.translate(
    'common.apiErrorToast.unexpected.body',
  )}`;
  label: string = this.translocoService.translate('common.home');
}
